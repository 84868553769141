import styled, { createGlobalStyle, css } from 'styled-components';

import { CircularProgress } from '@pro4all/shared/mui-wrappers';
import { ReportOptions } from '@pro4all/shared/types';

export const pagedJS = css`
  @page {
    size: A4 portrait;
    margin-top: 25mm;
    margin-right: 10mm;
    margin-bottom: 25mm;
    margin-left: 10mm;

    background-size: contain;
    background-image: var(--bgImage);

    @bottom-center {
      content: var(--footerText);
    }

    @bottom-right {
      content: var(--pageNumber);
    }
  }

  @page :first {
    background-image: var(--bgImageFrontPage);
    counter-set: page 1;
    @bottom-right {
      content: var(--pageNumberFirstPage);
    }
  }

  .page {
    width: 675px;
    height: 800px;
    break-before: page;
  }

  @media print {
    #root {
      display: none;
    }
    #pdf-preview {
      display: block;
    }
  }

  @media screen {
    .pagedjs_page {
      border: 0.5mm solid #eee;
      margin-bottom: 10mm;
      background-color: white;
      width: 100%;
    }
  }

  .outer-icon-container-reports {
    position: relative;
    height: 50px;
  }

  .inner-icon-container-reports {
    cursor: pointer;
    position: relative;
    height: 35px;
    width: 35px;
  }

  .icon-container-reports {
    align-items: center;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 4px;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const leaflet = css`
  @media screen {
    .leaflet-tile {
      opacity: 1 !important;
      visibility: visible !important;
      will-change: auto !important;
    }
    .leaflet-fade-anim {
      will-change: auto !important;
    }
  }

  .leaflet-pane,
  .leaflet-tile,
  .leaflet-marker-icon,
  .leaflet-marker-shadow,
  .leaflet-tile-container,
  .leaflet-pane > svg,
  .leaflet-pane > canvas,
  .leaflet-zoom-box,
  .leaflet-image-layer,
  .leaflet-layer {
    visibility: visible;
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    will-change: auto !important;
  }
  .leaflet-fade-anim {
    will-change: auto !important;
  }

  .leaflet-container {
    width: 768px;
    height: 512px;
    overflow: hidden;
  }

  .leaflet-tile-loaded {
    visibility: visible;
    opacity: 1;
  }

  .leaflet-pane {
    z-index: 400;
  }

  .leaflet-tile-pane {
    z-index: 200;
  }
  .leaflet-overlay-pane {
    z-index: 400;
  }
  .leaflet-shadow-pane {
    z-index: 500;
  }
  .leaflet-marker-pane {
    z-index: 600;
  }
  .leaflet-tooltip-pane {
    z-index: 650;
  }
  .leaflet-popup-pane {
    z-index: 700;
  }

  .leaflet-map-pane canvas {
    z-index: 100;
  }
  .leaflet-map-pane svg {
    z-index: 200;
  }

  img {
    opacity: 1 !important;
  }

  .report-floorplan-container {
    width: 675px;
    height: 800px;
    box-sizing: border-box;
    border: 1px solid #afafaf;
    border-radius: 4px;

    &.leaflet-grab,
    &.leaflet-interactive {
      cursor: default;
    }

    &.leaflet-map-pane-transform {
      .leaflet-map-pane {
        transform: translateX(0px) translateY(0px) rotate(0deg) !important;
      }
    }

    &:not(.leaflet-map-pane-transform) {
      .leaflet-map-pane {
        transform: translateX(735px) translateY(60px) rotate(90deg) !important;
      }
    }

    .leaflet-map-pane .leaflet-marker-pane .leaflet-div-icon {
      background: none;
      border: 0;
      border-radius: 50%;
    }
  }
`;

export const PdfPreview = styled.div`
  padding: 0;
  margin: 0;
  font-size: 10pt;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #373a3c;

  display: flex;
  justify-content: center;
`;

export const ReportStyling = createGlobalStyle<{
  defaultBg: string;
  page: string;
  reportOptions: ReportOptions;
}>`

  @media print {
    body {
      display:hidden;
    }

    #pdf-report-preview {
      display: visible;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  :root {
    --footerText: ${({ reportOptions }) => `'${reportOptions.footerText}'`};
    --pageText: '${({ page }) => page}';
    --pageNumber: ${({ reportOptions }) =>
      reportOptions.showPageNumbers ? `var(--pageText) ' ' counter(page)` : ''};
    --pageNumberFirstPage: ${({ reportOptions }) =>
      reportOptions.enableFrontPage
        ? ''
        : reportOptions.showPageNumbers
        ? `var(--pageText) ' ' counter(page)`
        : ''};
    --bgImage: url(${({ reportOptions, defaultBg }) =>
      reportOptions.background || defaultBg});
    --bgImageFrontPage: url(${({ reportOptions, defaultBg }) =>
      reportOptions.enableFrontPage
        ? reportOptions.backgroundFrontPage || defaultBg
        : reportOptions.background || defaultBg});

    .leaflet-tile {
      opacity: 1 !important;
      will-change: auto !important;
    }
    .leaflet-fade-anim {
      will-change: auto !important;
    }
  }
`;

export const Loader = styled(CircularProgress)`
  && {
    margin-top: 8px;
  }
`;
