import { MapElements } from '@pro4all/shared/types';

export async function getReportFloorplans(floorplanIds: string[]) {
  const updatedMapRecord: MapElements = {};

  const promises = floorplanIds.map((id) => waitForElm(id));
  await Promise.all(promises).then(() => {
    const preloadedMaps = document.getElementById('temp-floorplans-container');
    if (!preloadedMaps?.children) return updatedMapRecord;

    for (let i = 0; i < (preloadedMaps?.children?.length || 0); i++) {
      if (preloadedMaps?.children && preloadedMaps?.children.length) {
        const child = preloadedMaps.children[i];
        const floorplanId = child.id.replace('temp-floorplan-', '');

        updatedMapRecord[floorplanId] = child.innerHTML;
      }
    }

    if (!Object.keys(updatedMapRecord).length) return null;
    return updatedMapRecord;
  });
  if (!Object.keys(updatedMapRecord).length) return null;
  return updatedMapRecord;
}

function waitForElm(floorplanId: string) {
  const selector = `#temp-floorplan-${floorplanId}`;

  return new Promise<Element | null>((resolve) => {
    const element = document.querySelector(selector);
    if (element) {
      return resolve(element);
    }

    const observer = new MutationObserver(() => {
      if (element) {
        resolve(element);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
