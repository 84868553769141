import { RouteParams, Routes } from '@pro4all/shared/config';

import type { UrlOptions } from './generateUrl';
import { generateUrl } from './generateUrl';

export interface GenerateRouteOptions extends UrlOptions {
  params?: Partial<Record<keyof RouteParams, string>>;
}

export const generateRoute = (
  route: keyof typeof Routes,
  options: GenerateRouteOptions = {}
): string => generateUrl(Routes[route], options);
