import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs } from '@pro4all/shared/ui/general';

export enum ResultTabKey {
  Comments = 'comments',
  Log = 'log',
  Properties = 'properties',
}

export type MuiChangeEvent = React.SyntheticEvent;

export interface ResultsSidebarTabsProps {
  onChange: (e: MuiChangeEvent, value: string) => void;
  value: string;
}

export const ResultsSidebarTabs: React.FC<ResultsSidebarTabsProps> = ({
  onChange,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <Tabs onChange={onChange} value={value}>
      <Tab
        data-testid="qcr-properties-tab"
        label={t('Properties')}
        value="properties"
      />
      <Tab data-testid="qcr-log-tab" label={t('Log')} value="log" />
      <Tab
        data-testid="qcr-comments-tab"
        label={t('Comments')}
        value="comments"
      />
    </Tabs>
  );
};
