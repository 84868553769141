import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  ResultSet,
  TbqBrandcheckMultiResult,
  useGetTbqBrandcheckMultiResultQuery,
} from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';

import {
  BrandcheckProgressBarType,
  calculateAverages,
} from './brandcheckMultiAverages';
import { BouwbesluitState, PlusBouwbesluitState } from './ResultsUI.styles';

export const useTBQBrandcheckMultiDashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    searchParams,
    params: { objectId = '', resultSet = ResultSet.Victims },
    goTo,
  } = useRouting();

  const categoryId = searchParams.get('categoryId');
  const goalId = searchParams.get('goalId');
  const resultPlus = resultSet.endsWith('Plus');

  const progressBarSelected: boolean = Boolean(categoryId) || Boolean(goalId);
  const progressBarType: BrandcheckProgressBarType | null =
    progressBarSelected && categoryId
      ? BrandcheckProgressBarType.Category
      : progressBarSelected && goalId
      ? BrandcheckProgressBarType.Goal
      : null;
  const selectedGoalOrCategoryId =
    progressBarType === BrandcheckProgressBarType.Category
      ? categoryId
      : goalId;

  const { data, loading, error } = useGetTbqBrandcheckMultiResultQuery({
    fetchPolicy: 'no-cache',
    skip: !objectId,
    variables: { objectId, resultSet: resultSet as ResultSet },
  });

  const brandcheckMultiResult: TbqBrandcheckMultiResult[] =
    data?.getTBQBrandcheckMultiResult ?? [];

  const { categoriesAverage, goalsAverage } = calculateAverages(
    brandcheckMultiResult
  );

  const filterShortcomings = (
    param: 'categoryId' | 'goalId',
    id: string,
    name: string
  ) => {
    goTo({
      searchParams: {
        categoryId: undefined,
        goalId: undefined,
        goalOrCategoryName: name,
        [param]: id,
      },
    });
  };

  const toggleResultPlus = () => {
    goTo({
      params: {
        objectId,
        resultSet: resultPlus
          ? resultSet.replace('Plus', '')
          : `${resultSet}Plus`,
      },
    });
  };

  const back = () => history.goBack();

  const isSchadeTabSelected = resultSet.includes('Damage');
  const accomplished =
    categoriesAverage.filter((c) => c.percentage !== 1).length === 0;

  const statusIcon = () => {
    if (accomplished) {
      return (
        <BouwbesluitState>
          <Icon
            iconName="checkCircle"
            label={<Text>{t('Accomplished')}</Text>}
          />
        </BouwbesluitState>
      );
    } else {
      return (
        <PlusBouwbesluitState>
          <Icon
            iconName="cancel"
            label={<Text>{t('Not accomplished')}</Text>}
          />
        </PlusBouwbesluitState>
      );
    }
  };

  return {
    back,
    brandcheckMultiResult,
    categoriesAverage,
    data,
    error,
    filterShortcomings,
    goalsAverage,
    isSchadeTabSelected,
    loading,
    progressBarSelected,
    progressBarType,
    resultPlus,
    selectedGoalOrCategoryId,
    statusIcon,
    t,
    toggleResultPlus,
  };
};
