import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { Text } from '@pro4all/shared/ui/typography';

export const ProgressBar = styled.progress`
  flex: 1;
  border-radius: 8px;
  height: 1rem;
  background-color: ${({ theme }) => theme.palette.error.main};
  ::-webkit-progress-bar {
    background-color: ${({ theme }) => theme.palette.error.main};
    border-radius: 8px;
  }
  ::-webkit-progress-value {
    background-color: ${({ theme }) => theme.palette.secondary.main} !important;
    border-radius: 8px;
  }
  ::-moz-progress-bar {
    background-color: ${({ theme }) => theme.palette.secondary.main} !important;
    border-radius: 8px;
  }
`;

export const ProgressItem = styled.div<{ $selected: boolean }>`
  padding: 8px 8px 4px 8px;
  order: 0;
  cursor: pointer;
  :hover {
    background-color: ${customColors.grey300};
    border-radius: 4px;
    order: 1;
  }
  ${({ $selected, theme }) =>
    $selected &&
    `
    background-color: ${theme.palette.success.light};
    padding: 7px 7px 3px 7px;
    border: 1px solid ${theme.palette.success.dark};
    border-radius: 4px;
    order: 1;
    `}
`;

export const ProgressBarsWrapper = styled(Box)`
  && {
    flex: 1;
  }
`;

export const ProgressBarsPerObjectWrapper = styled(Box)`
  && {
    padding: 8px 8px 4px 8px;
    width: 45%;
  }
`;

export const BarPercentageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Percentage = styled(Text)`
  && {
    text-align: right;
    flex-basis: 50px;
    padding-left: 1rem;
  }
`;

export const SubTitle = styled(Text)`
  && {
    color: ${customColors.grey700};
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;
