import { TableColumnDataType } from '@pro4all/shared/types';

import { BaseRow } from '../types';

export type GetSortedItems<Row extends BaseRow> = {
  items: Row[];
};

export type MetaDataProps = {
  metaData: {
    answers: {
      fieldDefinitionId: string;
      value: string;
    }[];
  };
};

export type SortColumnTypeLS = {
  dataType: TableColumnDataType;
  sortKey: string;
  sortOrder: SortOrder.ASC | SortOrder.DESC;
  subProperty: string;
};

export type SortColumn<Row extends BaseRow> = {
  dataType?: TableColumnDataType;
  items: Row[];
  metaDataHeaderId?: string;
  propertyId?: string;
  subPropertyId?: string;
};

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type StandardProps = {
  [key: string]: {
    [key: string]: string;
  };
};
