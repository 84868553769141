import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useProjectContext } from '@pro4all/projects/ui/context';
import { ReportRenderService } from '@pro4all/quality-control/data-access';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';

import { useReportOptionsContext } from '../ReportOptionsContext';

export const DownloadReportButton: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isProcessing, setIsProcessing] = useState(false);

  const context = useReportOptionsContext();
  if (!context) throw new Error('ReportOptionsContext not available');
  const { customMode, reportOptions, miniMaps, reportFloorplans } = context;

  const {
    params: { projectId },
    state: { instanceIds, taskInstanceIds },
  } = useRouting<{
    instanceIds: string[];
    taskInstanceIds: string[];
  }>();

  const { projectName } = useProjectContext();

  if ((!instanceIds && !taskInstanceIds) || !projectId || !context) {
    return null;
  }

  const onComplete = () => setIsProcessing(false);

  return (
    <Button
      disabled={isProcessing}
      onClick={async () => {
        setIsProcessing(true);
        try {
          await ReportRenderService.downloadReport(
            {
              customMode,
              instanceIds,
              lang: i18n.language,
              miniMaps,
              options: reportOptions,
              projectId,
              projectName,
              reportFloorplans,
            },
            onComplete
          );
        } catch (e) {
          enqueueSnackbar(
            t('Something went wrong. Please try to download the report again.'),
            { variant: 'error' }
          );
        }
      }}
      startIcon="download"
      variant="contained"
    >
      {isProcessing ? `${t('Processing')}...` : t('Download PDF')}
    </Button>
  );
};
