import styled from 'styled-components';

import { ValueTypeName } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { LinearProgress, ListItemButton } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export const StyledProgress = styled(LinearProgress)<{ $visible: boolean }>`
  && {
    visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  }
`;

export const BoxNoBottomMargin = styled(Box)`
  && {
    margin-bottom: 0px;
  }
`;

export const BoxSectionFieldInclude = styled(Box)<{
  level: number;
  type: ValueTypeName;
}>`
  margin-left: ${({ level, theme }) => {
    const indent = Number(level * 4);
    return theme.spacing(indent);
  }};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  padding-left: ${({ theme }) => theme.spacing(1)};
  background: ${({ type }) =>
    type === ValueTypeName.Section ? customColors.grey300 : 'transparent'};
`;

export const StyledListItemButton = styled(ListItemButton)`
  && {
    padding: 0;
    margin-bottom: 0;
    &:hover {
      background: transparent;
    }
  }
`;
