import { useTranslation } from 'react-i18next';

import {
  FilterHeaderType,
  PredefinedFilter,
} from '@pro4all/shared/ui/filtering';

export const usePredefinedFilters = () => {
  const { t } = useTranslation();

  const predefinedFiltes: PredefinedFilter[] = [
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['Yes'],
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'active',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Active'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['No'],
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'active',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Not active'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['Administrator'],
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'roles',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Administrator'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['User'],
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'roles',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Users'),
    },
  ];

  return predefinedFiltes;
};
