import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FloorPlanRouterState,
  useFetchResults,
} from '@pro4all/quality-control/data-access';
import { useViewResult } from '@pro4all/quality-control/ui/results';
import { SearchBar, StorageKeyType, useTopTen } from '@pro4all/search/ui';
import { Box, Collapse } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SearchEntities } from '@pro4all/shared/search-utils';
import { useSnagFormSearchContext } from '@pro4all/shared/snags-and-forms-search-context';
import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';

export const FloorPlanSearchBar = () => {
  const { t } = useTranslation();
  const {
    params: { projectId },
  } = useRouting<FloorPlanRouterState>();
  const { handleSearch, paramQuery, predefinedHiddenFilters } =
    useSnagFormSearchContext();
  const [currentQuery, setCurrentQuery] = useState<string | null>(paramQuery);
  const [isVisibleSearchInput, setIsVisibleSearchInput] = useState(
    Boolean(paramQuery)
  );

  const { data: qcInstanceQueryResult } = useFetchResults(projectId);

  const onSelect = useViewResult(
    projectId,
    qcInstanceQueryResult?.qualityControlInstances || []
  );
  const topTen = useTopTen({
    predefinedHiddenFilters,
    type: SearchEntities.QualityControl,
  });

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        maxWidth: '320px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '39px',
          ...(isVisibleSearchInput
            ? {
                backgroundColor: customColors.grey200,
                borderBottomLeftRadius: (theme) => theme.spacing(0.5),
                borderTopLeftRadius: (theme) => theme.spacing(0.5),
                left: (theme) => theme.spacing(3),
                paddingLeft: (theme) => theme.spacing(2),
                position: 'relative',
              }
            : {}),
        }}
      >
        <IconButton
          ariaLabel={t('Search')}
          color="inherit"
          disableBorder
          iconName="search"
          onClick={() => setIsVisibleSearchInput(!isVisibleSearchInput)}
          type="button"
        />
      </Box>

      <Collapse
        collapsedSize={0}
        in={isVisibleSearchInput}
        orientation="horizontal"
      >
        <Box width="246px">
          <SearchBar
            currentQuery={currentQuery}
            historyContext={StorageKeyType.QualityControl}
            noInputIcon
            onSearch={handleSearch}
            onSelect={onSelect}
            setCurrentQuery={setCurrentQuery}
            showBackButton={false}
            showSavedSearches={false}
            topTen={topTen}
            type={SearchEntities.QualityControl}
          />
        </Box>
      </Collapse>
    </Box>
  );
};
