import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Formik, FormikErrors } from 'formik';

import {
  QualityControlInstance,
  Task,
  TaskType,
  TbqAnswer,
  TbqRieResult,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useProjectContext } from '@pro4all/projects/ui/context';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  FormFooter,
  FormikDate,
  FormikForm,
  FormikInput,
  FormikSelect,
  FormikTextarea,
  useTableCheck,
} from '@pro4all/shared/ui/general';
import { Text } from '@pro4all/shared/ui/typography';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';
import {
  isTbqBrandcheck,
  isTbqRie,
  useTaskStatuses,
} from '@pro4all/workflow/ui/utils';

import { ParticipantSelect } from './ParticipantSelect';
import TBQInstancesToLink from './TBQInstancesToLink';
import { FormFields } from './types';
import { useInitialValues } from './useInitialValues';
import { useTaskFormConfig } from './useTaskFormConfig';
import { useTBQSubmit } from './useTBQSubmit';
import { VisualContextSelect } from './VisualContextSelect';
interface Props {
  onClose: () => void;
  procedureId?: string;
  projectId: string;
  task?: Task;
  taskType: TaskType;
  tbqBrandcheckQcInstance?: QualityControlInstance;
  tbqQuestion?: TbqRieResult | TbqRieResult[] | TbqAnswer | TbqAnswer[];
}

export const TBQTaskForm: React.FC<Props> = ({
  onClose,
  procedureId,
  projectId,
  task,
  taskType,
  tbqQuestion,
  tbqBrandcheckQcInstance,
}) => {
  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();
  const { mainProcedureId } = useProjectContext();
  const hasQualityControlFeatureFlag = useFeatureFlag('qualitycontrol');
  const { checkedRows } = useTableCheck<TbqRieResult | TbqAnswer>();
  const { searchParams } = useRouting();
  const [linkInstances, setLinkInstances] = useState<
    TbqRieResult[] | TbqAnswer[]
  >([]);

  const { hasQualityControlLicense, hasDmsLicense } =
    useSubscriptionRestriction(contextScopedOrganizationSubscriptionLevel);
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const statuses = useTaskStatuses();

  const routeCreate = searchParams.is('action', 'createTask');

  const isEditMode = Boolean(task);

  const instanceId = searchParams.get('id');

  useEffect(() => {
    if (!routeCreate || !instanceId) return;
  }, [routeCreate, instanceId]);

  const onSubmit = useTBQSubmit({
    linkInstances: linkInstances,
    procedureId: procedureId || mainProcedureId,
    task,
    tbqBrandcheckQcInstance: tbqBrandcheckQcInstance,
    tbqQuestion: tbqQuestion,
  });

  const initialValues = useInitialValues({
    hasDmsLicense,
    hasQualityControlFeatureFlag,
    hasQualityControlLicense,
    task,
    taskType,
  });

  const { getField, validationSchema: formValidationSchema } =
    useTaskFormConfig();

  const nameField = getField('name');
  const descriptionField = getField('description');
  const endTimeField = getField('endTime');
  const nextStatusField = getField('nextStatus');
  const visualContextsField = getField('visualContexts');

  const checkDisableStatus = (
    errors: FormikErrors<FormFields>,
    isSubmitting: boolean,
    dirty: boolean
  ) => {
    const isDirty = dirty;

    // Default case for quality control and tbq
    return isSubmitDisabled({ dirty: isDirty, errors, isSubmitting });
  };

  const determineLinkInstances = () => {
    if (Array.isArray(tbqQuestion)) {
      if (isTbqBrandcheck(tbqQuestion[0])) {
        return Array.from(new Set(tbqQuestion as TbqAnswer[]));
      } else if (isTbqRie(tbqQuestion[0]))
        return Array.from(new Set(tbqQuestion as TbqRieResult[]));
    } else if (tbqQuestion) {
      return [tbqQuestion];
    }
  };

  useEffect(() => {
    if (!checkedRows) return;
    const instances = determineLinkInstances() as TbqRieResult[] | TbqAnswer[];
    setLinkInstances(instances);
  }, [checkedRows]);

  const availableStatuses = task?.availableStatuses.map((ts) => ({
    id: ts.taskStatus,
    label: t(statuses[ts.taskStatus].label),
  }));

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onReset={() => inputRef.current.focus()}
      onSubmit={onSubmit}
      validationSchema={formValidationSchema}
    >
      {({ values, errors, isSubmitting, dirty }) => (
        <FormikForm>
          <FormWrapper>
            <Text variant="h4">{t('General')}</Text>
            <FormikInput
              autoFocus
              id={nameField.name}
              inputRef={inputRef}
              label={nameField.label}
              name={nameField.name}
            />
            <FormikTextarea
              id={descriptionField.name}
              label={descriptionField.label}
              name={descriptionField.name}
              rows={4}
            />
            <FormikDate
              label={
                taskType === TaskType.TbqResolve
                  ? t('Deadline')
                  : endTimeField.label
              }
              minDate={dayjs()}
              name={endTimeField.name}
            />
            {isEditMode && (
              <FormikSelect
                id={nextStatusField?.name}
                label={nextStatusField?.label}
                name={nextStatusField?.name}
                options={availableStatuses}
              />
            )}
            <ParticipantSelect projectId={projectId ?? ''} />

            {taskType === TaskType.TbqResolve && linkInstances?.length > 0 && (
              <TBQInstancesToLink linkInstances={linkInstances} />
            )}

            <Box mt={1}>
              <VisualContextSelect
                id={visualContextsField.name}
                projectId={projectId ?? task?.project?.id ?? ''}
                tooltip={t(
                  'Link floor plans to a task so the participant can place snags on it'
                )}
              />
            </Box>
          </FormWrapper>

          <FormFooter
            cancelLabel={t(isEditMode ? 'Cancel' : 'Go back')}
            disableSubmit={checkDisableStatus(errors, isSubmitting, dirty)}
            onClose={onClose}
            pb={3}
            pt={2}
            px={3}
            showCancel={!routeCreate}
            sticky
          />
        </FormikForm>
      )}
    </Formik>
  );
};
