import React from 'react';

import { useQualityControlContext } from '@pro4all/quality-control/context';
import { DroppedFloorplan } from '@pro4all/quality-control/data-access';
import { CircularProgress } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';
import { Icon } from '@pro4all/shared/ui/icons';

export interface Props {
  droppedFloorplanId: string;
}

export const FloorplanRemoveAndUploadStatusCell: React.FC<Props> = ({
  droppedFloorplanId,
}) => {
  const {
    filesProcessed,
    filesSelectedToUpload,
    filesUploadedSuccessfully,
    filesUploadedUnsuccessfully,
    isUploading,
  } = useFileUploadContext();

  const {
    canUpdate,
    setCanUpdate,
    beCheck,
    setBeCheck,
    canUpload,
    setCanUpload,
  } = useQualityControlContext();

  const isUnsuccessfullyUploaded = filesUploadedUnsuccessfully.some(
    (upload) => upload.file.id === droppedFloorplanId
  );

  const isSuccessfullyUploaded = filesUploadedSuccessfully.some(
    (upload) => upload.file.id === droppedFloorplanId && upload.done
  );

  const isCurrentlyUploading = filesSelectedToUpload.some(
    (upload) =>
      upload.file.id === droppedFloorplanId && !upload.done && upload.uploading
  );

  const htmlColor = isSuccessfullyUploaded
    ? 'greenPrimary'
    : isUnsuccessfullyUploaded
    ? 'errorAlert'
    : 'grey600';

  const iconName = isSuccessfullyUploaded
    ? 'checkCircleOutlined'
    : isUnsuccessfullyUploaded
    ? 'error'
    : 'hourglassEmpty';

  if (isCurrentlyUploading) return <CircularProgress size={20} />;

  if (
    isUploading ||
    filesUploadedUnsuccessfully.length ||
    filesProcessed.length
  )
    return <Icon htmlColor={customColors[htmlColor]} iconName={iconName} />;

  const handleRemoveFile = () => {
    let filteredFloorplans: DroppedFloorplan[] = [];

    if (beCheck.length > 0) {
      filteredFloorplans = beCheck.filter(
        (floorplan) => floorplan.id !== droppedFloorplanId
      );
      if (filteredFloorplans.length !== beCheck.length) {
        setBeCheck(filteredFloorplans);
        return;
      }
    }
    if (canUpdate.length > 0) {
      filteredFloorplans = canUpdate.filter(
        (floorplan) => floorplan.id !== droppedFloorplanId
      );
      if (filteredFloorplans.length !== canUpdate.length) {
        setCanUpdate(filteredFloorplans);
        return;
      }
    }
    if (canUpload.length > 0) {
      filteredFloorplans = canUpload.filter(
        (floorplan) => floorplan.id !== droppedFloorplanId
      );
      if (filteredFloorplans.length !== canUpload.length)
        setCanUpload(filteredFloorplans);
    }
  };

  return (
    <IconButton
      color="inherit"
      disableBorder
      iconName="close"
      onClick={handleRemoveFile}
    />
  );
};
