import React from 'react';

import { SavedAnswers } from '@pro4all/graphql';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import { getFormattedDate, Timestamp } from '@pro4all/shared/ui/general';
import { FilterHeader } from '@pro4all/shared/ui/table-column-filtering';

export const useColumns = () => [
  {
    defaultSort: true,
    filterable: true,
    headerComponent: (
      <FilterHeader<SavedAnswers, {}>
        defaultWidth={400}
        filterType={FilterHeaderType.Text}
        label="Name" // i18n
        minWidth={100}
        propertyId="name"
        pullFromLocalStorage
      />
    ),
    key: 'name',
    width: 400,
  },
  {
    filterable: true,
    headerComponent: (
      <FilterHeader<SavedAnswers, {}>
        defaultWidth={400}
        filterType={FilterHeaderType.Text}
        label="Template" // i18n
        minWidth={100}
        propertyId="templateName"
      />
    ),
    key: 'templateName',
    width: 400,
  },
  {
    filterable: true,
    headerComponent: (
      <FilterHeader<SavedAnswers, {}>
        defaultWidth={400}
        filterType={FilterHeaderType.Select}
        label="Type" // i18n
        minWidth={100}
        propertyId="type"
      />
    ),
    key: 'type',
    width: 120,
  },
  {
    filterable: true,
    getValue: (savedAnswer: SavedAnswers) =>
      savedAnswer.createdOn
        ? getFormattedDate(savedAnswer.createdOn, 'lll').label
        : '',
    headerComponent: (
      <FilterHeader<SavedAnswers, {}>
        defaultWidth={200}
        filterType={FilterHeaderType.Date}
        label="Created on" // i18n
        minWidth={120}
        propertyId="createdOn"
      />
    ),
    key: 'createdOn',
    render: ({ createdOn }: SavedAnswers) => (
      <Timestamp date={createdOn} format="lll" />
    ),
    width: 200,
  },
];
