import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormGroup, Typography } from '@pro4all/shared/mui-wrappers';
import { FormikCheckbox } from '@pro4all/shared/ui/general';

import { useReportOptionsContext } from '../../ReportOptionsContext';

export const ConfigureLayout: React.FC = () => {
  const { t } = useTranslation();

  const context = useReportOptionsContext();
  if (!context) throw new Error('ReportOptionsContext not available');

  return (
    <FormGroup>
      <Typography variant="h6">{t('Layout')}</Typography>
      <FormikCheckbox label={t('Compact lists')} name="compactLists" />
      <FormikCheckbox label={t('Inline lists')} name="inlineLists" />
    </FormGroup>
  );
};
