import React from 'react';
import { useTranslation } from 'react-i18next';

import { DroppedFloorplan } from '@pro4all/quality-control/data-access';
import { ColumnProps } from '@pro4all/shared/ui/general';

import { FloorplanRemoveAndUploadStatusCell } from './FloorplanRemoveAndUploadStatusCell';
import { FloorplanUploadStatusTextCell } from './FloorplanUploadStatusTextCell';

export const useUploadModalColumns = () => {
  const { t } = useTranslation();

  const modalColumns: ColumnProps<DroppedFloorplan>[] = [
    {
      disableSort: true,
      filterable: true,
      flexGrow: 0,
      key: 'floorplan-remove-and-upload-status',
      render: ({ id }) => (
        <FloorplanRemoveAndUploadStatusCell droppedFloorplanId={id} />
      ),
      width: 55,
    },
    {
      disableSort: true,
      filterable: false,
      flexGrow: 0,
      key: 'name',
      label: t('Name'),
      minWidth: 125,
      render: ({ name }) => <p>{name}</p>,
    },
    {
      disableSort: true,
      filterable: false,
      flexGrow: 0,
      key: 'status',
      label: t('Status'),
      minWidth: 275,
      render: ({ id }) => (
        <FloorplanUploadStatusTextCell droppedFloorplanId={id} />
      ),
    },
  ];

  return modalColumns;
};
