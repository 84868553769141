import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { ReportStopwatchFieldProps } from '@pro4all/shared/types';
import { Text } from '@pro4all/shared/ui/typography';

import { Instance } from '../elements';

import { FieldDescription } from './FieldDescription';
import LinkedSnagsList from './LinkedSnagsList';

export const ReportStopwatchField = ({
  item,
  key,
  linkedSnags,
  templateOptions,
}: ReportStopwatchFieldProps) => {
  const { t } = useTranslation();
  const { displayName, displayDescription, value } = item;
  return (
    <Instance.Item key={key}>
      <Instance.Label>{displayName}</Instance.Label>
      <Instance.Value>
        {value ? (
          JSON.parse(value ?? '').map(
            (round: { label: string; time: string }) => (
              <Box alignItems="center" display="flex">
                <label>{t(`${round.label}`)}: </label>
                <Text>{round.time}</Text>
              </Box>
            )
          )
        ) : (
          <Text>-</Text>
        )}
        {templateOptions?.showDescription && (
          <FieldDescription displayDescription={displayDescription} />
        )}
        {templateOptions?.showLinkedSnags && (
          <LinkedSnagsList linkedSnags={linkedSnags} />
        )}
      </Instance.Value>
    </Instance.Item>
  );
};

export default ReportStopwatchField;
