import { QualityControlInstance } from '@pro4all/graphql';
import { GroupedInstances } from '@pro4all/shared/types';

export const groupInstancesByFloorplan = (
  instances: QualityControlInstance[]
) => {
  const groupedInstances: GroupedInstances[] = [];

  instances.forEach((instance) => {
    const existingGroup = groupedInstances.find(
      (group) =>
        group.visualContextId === instance.visualContextId &&
        group.page === instance.page
    );

    if (existingGroup) {
      existingGroup.instances.push(instance);
    } else {
      groupedInstances.push({
        instances: [instance],
        page: instance.page ?? 1,
        visualContextId:
          instance.visualContextId ?? '00000000-0000-0000-0000-000000000000',
        visualContextName: instance.visualContext?.name ?? '',
      });
    }
  });

  groupedInstances.forEach((group: GroupedInstances) => {
    group.instances.sort(
      (a, b) => parseInt(a.reference) - parseInt(b.reference)
    );
  });

  return groupedInstances;
};

export const sortGroupedInstances = (groupedInstances: GroupedInstances[]) =>
  groupedInstances.sort((a, b) => {
    // Handle the special case for visualContextId
    if (
      a.visualContextId === '00000000-0000-0000-0000-000000000000' &&
      b.visualContextId !== '00000000-0000-0000-0000-000000000000'
    ) {
      return -1; // a comes first
    }
    if (
      b.visualContextId === '00000000-0000-0000-0000-000000000000' &&
      a.visualContextId !== '00000000-0000-0000-0000-000000000000'
    ) {
      return 1; // b comes first
    }

    // If both have the special visualContextId, sort by referenceNumber
    if (
      a.visualContextId === '00000000-0000-0000-0000-000000000000' &&
      b.visualContextId === '00000000-0000-0000-0000-000000000000'
    ) {
      const aReferenceNumber = parseInt(a.instances[0].reference);
      const bReferenceNumber = parseInt(b.instances[0].reference);
      return aReferenceNumber - bReferenceNumber; // Sort by referenceNumber
    }

    // Sort alphabetically by visualContextName for other cases
    return a.visualContextName.localeCompare(b.visualContextName);
  });
