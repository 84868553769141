import { SvgIcon, SvgIconProps } from '@pro4all/shared/mui-wrappers';

export const Floorplan = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M6.04582 0.691498L10.2125 2.14983C10.4042 2.2165 10.6041 2.20817 10.7875 2.1415L13.2292 1.1915C14.3208 0.766498 15.4958 1.5665 15.4958 2.7415V12.6165C15.4958 13.3332 15.0375 13.9748 14.3625 14.1998L11.0375 15.3165C10.6875 15.4332 10.3042 15.4332 9.95415 15.3082L5.78748 13.8498C5.60415 13.7832 5.39582 13.7832 5.21248 13.8582L2.77082 14.8082C1.67915 15.2332 0.50415 14.4332 0.50415 13.2582V3.38317C0.50415 2.6665 0.962484 2.03316 1.63748 1.79983L4.96248 0.683165C5.31248 0.566498 5.69582 0.566498 6.04582 0.691498ZM5.50415 11.9832L10.5042 13.7415V4.0165L5.50415 2.25816V11.9832Z"
      fill="#585858"
      fillRule="evenodd"
    />
  </SvgIcon>
);
