import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { AuthService } from '@pro4all/authentication/src/services/auth-service';
import { useUserFolderPermissions } from '@pro4all/documents/data-access';
import { Folder, useDuplicateFolderStructureMutation } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import {
  getFolderTreeStructure,
  isSomethingInClipboard,
} from '@pro4all/shared/storage-utils';
import { ActionProps } from '@pro4all/shared/types';

import { useFolderTreeContextOuter } from '../tree/FolderTreeProviderOuter';

export const usePasteFolderTree = (folder?: Folder) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { hasFolderPermission } = useUserFolderPermissions({
    folderId: folder?.id,
  });
  const [duplicate] = useDuplicateFolderStructureMutation();
  const { projectId: targetProjectId } = useRouting().params;
  const { tenantId: organizationId } = AuthService.getProfile();
  const {
    state: { folders },
  } = useFolderTreeContextOuter();

  const existFolder = (targetFolderId: string, sourceFolderName: string) => {
    const subFolders = folders.filter(
      (folder) =>
        folder.parentFolderId === targetFolderId ||
        (!folder.parentFolderId && !targetFolderId)
    );
    const isFolder = Boolean(
      subFolders.find((folder) => folder.name === sourceFolderName)
    );
    return isFolder;
  };

  const paste = async () => {
    const {
      folderId: sourceFolderId,
      projectId: sourceProjectId,
      folderName: sourceFolderName,
    } = getFolderTreeStructure();

    const resp = await duplicate({
      variables: {
        sourceFolderId,
        sourceProjectId,
        targetFolderId: folder?.id,
        targetOrganizationId: organizationId,
        targetProjectId,
      },
    });

    if (resp.data.duplicateFolderStructure.success) {
      enqueueSnackbar(
        t('Pasting folders has started, this could take a few minutes.')
      );
    } else {
      if (resp.data.duplicateFolderStructure.errorCode === 409) {
        enqueueSnackbar(
          t('Folder with name "{{sourceFolderName}}" exist in this folder.', {
            sourceFolderName: resp.data.duplicateFolderStructure.folderName,
          })
        );
      } else {
        enqueueSnackbar(t('Something went wrong. Please try again.'));
      }
    }
  };

  const pasteFolderTreeAction: ActionProps = {
    ariaLabel: 'Paste folder tree',
    dataTestId: 'paste-folder-tree',
    disabled:
      (folder && !hasFolderPermission('CreateSubFolder')) ||
      !isSomethingInClipboard(),
    key: 'paste-folder-tree',
    label: t('Paste folder tree'),
    onClick: paste,
    startIcon: 'clipboard',
  };

  return {
    pasteFolderTreeAction,
  };
};
