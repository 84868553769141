import { useProjectQuery } from '@pro4all/graphql';
import { flatten } from '@pro4all/metadata/ui/utils';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useBatchFetchResults } from '@pro4all/quality-control/data-access';
import { useTranslateTbq } from '@pro4all/shared/qc-meta';
import { useRouting } from '@pro4all/shared/routing-utils';

export const useReportData = (ids: string[]) => {
  const { projectId = null } = useRouting().params;

  const { meData } = useOrganizationContext();
  const { data: instanceData } = useBatchFetchResults(ids);

  const { __typename, ...currentUser } = meData?.user || {
    displayName: 'unknown',
    userId: 'unknown',
  };

  const instances = instanceData?.qualityControlInstances;
  const instanceProjectId = projectId ?? instances?.[0]?.projectId;

  const { data: projectData } = useProjectQuery({
    skip: !instanceProjectId,
    variables: {
      projectId: instanceProjectId ?? '',
    },
  });
  const project = projectData?.project;

  const { translateTbq } = useTranslateTbq();

  const allItems = flatten(
    instances.flatMap((i) => i.items),
    (i) => i?.valueType?.subFields ?? []
  );
  allItems.forEach((it) => {
    if (it) {
      it.value = translateTbq(it?.value);
    }
  });

  // optional step
  instances.forEach((i) =>
    i.answers?.forEach((a) => {
      a.value = translateTbq(a.value);
    })
  );
  return {
    currentUser: { ...currentUser } || null,
    instances,
    project,
  };
};
