import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSidePanelContext } from '@pro4all/shared/contexts';
import { useLocalStorage } from '@pro4all/shared/hooks';
import { Drawer } from '@pro4all/shared/mui-wrappers';
import { StylingDefaults } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';

import { handlePickersModalClick } from '../../utils';

interface Props {
  keepMounted?: boolean;
  onClose: () => void;
  open: boolean;
  wide?: boolean;
}

export enum Size {
  Default = 400,
  Wide = 600,
}

export const Sidebar: React.FC<Props> = ({
  children,
  keepMounted,
  onClose,
  open,
  wide,
}) => {
  const { t } = useTranslation();

  const { setLocalStorageItem: setSidenavIsModalLocalStorage } =
    useLocalStorage<boolean>({
      key: `sidenav-modal`,
    });

  const { sidePanelIsModal, setSidePanelIsModal } = useSidePanelContext();

  /**
   * We want the escape key close to work whether the sidebar is focused or
   * not.  The Drawer onClose only triggers when it is focused so we need to
   * add it to window.document as well. Only adding it to window.document
   * doesn't work either since onClose on the drawer cancels the event!
   */
  const closeModal = useCallback(
    (event: KeyboardEvent): void => {
      if (event.type === 'keydown' && event.code === 'Escape') {
        // Don't show preview modal if any input element has focus
        if (
          !(window.document.activeElement instanceof HTMLInputElement) &&
          !(window.document.activeElement?.getAttribute('role') === 'textbox')
        ) {
          onClose();
          event.preventDefault();
        }
      }
    },
    [onClose]
  );

  const handleModal = () => {
    setSidenavIsModalLocalStorage(!sidePanelIsModal);
    setSidePanelIsModal(!sidePanelIsModal);
  };

  useEffect(() => {
    window.document.addEventListener('keydown', closeModal);
    return () => {
      window.document.removeEventListener('keydown', closeModal);
    };
  }, [onClose, closeModal]);

  /**
   * For our specific modal behaviour where we have a modal element (this
   * sidebar) that is not actually treated as a normal modal. There should be
   * no backdrop and everything should still be clickable. Because we only want
   * to mount the sidebars whenever the Sidebar opens we cannot use
   * variant="persistent", since that renders everything with visibility:
   * hidden;.
   */
  return (
    <StyledDrawer
      $modal={sidePanelIsModal}
      $wide={wide}
      ModalProps={{
        // Needed for Material-ui temporary workaround
        disableEnforceFocus: true,
        disableScrollLock: true,
        hideBackdrop: true,
      }}
      PaperProps={{
        'data-testid': 'sidebar-content',
      }}
      anchor="right"
      data-testid="sidebar"
      keepMounted={keepMounted}
      onClick={handlePickersModalClick}
      onClose={closeModal}
      /**
       * The 'onClose' in the Drawer is triggered on keypress when the Drawer
       * is active, but it is cancelled so the window.document event listener
       * is not triggered.  We need to add closeModal here too for it to work
       * correctly.
       */
      open={open}
      role="complementary"
      transitionDuration={0} // Don't animate
    >
      <ButtonContainer>
        <ButtonWrapper>
          <IconButton
            aria-label={t('Toggle fullscreen')}
            color="default"
            disableBorder
            iconName={!sidePanelIsModal ? 'fullscreen' : 'fullscreenExit'}
            onClick={handleModal}
            sx={{ display: { sm: 'inline-flex', xs: 'none' } }}
          />
          <IconButton
            aria-label={t('Close')}
            color="default"
            disableBorder
            iconName="close"
            onClick={onClose}
          />
        </ButtonWrapper>
      </ButtonContainer>
      {children}
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)<{ $modal?: boolean; $wide?: boolean }>`
  && {
    /* Needed so we can still interact with the background without calling the
   * close method of the sidebar */
    width: ${({ $modal }) => ($modal ? '100vw' : '0')};
    height: ${({ $modal }) => ($modal ? '100vh' : '0')};
    display: flex;
    justify-content: center;
    background: ${({ $modal }) =>
      $modal ? StylingDefaults.backdropColor : 'rgba(0, 0, 0, 0)'};
    ${({ theme }) => theme.typography.body1};

    .MuiDrawer-paper {
      transition: ${({ $modal }) => ($modal ? 'none' : 'inherit')};
      top: 0;
      right: ${({ $modal }) => ($modal ? 'auto' : '0')};
      max-width: 100%;
      box-shadow: ${StylingDefaults.boxShadow};

      ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 100vw;
        width: 100vh;
      }

      ${({ theme }) => theme.breakpoints.up('sm')} {
        border-radius: ${({ $modal }) =>
          $modal ? StylingDefaults.borderRadiusSmall : '0'};
        margin: ${({ $modal }) => ($modal ? '24px' : '0')};
        height: ${({ $modal }) => ($modal ? `calc(100% - 48px)` : '100%')};
        max-width: calc(100% - 64px);
        width: ${({ $modal }) =>
          $modal
            ? 'calc(100% - 64px)'
            : ({ $wide }) => ($wide ? Size.Wide : Size.Default) + 'px'};
      }

      ${({ theme }) => theme.breakpoints.up('md')} {
        max-width: 800px;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  position: sticky;
  top: 0px;
  right: 0;
  z-index: 2;
  height: 0px;
  width: 100%;
  display: flex;
  justify-content: end;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 3px;
  padding: ${({ theme }) => theme.spacing(2)};
`;
