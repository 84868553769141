import React, {
  createRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { createEditor } from 'slate';
import { Editor } from 'slate/dist/interfaces/editor';
import { withReact } from 'slate-react';

import {
  AttachmentInput,
  defaultEditorValues,
  EditorButton,
  ImageUploading,
  MessageFormBase,
  origin,
  stringToPlainText,
  useImageUpload,
  useUpload,
  withMentions,
} from '@pro4all/communication/ui/general';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Icon } from '@pro4all/shared/ui/icons';

import { CommentInput } from '../CommentInput';
import { OuterFooter } from '../OuterFooter';
import { useThreadContext } from '../ThreadContext';
import { useSubmit } from '../useSubmit';

import { CommentFormProps } from './CommentForm.types';

export const CommentForm: React.FC<CommentFormProps> = ({
  newComment,
  ...commentProps
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const containerRef = createRef<HTMLDivElement>();
  const { attachments, body, createdAt, messageId, references } = commentProps;

  const inputId = `attachment-input-${messageId || 'new'}`;

  const { searchParams } = useRouting();
  const focusId = searchParams.get('focusComment');
  const commentSeen = searchParams.get('commentSeen');
  const editId = searchParams.get('editComment');

  const context = useThreadContext();
  if (!context) throw Error('Comments context not initialized.');
  const {
    callbackOptions,
    target,
    threadId,
    references: contextReference,
  } = context;

  const onAttachmentButtonClicked = callbackOptions?.onAttachmentButtonClicked;

  const handleSubmit = useSubmit({
    messageId,
    targetId: target.referenceValue || '',
  });

  /* Initialize editor */
  const editorRef = useRef<Editor>();
  if (!editorRef.current)
    editorRef.current = withMentions(withReact(createEditor()));
  const editor = editorRef.current;
  if (!editor.selection) editor.selection = { anchor: origin, focus: origin };

  const resetEditorValues = () => {
    editor.selection = { anchor: origin, focus: origin };
    editor.children = defaultEditorValues;
  };

  const editMode = Boolean(messageId) && editId === messageId;
  const readOnly = !newComment && !editMode;

  const defaultValues: MessageFormBase = useMemo(
    () => ({
      attachments,
      body: body || '',
      messageId,
      newFiles: [],
    }),
    [body, messageId, attachments]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (containerRef?.current && !containerRef.current.contains(target)) {
        focusId && searchParams.set('commentSeen', 'true');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editor, containerRef, searchParams, focusId]);

  useLayoutEffect(() => {
    if (focusId && messageId === focusId) {
      const comment = document.getElementById(`message-id-${focusId}`);

      if (comment && !commentSeen) {
        comment.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [commentSeen, focusId, messageId, editId]);

  const resolver: Resolver<MessageFormBase> = async (values) => {
    const bodyRequiredError =
      !values.body || !values.body.length || !stringToPlainText(values.body)
        ? {
            body: { type: 'required' },
          }
        : null;

    let errors = {};
    if (bodyRequiredError) errors = { ...errors, ...bodyRequiredError };

    return {
      errors,
      values,
    };
  };

  const form = useForm<MessageFormBase>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver,
    shouldFocusError: true,
    shouldUnregister: false,
  });
  const { isValid, isSubmitting } = form.formState;

  useEffect(() => {
    if (
      !editMode &&
      !newComment &&
      JSON.stringify(defaultValues) !== JSON.stringify(form.getValues())
    ) {
      form.reset(defaultValues);
    }
  }, [defaultValues, editMode, form, newComment]);

  const { handleUpload, isUploading, resetProgress, uploadProgress } =
    useUpload<MessageFormBase>({
      onInput: callbackOptions?.onAttachmentAdded,
      onSuccess: form.trigger,
    });

  const onSubmit = async () => {
    await handleSubmit({
      createdAt,
      onError: () => {
        enqueueSnackbar(
          `${t('Something went wrong')}. ${t('Please try again')}.`
        );
        form.setValue('newFiles', []);
      },
      onSuccess: () => {
        searchParams.delete('editComment');
        resetEditorValues();
      },
      references: references || contextReference,
      target,
      threadId,
      values: form.getValues(),
    });
    form.reset(defaultValues);
  };

  const onUploadSuccess = () => form.trigger();

  const imageInputId = `image-input-upload-${messageId}`;

  const { handleImageUpload, isImageUploading = false } =
    useImageUpload<MessageFormBase>({
      editor,
      editorName: 'body',
      onSuccess: onUploadSuccess,
    });

  const CustomButtons: React.ReactNode[] = [
    <EditorButton
      disabled={isImageUploading}
      key="staticImage"
      onClick={() => openFileInput(imageInputId)}
      title={t('Add image')}
    >
      <Icon iconName="image" />
    </EditorButton>,
  ];

  const openFileInput = (currentInputId: string) => {
    const fileInput = document.getElementById(currentInputId);
    fileInput && fileInput.click();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CommentInput
          CustomButtons={CustomButtons}
          containerRef={containerRef}
          editMode={editMode}
          editor={editor}
          id={`message-id-${commentProps.messageId}`}
          newComment={newComment}
          onInputClicked={callbackOptions?.onInputClicked}
          readOnly={readOnly}
          threadId={threadId}
          uploadProgress={uploadProgress.current}
          {...commentProps}
        />
        <AttachmentInput
          disabled={isSubmitting || isUploading}
          id={inputId}
          multiple
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleUpload(event, form.setValue, form.getValues)
          }
          onClick={onAttachmentButtonClicked}
          type="file"
        />
        <AttachmentInput
          id={imageInputId}
          multiple
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleImageUpload(event, form.setValue, form.getValues)
          }
          type="file"
        />
        {!readOnly && (
          <>
            <OuterFooter
              disableSubmit={Boolean(!isValid || isSubmitting || isUploading)}
              editMode={editMode}
              inputId={inputId}
              isUploading={isUploading || false}
              resetEditorValues={() => false}
              resetProgress={resetProgress}
            />
            <ImageUploading isLoading={isImageUploading} />
          </>
        )}
      </form>
    </FormProvider>
  );
};
