import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  QcPermissionCategory,
  QualityControlInstance,
  TaskType,
  useDeleteInstanceMutation,
} from '@pro4all/graphql';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { Action } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import {
  Dialog,
  useTableCheck,
  useTableContext,
} from '@pro4all/shared/ui/general';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { useAnalytics } from '@pro4all/shared/vendor';

export const ResultsActionBar: React.FC<{
  deleteItems?: (ids: string[]) => void;
  showDataViewContextToggle?: boolean;
  showSearch?: boolean;
}> = ({ showDataViewContextToggle, showSearch, deleteItems }) => {
  const { t } = useTranslation();

  const [showDialog, setShowDialog] = useState(false);
  const [checkedAllForms, setCheckedAllForms] = useState(false);
  const chartsVisible = useFeatureFlag('charts');
  const qcGroupPermissionsFlag = useFeatureFlag('qc-group-permissions');

  const { goTo, params } = useRouting();

  const { checkedRows } = useTableCheck<QualityControlInstance>();
  const { rows } = useTableContext();
  const { track } = useAnalytics();

  const {
    resolveCreateAll,
    resolveCreateOwn,
    qualityDeleteAll,
    qualityDeleteOwn,
  } = useQCPermissions({
    category: QcPermissionCategory.Procedure,
    qcGroupPermissionsFlag,
  });

  const hasTbqType = checkedRows.some((row) => row.type === 'tbq');

  const verifyCheckedResults = () => {
    const flag = checkedRows.every((row) => row.type === 'snag')
      ? 'all-snags'
      : checkedRows.every((row) => row.type === 'form')
      ? 'all-forms'
      : 'mixed';

    if (flag === 'all-snags') {
      createResolveTask();
    } else {
      setShowDialog(true);
      setCheckedAllForms(flag === 'all-forms' ? true : false);
    }
  };

  const createResolveTask = () => {
    if (showDialog) setShowDialog(false);
    goTo({
      searchParams: { action: 'createTask', taskType: TaskType.Resolve },
    });
  };

  const { enqueueSnackbar } = useSnackbar();

  const [deleteInstanceMutation] = useDeleteInstanceMutation();

  const deleteQualityResultsFeatureFlag = useFeatureFlag(
    'delete-quality-results'
  );

  const handleDelete = async () => {
    const instanceIds = checkedRows?.map((row) => row?.id).filter(Boolean);

    try {
      const deleteResponse = await deleteInstanceMutation({
        variables: {
          ids: instanceIds,
        },
      });
      const successDeletedItems = deleteResponse.data?.deleteInstance?.filter(
        (item) => item?.success
      );
      const failedDeletedItems = deleteResponse.data?.deleteInstance?.filter(
        (item) => !item?.success
      );
      enqueueSnackbar(
        `${t('{{successNumber}} items deleted', {
          successNumber: successDeletedItems?.length,
        })} ${
          failedDeletedItems?.length
            ? t(', {{failedNumber}} items failed to delete', {
                failedNumber: failedDeletedItems?.length,
              })
            : ''
        }`
      );

      deleteItems?.(instanceIds);
    } catch (_) {
      enqueueSnackbar(t('Failed to delete items'), {
        variant: 'error',
      });
    }
  };

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Create report'),
      dataTestId: 'new-report',
      disabled: false,
      key: 'new-report',
      label: t('Create report'),
      onClick: () => {
        track(Action.CreateReportButtonClicked, {
          itemAmount: checkedRows.length,
          projectId: params.projectId,
        });

        goTo({
          searchParams: { action: 'createReport' },
          state: {
            instanceIds: checkedRows.map(({ id }) => id),
          },
        });
      },
      startIcon: 'document',
    },
    !hasTbqType &&
      (resolveCreateAll || resolveCreateOwn) && {
        ariaLabel: t('Resolve'),
        dataTestId: 'resolve',
        disabled: false,
        key: 'resolve',
        label: t('Resolve'),

        onClick: () => {
          track(Action.CreateResolveTaskButtonClicked, {
            itemAmount: checkedRows.length,
            location: 'Results table',
            projectId: params.projectId,
          });

          verifyCheckedResults();
        },

        startIcon: 'taskSucceeded',
      },
    !hasTbqType &&
      deleteQualityResultsFeatureFlag &&
      (qualityDeleteOwn || qualityDeleteAll) && {
        ariaLabel: t('Delete results'),
        dataTestId: 'delete-snags',
        disabled: false,
        key: 'delete-snags',
        label: t('Delete results'),
        onClick: () => {
          handleDelete();
        },
        startIcon: 'document',
      },
  ].filter(Boolean) as ActionProps[];

  return (
    <>
      <Dialog
        iconName="warning"
        name="resolveTaskWarning"
        onClose={() => setShowDialog(false)}
        onConfirm={checkedAllForms ? null : createResolveTask}
        open={showDialog}
        title={t('Only Snags allowed')}
      >
        {checkedAllForms && (
          <>
            You are trying to create a resolve task with only forms. You need at
            least one snag to create a resolve task.
          </>
        )}
        {!checkedAllForms && (
          <>
            You are trying to add a form to a resolve task. If you continue only
            the selected Snags will be added to the resolve task.
          </>
        )}
      </Dialog>
      <TableActionBar
        altActions={altActions}
        dataTestid="results-action-bar"
        dataViewToggle={showDataViewContextToggle}
        search={showSearch}
      />
    </>
  );
};
