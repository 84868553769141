import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Marker as LeafletMarker } from 'react-leaflet';
import { DivIcon, LatLng, LatLngBounds, Point } from 'leaflet';

import { QualityControlInstanceType } from '@pro4all/graphql';
import { useQualityControlContext } from '@pro4all/quality-control/context';
import {
  ComposedPin,
  getFileUrlByTemplateId,
  TMuiIcon,
} from '@pro4all/shared/composed-snag-form-pin';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { FormPin, SnagPin } from '@pro4all/shared/ui/icons';

import { useMapLinkingContext } from './MapLinkingContext';

const isPointInBounds = (point: LatLng, bounds: LatLngBounds) =>
  bounds.contains(point);
export interface MarkerProps {
  draggable?: boolean;
  iconName?: string | null;
  iconType?: number;
  id?: string;
  lat: number;
  lng: number;
  onClick?: (id: string) => void;
  page: number;
  report?: boolean;
  selectedId?: string;
  statusColor?: string;
  templateId?: string;
  visualContextId?: string;
  type: QualityControlInstanceType;
}

export const Marker = ({
  type,
  id,
  selectedId,
  lat,
  draggable,
  lng,
  visualContextId,
  onClick,
  statusColor,
  page,
  iconName,
  report = false,
  templateId = '',
  iconType = 1,
}: MarkerProps) => {
  const { iconScale } = useMapLinkingContext();

  const { floorplanBounds, setUpdateMarkerObj, updateMarkerObj } =
    useQualityControlContext();

  const [initialPosition, setInitialPosition] = useState({ lat, lng });

  const htmlElement = document.createElement('div');
  const selected = id === selectedId;

  const colorTemplateSelection =
    type === QualityControlInstanceType.Form ? '#28237A' : '#E25314';
  const color = selected || !selectedId ? colorTemplateSelection : '#848484';

  const isCustomerIconsEnabled = useFeatureFlag('customericons');

  const PinFeatureFlagRenderer = isCustomerIconsEnabled ? (
    <ComposedPin
      currentFile={iconType === 2 ? getFileUrlByTemplateId(templateId) : null}
      customColor={statusColor}
      report={report}
      scale={iconScale}
      selectedIconName={iconName as TMuiIcon}
      type={type === QualityControlInstanceType.Form ? 'form' : 'snag'}
    />
  ) : type === QualityControlInstanceType.Form ? (
    <FormPin color={color} iconScale={iconScale} statusColor={statusColor} />
  ) : (
    <SnagPin color={color} iconScale={iconScale} statusColor={statusColor} />
  );

  useEffect(() => {
    ReactDOM.render(PinFeatureFlagRenderer, htmlElement);
  });

  const icon = new DivIcon({
    html: htmlElement,
    iconAnchor: new Point(12 * iconScale, 48 * iconScale),
  });

  return (
    <LeafletMarker
      draggable={draggable}
      eventHandlers={{
        click: () => {
          if (onClick && id) onClick(id);
        },
        dragend: (event: L.LeafletEvent) => {
          if (
            floorplanBounds &&
            isPointInBounds(
              new LatLng(
                event.target.getLatLng().lat,
                event.target.getLatLng().lng
              ),
              floorplanBounds
            )
          ) {
            setUpdateMarkerObj({
              instanceId: id,
              page: page ?? 1,
              visualContextId: visualContextId ? visualContextId : '',
              x: event.target.getLatLng().lng,
              y: event.target.getLatLng().lat,
            });
          } else {
            const marker = event.target;

            const lastPosition = {
              lat: updateMarkerObj.y,
              lng: updateMarkerObj.x,
            };

            marker.setLatLng(
              updateMarkerObj.instanceId === id ? lastPosition : initialPosition
            );
          }
        },
      }}
      icon={icon}
      position={{
        lat: updateMarkerObj.instanceId === id ? updateMarkerObj.y : lat,
        lng: updateMarkerObj.instanceId === id ? updateMarkerObj.x : lng,
      }}
      zIndexOffset={selected && 1000}
    />
  );
};
