import React from 'react';
import { MapContainer } from 'react-leaflet';
import classNames from 'classnames';

import { useVisualContextQuery } from '@pro4all/graphql';
import { Marker } from '@pro4all/quality-control/ui/maps';
import { ReportFloorplanProps } from '@pro4all/shared/types';

import './reportPreview.css';

import { ReportFloorplanContents } from './ReportFloorplanContents';

export const ReportFloorplan = ({
  instances,
  visualContextId,
  page,
}: ReportFloorplanProps) => {
  const { data } = useVisualContextQuery({
    skip: !visualContextId,
    variables: { id: visualContextId },
  });

  if (
    !data?.visualContext ||
    !data?.visualContext?.height ||
    !data.visualContext.width
  ) {
    return null;
  }

  const { height, width, zoomLevels } = data.visualContext;

  return (
    <MapContainer
      attributionControl={false}
      className={classNames('report-floorplan-container', {
        'leaflet-grab': true,
        'leaflet-interactive': true,
        'leaflet-map-pane-transform': height / width > 1,
      })}
      dragging={false}
      scrollWheelZoom={false}
      zoomControl={false}
      zoomSnap={0}
    >
      <ReportFloorplanContents
        mapHeight={height}
        mapWidth={width}
        page={page}
        visualContextId={visualContextId}
        zoomLevels={zoomLevels || 2}
      >
        {instances.map((instance) => {
          if (instance.x && instance.y) {
            return (
              <Marker
                id={instance.id}
                key={instance.id}
                lat={instance.y | 0}
                lng={instance.x | 0}
                page={page}
                report
                type={instance.type}
              />
            );
          }
          return '';
        })}
      </ReportFloorplanContents>
    </MapContainer>
  );
};
