import React, { useCallback, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { QualityControlInstance } from '@pro4all/graphql';
import {
  DEFAULT_BACKGROUND_URL,
  Loader,
  PdfPreview,
  Report,
  ReportStyling,
} from '@pro4all/quality-control/ui/report';
import { groupInstancesByFloorplan } from '@pro4all/quality-control/utils';
import { Box } from '@pro4all/shared/mui-wrappers';
import { PhotoBlobs, ReportPreviewProps } from '@pro4all/shared/types';
import { DialogContainer } from '@pro4all/shared/ui/general';

import { FloorplanReportMaps } from './utils/FloorplanReportMaps';
import { getPhotoBlobs } from './utils/getPhotoBlobs';
import { getReportFloorplans } from './utils/getReportFloorplans';
import { getReportMiniMaps } from './utils/getReportMiniMaps';
import { getSignatureIds } from './utils/getSignaturesIds';
import { ReportMiniMaps } from './utils/ReportMiniMaps';
import { useFetchSignatureReports } from './utils/useFetchSignatureReports';
import { useReportRenderer } from './utils/useReportRenderer';
import { ReportPreviewContainer } from './ReportingMainStyles';
import { useReportOptionsContext } from './ReportOptionsContext';

export const ReportPreview = (props: ReportPreviewProps) => {
  const { t } = useTranslation();
  const id = 'pdf-report';
  const { enqueueSnackbar } = useSnackbar();
  const {
    instances,
    mapInstances,
    instancesGroupedByFloorplan,
    tasks,
    ...rest
  } = props;
  const groupedInstances =
    tasks && tasks.length > 0
      ? groupInstancesByFloorplan(
          tasks
            .map((task) => task.linkedSnagInstances)
            .flat()
            .filter(
              (instance): instance is QualityControlInstance =>
                instance !== null && instance !== undefined
            )
        )
      : instancesGroupedByFloorplan;

  const context = useReportOptionsContext();
  if (!context) throw new Error('ReportOptionsContext not available');

  const {
    reportOptions,
    setMiniMaps,
    miniMaps,
    reportFloorplans,
    setReportFloorplans,
  } = context;

  const [photoBlobs, setPhotoBlobs] = useState<PhotoBlobs | undefined | null>(
    undefined
  );

  const instancesWithSignatures = getSignatureIds(instances);

  const { signatures } = useFetchSignatureReports({ instancesWithSignatures });

  const updateMaps = useCallback(async () => {
    const mapInstanceIds = mapInstances
      .filter((instance) => instance.x && instance.y)
      .map((instance) => instance.id);

    if (!mapInstanceIds.length) {
      setMiniMaps({});
      return;
    }

    if (!miniMaps) {
      const updatedMiniMaps = await getReportMiniMaps(mapInstanceIds);
      if (!updatedMiniMaps || !Object.values(updatedMiniMaps).length) {
        setMiniMaps({});
        return;
      }

      const updatedValue = Object.values(updatedMiniMaps);

      const equality =
        updatedValue.join() === Object.values(miniMaps || {}).join();

      if (!equality || !miniMaps) {
        updatedMiniMaps && setMiniMaps(updatedMiniMaps);
      }
    }

    const validGroupedInstances = groupedInstances?.filter((group) =>
      Boolean(group.visualContextId !== '00000000-0000-0000-0000-000000000000')
    );

    const floorplanTempsIds = validGroupedInstances?.map(
      (group) => `${group.visualContextId}${group.page}`
    );

    if (!floorplanTempsIds?.length) {
      setReportFloorplans({});
      return;
    }

    if (!reportFloorplans) {
      const updatedReportFloorplans = await getReportFloorplans(
        floorplanTempsIds
      );

      if (
        !updatedReportFloorplans ||
        !Object.values(updatedReportFloorplans).length
      ) {
        setReportFloorplans({});
        return;
      }

      const updatedValues = Object.values(updatedReportFloorplans);

      const equality =
        updatedValues.join() === Object.values(reportFloorplans || {}).join();

      if (!equality || !reportFloorplans) {
        updatedReportFloorplans && setReportFloorplans(updatedReportFloorplans);
      }
    }
  }, []);

  useEffect(() => {
    const miniMapsIntervalId = setInterval(async () => {
      await updateMaps();
    }, 5000);

    return () => clearInterval(miniMapsIntervalId);
  }, []);

  useEffect(() => {
    try {
      const resolveBlobs = getPhotoBlobs(instances);
      resolveBlobs().then((blobRecord) => {
        setPhotoBlobs(blobRecord);
      });
    } catch (e) {
      enqueueSnackbar(
        t('Something went wrong. Please try to generate the report again.'),
        { variant: 'error' }
      );
    }
  }, [enqueueSnackbar, instances, t]);

  const component = (
    <Report
      {...rest}
      instancesGroupedByFloorplan={instancesGroupedByFloorplan}
      miniMaps={miniMaps}
      photoBlobs={photoBlobs}
      reportFloorplans={reportFloorplans}
      reportOptions={reportOptions}
      signatures={signatures}
      tasks={tasks}
    />
  );

  const miniMapsLoaded = miniMaps !== undefined;
  const photosLoaded = photoBlobs !== undefined;
  const mediaLoaded = miniMapsLoaded && photosLoaded;

  const html = ReactDOMServer.renderToString(component);

  useReportRenderer({
    html,
    id,
    mediaLoaded,
  });

  if (!context) {
    return null;
  }

  return (
    <ReportPreviewContainer id={id}>
      <ReportStyling
        defaultBg={DEFAULT_BACKGROUND_URL}
        page={t('Page')}
        reportOptions={reportOptions}
      />

      <DialogContainer open={!mediaLoaded}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          minHeight={50}
          minWidth={150}
        >
          <span>
            {t(`Loading ${!photosLoaded ? 'photos' : 'drawings'}`)} ...
          </span>
          <Loader />
        </Box>
      </DialogContainer>

      <div
        id="temp-maps-container"
        style={{ height: '1px', visibility: 'hidden', width: '1px' }}
      >
        <ReportMiniMaps instances={mapInstances} />
      </div>

      <div
        id="temp-floorplans-container"
        style={{ height: '1px', visibility: 'hidden', width: '1px' }}
      >
        <FloorplanReportMaps
          instancesGroupedByFloorplan={groupedInstances ?? []}
        />
      </div>

      <PdfPreview id={`${id}-preview`} />
    </ReportPreviewContainer>
  );
};
