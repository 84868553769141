import { useTranslation } from 'react-i18next';

import { useProjectContext } from '@pro4all/projects/ui/context';
import {
  FilterHeaderType,
  PredefinedFilter,
} from '@pro4all/shared/ui/filtering';

export const usePredefinedFilters = () => {
  const { t } = useTranslation();

  const { projectData } = useProjectContext();

  console.log('projectData', projectData);

  const predefinedFiltes: PredefinedFilter[] = [
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['published'],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'state',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Published'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['draft'],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'state',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Draft'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['organization'],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'scope',
          subPropertyId: 'type',
          translateOptions: true,
        },
      ],
      title: t('Organization'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['project'],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'scope',
          subPropertyId: 'type',
          translateOptions: true,
        },
      ],
      title: t('Project'),
    },
  ];

  return predefinedFiltes.filter((predefinedFilter) => {
    if ([t('Organization'), t('Project')].includes(predefinedFilter.title)) {
      if (projectData.id === '') {
        return false;
      }
    }
    return true;
  });
};
