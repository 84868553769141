import React, { Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { client } from '@pro4all/authentication/src/graph-ql';
import { OpenCdeContextProvider } from '@pro4all/opencde/context';
import { OrganizationContextProvider } from '@pro4all/organization/context';
import { QualityControlContextProvider } from '@pro4all/quality-control/context';
import { SubscriptionContextProvider } from '@pro4all/settings/ui/src/subscription';
import { Routes } from '@pro4all/shared/config';
import {
  ClientRedirectContextProvider,
  ClipboardContextProvider,
  QCTBQContextProvider,
  SideNavContextProvider,
  SidePanelContextProvider,
} from '@pro4all/shared/contexts';
import { useApplication } from '@pro4all/shared/hooks';
import { GenericProvider } from '@pro4all/shared/providers';
import { SavedAnswersContextProvider } from '@pro4all/shared/providers/saved-answers';
import { ThemeProvider } from '@pro4all/shared/themes';
import { Confetti } from '@pro4all/shared/ui/confetti';
import { FileUploadProvider } from '@pro4all/shared/ui/file-upload';
import {
  GlobalStyles,
  SnackbarProvider,
  SortColumnContextProvider,
  TableContextMenuContextProvider,
  ToggleProvider,
} from '@pro4all/shared/ui/general';
import {
  DiscardModalProvider,
  MessageProvider,
} from '@pro4all/shared/ui/messages';
import { Snow } from '@pro4all/shared/ui/snowfall';

import useBrowserTabStyle from '../useBrowserTabStyle';

export const Providers: React.FC = ({ children }) => {
  const application = useApplication();
  const theme = application;

  useBrowserTabStyle({
    theme: theme,
  });

  return (
    <Suspense fallback={<div>Loading ...</div>}>
      <Confetti />
      <Snow />
      <GenericProvider>
        <DndProvider backend={HTML5Backend}>
          <ApolloProvider client={client}>
            <ThemeProvider themeName={application}>
              <GlobalStyles />
              <SideNavContextProvider>
                <DiscardModalProvider>
                  <SidePanelContextProvider>
                    <OpenCdeContextProvider>
                      <FileUploadProvider>
                        <SnackbarProvider>
                          <SubscriptionContextProvider>
                            <ClipboardContextProvider>
                              <ClientRedirectContextProvider>
                                <SavedAnswersContextProvider>
                                  <QCTBQContextProvider>
                                    <SortColumnContextProvider>
                                      <ToggleProvider
                                        cycle
                                        limit={1}
                                        scope="Tooltip"
                                      >
                                        <TableContextMenuContextProvider>
                                          <BrowserRouter>
                                            <Switch>
                                              <Route
                                                exact
                                                path={Object.values(Routes)}
                                              >
                                                <MessageProvider>
                                                  <OrganizationContextProvider>
                                                    <QualityControlContextProvider>
                                                      {children}
                                                    </QualityControlContextProvider>
                                                  </OrganizationContextProvider>
                                                </MessageProvider>
                                              </Route>

                                              <Route>
                                                <MessageProvider>
                                                  <OrganizationContextProvider>
                                                    <QualityControlContextProvider>
                                                      {children}
                                                    </QualityControlContextProvider>
                                                  </OrganizationContextProvider>
                                                </MessageProvider>
                                              </Route>
                                            </Switch>
                                          </BrowserRouter>
                                        </TableContextMenuContextProvider>
                                      </ToggleProvider>
                                    </SortColumnContextProvider>
                                  </QCTBQContextProvider>
                                </SavedAnswersContextProvider>
                              </ClientRedirectContextProvider>
                            </ClipboardContextProvider>
                          </SubscriptionContextProvider>
                        </SnackbarProvider>
                      </FileUploadProvider>
                    </OpenCdeContextProvider>
                  </SidePanelContextProvider>
                </DiscardModalProvider>
              </SideNavContextProvider>
            </ThemeProvider>
          </ApolloProvider>
        </DndProvider>
      </GenericProvider>
    </Suspense>
  );
};
