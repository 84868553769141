import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  FormLinkedSnag,
  QualityControlInstance,
  ResourceType,
  useQualityControlInstancesQuery,
  useTaskQuery,
} from '@pro4all/graphql';
import {
  useBatchFetchResults,
  useFetchResourcePermissions,
} from '@pro4all/quality-control/data-access';
import { FloorPlanRouterState } from '@pro4all/quality-control/data-access';
import { getFloorPlanRoute } from '@pro4all/quality-control/utils';
import { Action } from '@pro4all/shared/config';
import { Menu, MenuItem, MenuProps } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { StylingDefaults } from '@pro4all/shared/themes';
import { Button, IconButton } from '@pro4all/shared/ui/buttons';
import { ListCard, Timestamp } from '@pro4all/shared/ui/general';
import { BigMessageNoLinkedSnags } from '@pro4all/shared/ui/messages';
import { StyledTooltip } from '@pro4all/shared/ui/side-navigation';
import { useAnalytics } from '@pro4all/shared/vendor';

import { useUnlinkSnag } from '../hooks/useUnlinkSnag';

import { LinkSnagDialog } from './LinkSnagDialog';
import { PlaceSelectSnagDialog } from './PlaceSelectSnagDialog';

enum State {
  Idle,
  Link,
  Place,
}
interface Props {
  editMode: boolean;
  fieldId: string;
  isDirty: boolean;
  linkedSnags?: FormLinkedSnag[] | null;
  refetchQualityInstance?: () => void;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={1}
    transformOrigin={{
      horizontal: 280,
      vertical: -50,
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    '& .MuiMenuItem-root': {
      '& .MuiCard-root': {
        cursor: 'default',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      padding: '0',
    },
    boxShadow: StylingDefaults.boxShadow,
    maxHeight: 328,
    minWidth: 350,
  },
}));

export const SnagActionButtons = ({
  editMode,
  fieldId,
  isDirty,
  linkedSnags,
  refetchQualityInstance,
}: Props) => {
  const [state, setState] = useState<State>(State.Idle);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { searchParams, params, goTo, url } = useRouting();
  const { projectId: paramsProjectId } = params;

  const isTBQQuestionView = searchParams.is('view', 'view-question-sidebar');
  const taskId = searchParams.get('taskId');
  const resultId = searchParams.get('id');

  const UnlinkSnag = useUnlinkSnag({
    linkedSnags,
    refetchQualityInstance,
  });

  const { data: taskData, error: taskError } = useTaskQuery({
    fetchPolicy: 'cache-and-network',
    skip: !taskId,
    variables: { id: taskId },
  });

  const projectId = taskData?.task?.project?.id ?? paramsProjectId;

  const { snagCreateAssigned, snagCreateAll } = useFetchResourcePermissions({
    resourceId: resultId,
    resourceType: ResourceType.Instance,
    tbqProjectId: projectId,
  });

  const currentTaskVisualContext = taskData?.task?.visualContexts.map(
    (floorplan) => floorplan.id
  );

  const { data: projectSnagFormInstances, error: errorSnagFormInstances } =
    useQualityControlInstancesQuery({
      fetchPolicy: 'cache-and-network',
      variables: { projectId, visualContextIds: currentTaskVisualContext },
    });

  const projectSnagInstances =
    projectSnagFormInstances?.qualityControlInstances;
  const snagIntancesList = projectSnagInstances?.length
    ? projectSnagInstances
    : [];

  //This filter is to exclude instances returned from the BE that are not assigned to any visualContext
  const filteredSnagInstances = snagIntancesList.filter(
    (snag) => snag.visualContext !== null
  );

  //Here we are filtering the snagInstances that are available for linking
  const linkedSnagsIds = linkedSnags.map((link) => link.id);
  const linksAvailableToLink = filteredSnagInstances.filter(
    (snag) => !linkedSnagsIds.includes(snag.id)
  );

  const { data: listOfSnagIntancesToUnlink } = useBatchFetchResults(
    linkedSnags.map((x) => x.id)
  );

  const renderDialog = () => {
    switch (state) {
      case State.Place:
        return (
          <PlaceSelectSnagDialog
            fieldId={fieldId}
            onClose={() => setState(State.Idle)}
            projectId={projectId}
          />
        );
      case State.Link:
        return (
          <LinkSnagDialog
            fieldId={fieldId}
            onClose={() => setState(State.Idle)}
            refetchQualityInstance={refetchQualityInstance}
            snagIntancesList={linksAvailableToLink}
            taskError={taskError}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderDialog()}
      <ButtonsWrapper>
        {editMode && (
          <>
            {(snagCreateAll || snagCreateAssigned) && (
              <StyledTooltip
                placement="bottom"
                title={
                  isDirty
                    ? t('Save your changes to place a Snag')
                    : t('Create a new Snag')
                }
              >
                <IconButton
                  color="default"
                  disableBorder
                  disabled={isDirty}
                  iconName="placeSnag"
                  onClick={() => {
                    setState(State.Place);
                  }}
                />
              </StyledTooltip>
            )}
            <StyledTooltip
              placement="bottom"
              title={
                linksAvailableToLink.length === 0
                  ? t('No Snags existing to link')
                  : t('Link existing Snag')
              }
            >
              <IconButton
                color="default"
                disableBorder
                iconName="link"
                onClick={() => {
                  linksAvailableToLink.length === 0
                    ? setState(State.Idle)
                    : setState(State.Link);
                }}
              />
            </StyledTooltip>
          </>
        )}
        <StyledTooltip
          placement="bottom"
          title={
            listOfSnagIntancesToUnlink?.qualityControlInstances.length !== 0
              ? t('Linked Snags')
              : t('There are no linked Snags')
          }
        >
          <StyledButton
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            id="demo-customized-button"
            onClick={handleClick}
            startIcon="snag"
          >
            {linkedSnags !== undefined ? linkedSnags.length : '0'}
          </StyledButton>
        </StyledTooltip>
        <StyledMenu
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
            style: {
              height: '328px',
              padding: '0',
              width: '350px',
            },
          }}
          anchorEl={anchorEl}
          elevation={1}
          id="demo-customized-menu"
          onClose={() => setAnchorEl(null)}
          open={open}
        >
          {linkedSnags.length !== 0 ? (
            listOfSnagIntancesToUnlink?.qualityControlInstances.map(
              (snag: QualityControlInstance) => (
                <MenuItem
                  disableGutters
                  disableRipple
                  key={snag.id}
                  value={snag.id}
                >
                  <ListCard
                    disabledButtonBorder
                    iconName="snag"
                    key={snag.id}
                    menuItems={
                      !isTBQQuestionView
                        ? [
                            {
                              label: `Snag list`,
                              onClick: (e) => {
                                e.stopPropagation();
                                UnlinkSnag({ id: snag.id });
                              },
                              startIcon: 'unlink',
                            },
                          ]
                        : []
                    }
                    meta={[
                      `${snag?.createdBy?.displayName}`,
                      <Timestamp date={snag?.createdAt} format="lll" />,
                    ]}
                    onClick={() => {
                      track(Action.LinkedSnagOpened, {
                        id: snag.id,
                        name: snag.name,
                        projectId,
                      });
                      goTo(getFloorPlanRoute(params), {
                        params: {
                          objectId: params.objectId,
                          projectId,
                          visualContextId: snag.visualContext.id,
                        },
                        searchParams: {
                          action: 'viewResult',
                          id: snag.id,
                          page: snag.page,
                        },
                        state: {
                          previousPageName: 'form',
                          previousPageUrl: url,
                        } as FloorPlanRouterState,
                      });
                    }}
                    title={'#' + snag.reference + ' ' + snag.name}
                  />
                </MenuItem>
              )
            )
          ) : (
            <BigMessageNoLinkedSnags />
          )}
        </StyledMenu>
      </ButtonsWrapper>
    </>
  );
};

export const StyledButton = styled(Button)`
  && {
    border-radius: 5px;
    padding: 5px;
    min-width: 50px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;
