import { useQualityControlContext } from '@pro4all/quality-control/context';
import { FloorPlanService } from '@pro4all/quality-control/data-access';
import { useFileUploadContext } from '@pro4all/shared/ui/file-upload';

export function useFloorPlanUpload(projectId: string) {
  const { upload } = useFileUploadContext();

  const { canUpdate } = useQualityControlContext();

  const canUpdateIds = canUpdate.map((fpToUpdate) => fpToUpdate.id);

  return async (files: File[]) => {
    upload(files, (file, onProgress) =>
      canUpdateIds.includes(file.id)
        ? FloorPlanService.updateFloorPlan(
            canUpdate.filter((fpToUpdate) => fpToUpdate.id === file.id)[0]
              .visualContextId,
            file,
            onProgress
          )
        : FloorPlanService.uploadFloorPlan(projectId, file, onProgress)
    );
  };
}
