/* eslint-disable typescript-sort-keys/string-enum */
import styled from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { ColumnsMap, ImageColumns } from '@pro4all/shared/types';

// With width 100% pagedJS doesn't correctly render resulting in an infinite amount of pages
export const Section = styled.section<{
  borderRight?: boolean;
}>`
  page-break-inside: avoid;
  border: 1px solid ${customColors.grey300};
  border-radius: 4px 0 0 4px;
  padding: 0;
  border-right: ${({ borderRight }) =>
    borderRight ? `1px solid ${customColors.grey300}` : 'none'};
`;

export const SectionHeader = styled.div`
  background: ${customColors.grey300};
  padding: 1mm;
  padding-left: var(--padding-left);
  border-top: 0.5mm solid #ededed;
  border-radius: 4px 0 0 0;
  font-size: var(--font-size);
  margin: 0;
`;

export const SectionBody = styled.div`
  padding: 2mm;
  padding-right: 0;
  border-top: 0;
`;

const columns: ColumnsMap = {
  aOne: 1,
  bTwo: 2,
  cThree: 3,
  dFour: 4,
};

export const PhotoGrid = styled.div<{
  column?: keyof typeof ImageColumns;
  inline?: boolean;
}>`
  --img-columns: ${({ column = 'bTwo' }) => columns[column]};
  --img-border: ${({ column = 'bTwo' }) =>
    column === 'aOne' ? 'none' : '1px dotted rgb(237, 237, 237)'};

  display: grid;
  grid-template-columns: repeat(var(--img-columns), minmax(0, 1fr));
  gap: 0.5rem;

  .reports-img-container {
    border: var(--img-border);
    display: flex;
    vertical-align: middle;
  }

  img {
    width: 100%;
    margin: auto;
  }
`;

export const Page = styled.div.attrs({ className: 'page' })`
  margin: 5mm;
`;

export const MetaLeft = styled.div.attrs({ className: 'meta-left' })`
  display: flex;
  flex: 1;
  padding: 12pt;
`;

export const MetaRight = styled.div.attrs({ className: 'meta-right' })`
  aspect-ratio: 1 / 1;
  border-left: 1px solid ${customColors.grey300};
  height: 256px;
  overflow: hidden;
  align-self: center;
  display: flex;

  .map-container {
    z-index: 0;
  }
`;
