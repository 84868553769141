import React, { useEffect } from 'react';
import { Formik } from 'formik';

import { ReportConfigTypes } from '@pro4all/shared/types';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import {
  defaultTemplate,
  useReportOptionsContext,
} from '../ReportOptionsContext';

import { CustomOptions } from './CustomOptions';
import { StylePresetSelect } from './StylePresetSelect';

export const ReportOptionsForm = ({
  formTemplateOptions,
  onClose,
  previewInstances,
  snagTemplateOptions,
}: Pick<
  ReportConfigTypes,
  'formTemplateOptions' | 'onClose' | 'previewInstances' | 'snagTemplateOptions'
>) => {
  const context = useReportOptionsContext();
  if (!context) throw new Error('ReportOptionsContext not available');
  const { customMode, reportOptions, setTemplateOptions } = context;

  //Ensure the report options always atleast have a set of default options for each template
  useEffect(() => {
    [...snagTemplateOptions, ...formTemplateOptions].forEach((template) => {
      if (
        !Object.keys(reportOptions.templates ?? {}).some(
          (templateId) => templateId === template.id
        )
      ) {
        setTemplateOptions(template.id, defaultTemplate);
      }
    });
  }, [
    snagTemplateOptions,
    formTemplateOptions,
    setTemplateOptions,
    reportOptions.templates,
  ]);

  return (
    <Formik
      enableReinitialize
      initialValues={reportOptions}
      onSubmit={() => {
        onClose && onClose();
      }}
    >
      <FormWrapper>
        <StylePresetSelect />
        {customMode ? (
          <CustomOptions
            formTemplateOptions={formTemplateOptions}
            previewInstances={previewInstances}
            snagTemplateOptions={snagTemplateOptions}
          />
        ) : null}
      </FormWrapper>
    </Formik>
  );
};
