import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

import * as Styled from './VisibilityToggle.styles';

export const VisibilityToggle = ({
  labelHide = 'Hide',
  labelShow = 'Show',
  toggle,
  toggleValue,
}: {
  labelHide?: string;
  labelShow?: string;
  toggle: () => void;
  toggleValue: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Styled.VisibilityToggle onClick={() => toggle()}>
      <Box alignItems="center" display="flex" mr={1}>
        <Icon iconName={toggleValue ? 'visibility' : 'visibilityOff'} />
      </Box>
      <Box alignItems="center" display="flex">
        {toggleValue ? t(labelHide) : t(labelShow)}
      </Box>
    </Styled.VisibilityToggle>
  );
};
