import { useTranslation } from 'react-i18next';

import {
  QcPermissionCategory,
  ResourceType,
  TemplateType,
} from '@pro4all/graphql';
import {
  useFetchResourcePermissions,
  useQCPermissions,
} from '@pro4all/quality-control/data-access';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { ContextMenu } from '@pro4all/shared/ui/context-menu';

import { useMapLinkingContext } from './MapLinkingContext';

export const MapContextMenu = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const taskId = searchParams.get('taskId');
  const {
    setIsContextMenuOpen,
    setIsSelectTemplateDialogOpen,
    setSelectedTemplateType,
  } = useMapLinkingContext();

  const isSelectIconEnabled = useFeatureFlag('customericons');
  const qcGroupPermissionsFlag = useFeatureFlag('qc-group-permissions');

  const handleContextMenuSelection = (selection: TemplateType) => {
    // Define if the user selected snag template or form template
    setSelectedTemplateType(selection);
    setIsContextMenuOpen(false);
    setIsSelectTemplateDialogOpen(true);
  };
  const { formCreateAll, snagCreateAll } = useQCPermissions({
    category: QcPermissionCategory.Qcs,
    qcGroupPermissionsFlag,
  });
  const { snagCreateAssigned, formCreateAssigned } =
    useFetchResourcePermissions({
      resourceId: taskId,
      resourceType: ResourceType.Task,
    });

  const defaultMenu = [
    (snagCreateAll || snagCreateAssigned) && {
      ariaLabel: t('Add snag'),
      dataTestId: 'add-snag',
      key: 'add-snag',
      label: t('Add snag'),
      onClick: () => handleContextMenuSelection(TemplateType.Snag),
      startIcon: isSelectIconEnabled ? 'staticSnagIcon' : 'snag',
    },
    (formCreateAll || formCreateAssigned) && {
      ariaLabel: t('Add form'),
      dataTestId: 'add-form-template',
      key: 'add-form-template',
      label: t('Add form'),
      onClick: () => handleContextMenuSelection(TemplateType.Form),
      startIcon: isSelectIconEnabled ? 'staticFormIcon' : 'addFormTemplate',
    },
  ].filter(Boolean) as ActionProps[];

  return (
    <ContextMenu
      data-testid="snags-template-context-menu"
      menuItems={defaultMenu}
      onClose={() => setIsContextMenuOpen(false)}
      open
    />
  );
};
