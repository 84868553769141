import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  QcPermissionCategory,
  useCreateVisualContextFromDocumentMutation,
  VisualContextStatus,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useSubscriptionRestriction } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

export const useVisualContextAction = ({
  selection,
}: Pick<DocumentActionProps, 'selection'>) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [clicked, setClicked] = useState(false);
  const {
    goTo,
    params: { projectId },
  } = useRouting();
  const { contextScopedOrganizationSubscriptionLevel } =
    useOrganizationContext();

  const { hasQualityControlLicense } = useSubscriptionRestriction(
    contextScopedOrganizationSubscriptionLevel
  );

  const hasQualityControlFeatureFlag = useFeatureFlag('qualitycontrol');
  const qcGroupPermissionsFlag = useFeatureFlag('qc-group-permissions');
  const { floorCreateAll } = useQCPermissions({
    category: QcPermissionCategory.Qcs,
    qcGroupPermissionsFlag,
  });

  const { id: documentId, extension, visualContext } = selection[0] ?? {};
  const { id: visualContextId } = visualContext ?? {};

  // Also include documentId as a dependancy, because if we switch between documents localState 'clicked' must be false.
  useEffect(() => {
    setClicked(false);
  }, [documentId, setClicked, visualContext]);

  const [createVisualContextFromDocument] =
    useCreateVisualContextFromDocumentMutation();

  const createVisualContext = useCallback(async () => {
    setClicked(true);
    await createVisualContextFromDocument({
      variables: { documentId },
    });
    enqueueSnackbar(t('Creating new floorplan'));
  }, [createVisualContextFromDocument, documentId, enqueueSnackbar, t]);

  const goToVisualContext = useCallback(() => {
    goTo('projectQualityControlFloorPlan', {
      params: { projectId, visualContextId },
    });
  }, [goTo, projectId, visualContextId]);

  const converting: ActionProps = {
    ariaLabel: t('Converting floorplan'),
    dataTestId: 'converting-floorplan',
    key: 'converting-floorplan',
    label: t('Converting floorplan'),
    startIcon: 'update',
  };

  const converted: ActionProps = {
    ariaLabel: t('Go to floorplan'),
    dataTestId: 'document-go-to-floorplan',
    key: 'document-go-to-floorplan',
    label: t('Go to floorplan'),
    onClick: goToVisualContext,
    startIcon: 'link',
  };

  const convertable: ActionProps = {
    ariaLabel: t('Create floor plan from document'),
    dataTestId: 'document-create-visual-context',
    key: 'document-create-visual-context',
    label: t('Create floor plan from document'),
    onClick: createVisualContext,
    startIcon: 'map',
  };

  if (
    !floorCreateAll ||
    !hasQualityControlFeatureFlag ||
    !projectId ||
    !hasQualityControlLicense ||
    selection[0]?.isExpected ||
    !['pdf', 'jpg', 'png'].includes(extension)
  ) {
    return null;
  }

  if (
    clicked ||
    (visualContext && visualContext.status !== VisualContextStatus.Processed)
  ) {
    return converting;
  }

  if (visualContext && visualContext.status === VisualContextStatus.Processed) {
    return converted;
  }

  return convertable;
};
