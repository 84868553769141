import dayjs from 'dayjs';

import { NotificationsUser } from '@pro4all/graphql';
import { ColumnProps } from '@pro4all/shared/ui/general';

const formatDate = (value: string) => {
  if (dayjs(value).isValid()) return dayjs(value).format('lll');
  else return value;
};

export const useColumns = (): ColumnProps<NotificationsUser>[] => [
  {
    align: 'left',
    defaultSort: true,
    defaultSortOrder: 'asc',
    key: 'displayName',
    title: 'Name', // i18n
    width: 176,
  },
  {
    align: 'left',
    defaultSort: true,
    defaultSortOrder: 'asc',
    key: 'email',
    title: 'Email', // i18n
    width: 176,
  },
  {
    align: 'left',
    defaultSort: true,
    defaultSortOrder: 'asc',
    key: 'organization.name',
    render: ({ organization }) => organization?.name,
    title: 'Organization', // i18n
    width: 176,
  },
  {
    align: 'left',
    defaultSort: true,
    defaultSortOrder: 'asc',
    key: 'lastUpdatedOn',
    render: ({ lastUpdatedOn }) =>
      lastUpdatedOn ? formatDate(lastUpdatedOn) : '',
    title: 'Last updated on', // i18n
    width: 160,
  },
  {
    align: 'left',
    defaultSort: true,
    defaultSortOrder: 'asc',
    key: 'lastUpdatedBy',
    title: 'Last updated by', // i18n
    width: 216,
  },
];
