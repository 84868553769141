import React from 'react';
import { useTranslation } from 'react-i18next';

import { TaskType } from '@pro4all/graphql';
import { TaskInstancePageProps } from '@pro4all/shared/types';
import { IconName } from '@pro4all/shared/ui/icons';

import QCTaskResources from './linkedTaskResources/QCTaskResources';
import ResolveTaskResources from './linkedTaskResources/ResolveTaskResources';
import { Instance, MetaLeft, Page } from './elements';

export const TaskInstancePage = ({
  instance,
  reportOptions,
}: TaskInstancePageProps) => {
  const { t } = useTranslation();

  const limitDescription = (description: string) => {
    const wordLimit = 140;
    const words = description.split(' ');
    if (words.length <= wordLimit) {
      return description;
    } else {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
  };

  const getResourcesComponent = () => {
    if (instance.type === TaskType.QualityControl)
      return <QCTaskResources instance={instance} />;
    else if (instance.type === TaskType.Resolve)
      return <ResolveTaskResources instance={instance} />;
  };

  const getTaskIcon = () => {
    const iconMap: Partial<{ [key in TaskType]: IconName }> = {
      [TaskType.QualityControl]: 'qcTask',
      [TaskType.Resolve]: 'taskSucceeded',
    };
    return iconMap[instance.type ?? TaskType.QualityControl] ?? 'qcTask';
  };

  return (
    <Page>
      <Instance.Header>
        <MetaLeft>
          <Instance.List
            compactLists={reportOptions.compactLists}
            inlineLists={reportOptions.inlineLists}
            labelWidth={reportOptions.inlineLists ? 30 : 40}
          >
            <Instance.Title>
              <Instance.Icon iconName={getTaskIcon()} />
              {instance.name}
            </Instance.Title>
            <Instance.Item style={{ display: 'block' }}>
              <Instance.Label>{t('Description')}</Instance.Label>
              <Instance.Value>
                {instance.description
                  ? limitDescription(instance.description)
                  : '-'}
              </Instance.Value>
            </Instance.Item>
            <Instance.Item>
              <Instance.Label>{t('Responsible')}</Instance.Label>
              <Instance.Value>
                {instance?.assignment?.[0]?.displayName}
              </Instance.Value>
            </Instance.Item>
            <Instance.Item>
              <Instance.Label>{t('Deadline')}</Instance.Label>
              <Instance.Value>
                {instance.endTime
                  ? new Date(instance.endTime).toLocaleDateString()
                  : '-'}
              </Instance.Value>
            </Instance.Item>
            <Instance.Item>
              <Instance.Label>{t('Status')}</Instance.Label>
              <Instance.Value>{t(`${instance.status}`)}</Instance.Value>
            </Instance.Item>
            <Instance.Item>
              <Instance.Label>{t('Created by')}</Instance.Label>
              <Instance.Value>
                {instance.createdByUser?.displayName}
              </Instance.Value>
            </Instance.Item>
            <Instance.Item>
              <Instance.Label>{t('Created at')}</Instance.Label>
              <Instance.Value>
                {new Date(instance.createdAt).toLocaleDateString()}
              </Instance.Value>
            </Instance.Item>
            <Instance.Item>
              <Instance.Label>{t('Completed at')}</Instance.Label>
              <Instance.Value>
                {instance.completedAt
                  ? new Date(instance.completedAt).toLocaleDateString()
                  : '-'}
              </Instance.Value>
            </Instance.Item>
            <Instance.Item>
              <Instance.Label>{t('Category')}</Instance.Label>
              <Instance.Value>{instance.taskCategoryName}</Instance.Value>
            </Instance.Item>
            {getResourcesComponent()}
          </Instance.List>
        </MetaLeft>
      </Instance.Header>
    </Page>
  );
};
