import { TbqBrandcheckMultiResult } from '@pro4all/graphql';

export enum BrandcheckProgressBarType {
  Category = 'category',
  Goal = 'goal',
}

export const calculateAverages = (
  brandcheckMultiResult: TbqBrandcheckMultiResult[]
) => {
  const categoriesMap: Record<string, { name: string; percentages: number[] }> =
    {};
  const goalsMap: Record<string, { name: string; percentages: number[] }> = {};

  const calculateAveragePercentages = (
    categoriesOrGoalsMap: Record<
      string,
      { name: string; percentages: number[] }
    >
  ) =>
    Object.entries(categoriesOrGoalsMap).map(([id, { name, percentages }]) => ({
      id,
      name,
      percentage:
        percentages.reduce((acc, perc) => acc + perc, 0) / percentages.length,
    }));

  brandcheckMultiResult.forEach((result) => {
    result.categories.forEach((category) => {
      if (!categoriesMap[category.id]) {
        categoriesMap[category.id] = {
          name: category?.name ?? '',
          percentages: [],
        };
      }
      categoriesMap[category.id].percentages.push(category.percentage);
    });

    result.operationGoals.forEach((goal) => {
      if (!goalsMap[goal.id]) {
        goalsMap[goal.id] = { name: goal?.name ?? '', percentages: [] };
      }
      goalsMap[goal.id].percentages.push(goal.percentage);
    });
  });

  const categoriesAverage = calculateAveragePercentages(categoriesMap);
  const goalsAverage = calculateAveragePercentages(goalsMap);

  return { categoriesAverage, goalsAverage };
};

export const calculateAveragesByObject = (
  filteredResults: TbqBrandcheckMultiResult[],
  id: string,
  type: BrandcheckProgressBarType
) => {
  const objectMap: Record<
    string,
    { objectName: string; objectParentId: string; percentages: number[] }
  > = {};

  filteredResults.forEach((result) => {
    const percentage =
      type === BrandcheckProgressBarType.Category
        ? result.categories.find((category) => category.id === id)
            ?.percentage ?? 0
        : result.operationGoals.find((goal) => goal.id === id)?.percentage ?? 0;

    if (!objectMap[result.objectName]) {
      objectMap[result.objectName] = {
        objectName: result.objectName,
        objectParentId: result.objectParentId ?? '',
        percentages: [],
      };
    }

    objectMap[result.objectName].percentages.push(percentage);
  });

  return Object.entries(objectMap).map(
    ([objectName, { objectParentId, percentages }]) => ({
      averagePercentage:
        percentages.reduce((acc, perc) => acc + perc, 0) / percentages.length,
      objectName,
      objectParentId,
    })
  );
};
