import { ReportSelectionFieldProps } from '@pro4all/shared/types';
import { Icon } from '@pro4all/shared/ui/icons';

import { Instance } from '../elements';

import { FieldDescription } from './FieldDescription';
import LinkedSnagsList from './LinkedSnagsList';

export const ReportSelectionField = ({
  item,
  key,
  linkedSnags,
  reportOptions,
  templateOptions,
}: ReportSelectionFieldProps) => {
  const { displayName, displayDescription, value, valueType } = item;

  const values = valueType?.multiSelect ? value?.split(',') || [] : [value];

  return (
    <Instance.Item key={key}>
      <Instance.Label>{displayName}</Instance.Label>
      <Instance.Value>
        {values.length > 1 ? (
          <Instance.SelectionOuter inline={reportOptions?.inlineLists}>
            <Instance.OptionWrapper>
              {values.map((option) => (
                <Instance.SelectionInner key={option}>
                  <Icon iconName="check" />
                  {option}
                </Instance.SelectionInner>
              ))}
            </Instance.OptionWrapper>
          </Instance.SelectionOuter>
        ) : (
          value
        )}
        {templateOptions?.showDescription && (
          <FieldDescription displayDescription={displayDescription} />
        )}
        {templateOptions?.showLinkedSnags && (
          <LinkedSnagsList linkedSnags={linkedSnags} />
        )}
      </Instance.Value>
    </Instance.Item>
  );
};

export default ReportSelectionField;
