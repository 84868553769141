import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';
import { ReportHierarchyFieldProps } from '@pro4all/shared/types';

import { Instance } from '../elements';

import { FieldDescription } from './FieldDescription';
import LinkedSnagsList from './LinkedSnagsList';

const ReportHierarchyField = ({
  key,
  linkedSnags,
  item,
  templateOptions,
}: ReportHierarchyFieldProps) => (
  <Instance.Item key={key}>
    <Instance.Label>{item.displayName}</Instance.Label>
    <Box>
      {item.value ? (
        JSON.parse(item.value ?? '')?.map(
          (val: { breadcrumbs: string[]; value: string }) => (
            <Instance.Value>
              <FlexInstanceValue>
                {val?.breadcrumbs?.join(' > ')}
              </FlexInstanceValue>
              {templateOptions?.showDescription && (
                <FieldDescription
                  displayDescription={item.displayDescription}
                />
              )}
              {templateOptions?.showLinkedSnags && (
                <LinkedSnagsList linkedSnags={linkedSnags} />
              )}
            </Instance.Value>
          )
        )
      ) : (
        <Instance.Value>
          '-'
          {templateOptions?.showDescription && (
            <FieldDescription displayDescription={item.displayDescription} />
          )}
          {templateOptions?.showLinkedSnags && (
            <LinkedSnagsList linkedSnags={linkedSnags} />
          )}
        </Instance.Value>
      )}
    </Box>
  </Instance.Item>
);

const FlexInstanceValue = styled(Instance.Value)`
  display: flex;
`;

export default ReportHierarchyField;
