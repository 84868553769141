import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Formik } from 'formik';

import {
  FieldDefinition,
  TemplateService,
  useFieldDefinitionQuery,
  useFieldDefinitionTypesQuery,
  useHierarchicalListsIncludeQuery,
  ValueTypeName,
} from '@pro4all/graphql';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { isSubmitDisabled } from '@pro4all/shared/forms';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option } from '@pro4all/shared/types';
import {
  FormFooter,
  FormikForm,
  FormikInput,
  FormikRadioGroup,
  FormikSearchableSelect,
  FormikTextarea,
  Loader,
} from '@pro4all/shared/ui/general';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { useFieldConfig } from '../configs/useFieldConfig';
import { usePublishTemplatesModal } from '../modals/usePublishTemplatesModal';

import { getInitialValues } from './getInitialValues';
import { useFieldDefinitionFormConfig } from './useFieldDefinitionFormConfig';
import { useGetTypeOptions } from './useGetTypeOptions';
import { useSubmit } from './useSubmit';

interface Props {
  isReusable?: boolean;
  onClose: () => void;
  selectedField?: FieldDefinition;
}

export const FieldDefinitionForm: React.FC<Props> = ({
  isReusable = false,
  onClose,
  selectedField,
}) => {
  const { t } = useTranslation();

  const fieldConfig = useFieldConfig();
  const { templateService } = useMetaDataContext();
  const inputRef = useRef(null);
  const { data } = useFieldDefinitionTypesQuery({
    variables: { templateService },
  });

  const editMode = Boolean(selectedField);

  const { data: dataFieldDefinition, loading } = useFieldDefinitionQuery({
    fetchPolicy: 'network-only', // Do not change to some other cache policy, because then the prop 'valueType' will cache on id (which is the type id, not the fieldDefinitionId!!). And that will cause a display of f.i. the wrong range values for a Number type.
    skip: !editMode,
    variables: { fieldDefinitionId: selectedField?.id, templateService },
  });
  const {
    params: { projectId },
  } = useRouting();

  const { data: dataHierarchicalLists } = useHierarchicalListsIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: templateService === TemplateService.Documents,
    variables: {
      includeOrgItems: true,
      projectId,
    },
  });

  const hierarchyListOptions: Option[] = dataHierarchicalLists
    ? dataHierarchicalLists.hierarchicalLists.map(({ id, name }) => ({
        id,
        label: name,
      }))
    : [];

  const orgMetaData = useRouteMatch(Routes.metaDataDocuments);
  const projMetadata = useRouteMatch(Routes.projectMetaDataDocuments);

  const orgQualityControl = useRouteMatch(Routes.metaDataQualityControl);
  const projQualityControl = useRouteMatch(
    Routes.projectMetaDataQualityControl
  );

  let { typeOptions } = useGetTypeOptions({ data });
  const showUserSelection = useFeatureFlag('user-field');

  const metadataDocument = orgMetaData || projMetadata;
  const multiRow = metadataDocument ? 0 : 1;
  if (metadataDocument)
    typeOptions = typeOptions.filter((obj) => {
      if (
        obj.id === 'Description' ||
        (!showUserSelection && obj.id === 'UserSelection') ||
        obj.id === 'TimeSpan'
      )
        return false;
      return true;
    });

  const { getField, validationSchema } = useFieldDefinitionFormConfig();
  const displayNameField = getField('displayName');
  const displayDescriptionField = getField('displayDescription');
  const typeField = getField('type');
  const nameField = getField('name');
  const descriptionField = getField('description');
  const displayType = getField('displayType');

  const initialValues = getInitialValues({
    fieldType: dataFieldDefinition?.fieldDefinition?.type,
    hierarchyListOptions,
    selectedField: isReusable
      ? dataFieldDefinition?.fieldDefinition
      : selectedField,
    typeOptions,
  });

  const onSubmit = useSubmit({
    data,
    isReusable,
    onClose,
    selectedField: dataFieldDefinition?.fieldDefinition,
  });

  const { publishTemplatesModal, setShowModal, setUpdateCallback } =
    usePublishTemplatesModal({
      updateAndPublishLabel: 'Update and publish', // i18n
      updateLabel: 'Update', // i18n
    });

  return loading ? (
    <Loader />
  ) : (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onReset={() => inputRef.current.focus()}
      onSubmit={(values, helpers) => {
        if (editMode) {
          helpers.setSubmitting(false);
          setUpdateCallback(() => () => {
            onSubmit(values, helpers);
          });
          setShowModal(true);
        } else {
          onSubmit(values, helpers);
        }
      }}
      validateOnBlur
      validateOnChange
      validationSchema={validationSchema}
    >
      {({ dirty, errors, isSubmitting, values }) => {
        const { CustomForm } = values?.type?.id
          ? fieldConfig[values?.type?.id as keyof typeof ValueTypeName]
          : { CustomForm: null };
        return (
          <FormikForm>
            <FormWrapper>
              <FormikInput
                autoFocus
                fieldsToSync={editMode ? [] : ['name']}
                inputRef={inputRef}
                label={displayNameField.label}
                name={displayNameField.name}
                rows={multiRow}
              />
              <FormikTextarea
                fieldsToSync={editMode ? [] : ['description']}
                id={displayDescriptionField.name}
                label={displayDescriptionField.label}
                name={displayDescriptionField.name}
                rows={4}
              />
              <FormikSearchableSelect
                disabled={editMode}
                id={typeField.name}
                label={typeField.label}
                limitTags={20}
                name={typeField.name}
                options={typeOptions}
                placeholder={t('Select type')}
              />
              {CustomForm && (
                <Box mt={1}>
                  <CustomForm values={values} />
                </Box>
              )}
              {values?.type?.id === ValueTypeName.Selection &&
                (orgQualityControl || projQualityControl) && (
                  <FormikRadioGroup
                    label={displayType.displayName}
                    name={displayType.name}
                    options={displayType.options}
                  />
                )}
              {isReusable && (
                <>
                  <FormikInput label={nameField.label} name={nameField.name} />
                  <FormikTextarea
                    id={descriptionField.name}
                    label={descriptionField.label}
                    name={descriptionField.name}
                    rows={4}
                  />
                </>
              )}
            </FormWrapper>

            <FormFooter
              ariaLabelSave="Save field"
              disableSubmit={isSubmitDisabled({
                dirty,
                errors,
                isSubmitting,
              })}
              onClose={onClose}
              pb={3}
              pt={2}
              px={3}
              sticky
            />
            {publishTemplatesModal}
          </FormikForm>
        );
      }}
    </Formik>
  );
};
