import { RouteParams, Routes } from '@pro4all/shared/config';

export function getFloorPlanRoute(params: RouteParams) {
  let floorPlanRoute: keyof typeof Routes = 'projectQualityControlFloorPlan';
  if (params.objectId) {
    if (params.projectId) {
      floorPlanRoute = 'projectObjectFloorPlan';
    } else {
      floorPlanRoute = 'objectFloorPlan';
    }
  }

  return floorPlanRoute;
}

export function getQCSResultRoute(params: RouteParams) {
  let floorPlanRoute: keyof typeof Routes = 'projectQualityControlResults';
  if (params.objectId) {
    if (params.projectId) {
      floorPlanRoute = 'projectObjectResults';
    } else {
      floorPlanRoute = 'objectResults';
    }
  }

  return floorPlanRoute;
}
