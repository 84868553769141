import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, FormControlLabel } from '@pro4all/shared/mui-wrappers';
import { ReportConfigTypes } from '@pro4all/shared/types';
import { Checkbox } from '@pro4all/shared/ui/general';

import { useReportOptionsContext } from '../../ReportOptionsContext';

export const ConfigureShowLinkedSnags = ({
  elementIdentifier,
  template,
}: Pick<ReportConfigTypes, 'elementIdentifier' | 'template'>) => {
  const { t } = useTranslation();
  const { reportOptions, setTemplateOptions } = useReportOptionsContext();

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              template
                ? reportOptions?.templates?.[template.id]?.showLinkedSnags
                : false
            }
            onChange={() => {
              setTemplateOptions(template.id, {
                ...reportOptions?.templates?.[template.id],
                showLinkedSnags:
                  !reportOptions?.templates?.[template.id]?.showLinkedSnags,
              });
            }}
          />
        }
        id={elementIdentifier}
        label={t('Show linked snags')}
      />
    </Box>
  );
};
