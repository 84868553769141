import type { ObjectNode, ObjectQueryResult } from '@pro4all/graphql';
import { useObjectQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';

export interface UseObjectDetailResult extends Omit<ObjectQueryResult, 'data'> {
  id: ObjectNode['id'] | undefined;
  object: ObjectNode | undefined;
}

export function useObjectDetail(): UseObjectDetailResult {
  const {
    params: { objectId: id },
  } = useRouting();

  const { data, ...queryResult } = useObjectQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000 * ApiConfig.pollEnabled,
    skip: !id,
    variables: { id },
  });

  const object = data?.object ?? undefined;

  return { ...queryResult, id, object };
}
