import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { FormGroup, Typography } from '@pro4all/shared/mui-wrappers';
import { FormikCheckbox } from '@pro4all/shared/ui/general';

import { useReportOptionsContext } from '../../ReportOptionsContext';
import { BackgroundField } from '../general-custom-options/BackgroundField';

const StyledBox = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
`;

export const FrontpageCustomOptions = () => {
  const { t } = useTranslation();

  const context = useReportOptionsContext();
  if (!context) throw new Error('ReportOptionsContext not available');

  const { reportOptions } = context;

  const { enableFrontPage } = reportOptions || {};

  return (
    <FormGroup>
      <StyledBox>
        <FormikCheckbox label={t('Enable front page')} name="enableFrontPage" />
      </StyledBox>
      {enableFrontPage ? (
        <>
          <StyledBox>
            <BackgroundField isFrontPageProp />
          </StyledBox>

          <StyledBox>
            <Typography variant="h6">{t('Field visibility')}</Typography>
            <FormikCheckbox label={t('Title')} name="enableTitle" />
            <FormikCheckbox
              label={t('Project name')}
              name="enableProjectName"
            />
            <FormikCheckbox
              label={t('Project number')}
              name="enableProjectNumber"
            />
            <FormikCheckbox label={t('Start date')} name="enableStartDate" />
            <FormikCheckbox label={t('End date')} name="enableEndDate" />
            <FormikCheckbox
              label={t('Project description')}
              name="enableProjectDescription"
            />
            <FormikCheckbox label={t('Created on')} name="enableCreatedOn" />
            <FormikCheckbox label={t('Created by')} name="enableCreatedBy" />
            <FormikCheckbox
              label={t('Generated with')}
              name="enableGeneratedWith"
            />
          </StyledBox>
        </>
      ) : null}
    </FormGroup>
  );
};
