import { useTranslation } from 'react-i18next';

import { getToken } from '@pro4all/authentication/src/utils';
import {
  useCreateCommandMutation,
  useDocumentVersionQuery,
} from '@pro4all/graphql';
import { useClientRedirectContext } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { ActionProps } from '@pro4all/shared/types';

import { DocumentActionProps } from '../../types';

export const useEditFileClientAction = ({
  editableDocuments,
  isLocked,
  isSpecialFolder,
  isVersion,
  position,
  selection,
}: Pick<
  DocumentActionProps,
  | 'editableDocuments'
  | 'isLocked'
  | 'isSpecialFolder'
  | 'isVersion'
  | 'position'
  | 'selection'
  | 'uncheckAllRows'
>) => {
  const { t } = useTranslation();
  const hasFeatureFlagEditPublish = useFeatureFlag('edit-publish');
  const document = selection[0];
  //Check client application installed
  const [createCommand] = useCreateCommandMutation();

  const {
    setClientUrl,
    setPosition,
    setDocument,
    setIsDialogOpen,
    setIsPublishAction,
    setCommandId,
  } = useClientRedirectContext();

  const { data } = useDocumentVersionQuery({
    fetchPolicy: 'network-only',
    skip: !document,
    variables: { id: document?.id },
  });

  const openWarningModal = async () => {
    // Convert it to an array of DocumentVersion
    const versions = data?.documentVersion ?? [];

    // No versions? No command!
    if (versions.length === 0) return;

    // Make sure the versions are sorted..
    versions.sort(
      (v1, v2) => (v1?.versionNumber ?? 0) - (v2?.versionNumber ?? 0)
    );

    // Safely access the last element
    const lastVersion = versions[versions.length - 1];
    const versionId = lastVersion?.id ?? '';

    if (versionId === '') return;

    const command = await createCommand({
      variables: {
        command: {
          jobs: [
            {
              Type: 'Checkout', // Uppercase because of the API
              documentId: document.id,
              order: 0,
              versionId: versionId,
            },
          ],
          versionId: versionId,
        },
      },
    });

    if (command.data?.createCommand?.commandId) {
      const token = getToken();
      const url = `prostream://${command.data.createCommand.commandId}?token=${token}&documentId=${document.id}`;
      setCommandId(command.data.createCommand.commandId);
      setClientUrl(url);
      setDocument(document);
      setPosition(position);
      setIsPublishAction(false);
      setIsDialogOpen(true);
    }
  };

  const editFileAction: ActionProps = {
    ariaLabel: t('Edit document on my computer'),
    dataTestId: 'edit-file',
    disabled:
      !hasFeatureFlagEditPublish ||
      editableDocuments.length === 0 ||
      isLocked ||
      isSpecialFolder ||
      isVersion ||
      selection.length > 1,
    key: 'edit-file',
    label: t('Edit document on my computer'),
    onClick: openWarningModal,
    startIcon: 'editDocumentOnComputer',
  };

  return editFileAction;
};
