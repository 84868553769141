import React, { useEffect, useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import { LatLng, LatLngBounds } from 'leaflet';

import { TemplateType } from '@pro4all/graphql';
import { useQualityControlContext } from '@pro4all/quality-control/context';
import { useRouting } from '@pro4all/shared/routing-utils';

import { AuthTileLayer } from './AuthTileLayer';
import { ClusterGroup } from './ClusterGroup';
import { MapContextMenu } from './MapContextMenu';
import { useMapLinkingContext } from './MapLinkingContext';
import { SelectSnagModal } from './SelectSnagModal';
import { ContentsProps } from './types';

const isPointInBounds = (point: LatLng, bounds: LatLngBounds) =>
  bounds.contains(point);

export const MapContents: React.FC<ContentsProps> = ({
  children,
  cluster = true,
  lat,
  lng,
  mapHeight,
  mapWidth,
  visualContextId,
  zoomLevels = 5,
}) => {
  const {
    isContextMenuOpen,
    isSelectTemplateDialogOpen,
    centering,
    setCentering,
    setCoordinates,
    setIsContextMenuOpen,
    setIsSelectTemplateDialogOpen,
    setSelectedTemplateType,
  } = useMapLinkingContext();

  const { searchParams } = useRouting();
  const page = Number(searchParams.get('page'));
  const placeSnag = searchParams.get('placeSnag');

  const [localZoom, setLocalZoom] = useState<number>(1);

  const { setFloorplanBounds, floorplanBounds } = useQualityControlContext();

  const map = useMapEvents({
    click: ({ latlng: { lat: y, lng: x } }) => {
      if (
        floorplanBounds &&
        isPointInBounds(new LatLng(y, x), floorplanBounds)
      ) {
        if (placeSnag === 'false' || !placeSnag) return;
        setCoordinates({ x, y });
        setSelectedTemplateType(TemplateType.Snag);
        setIsSelectTemplateDialogOpen(true);
      }
    },
    contextmenu: ({ latlng: { lat: y, lng: x } }) => {
      if (
        floorplanBounds &&
        isPointInBounds(new LatLng(y, x), floorplanBounds)
      ) {
        if (placeSnag === 'true') return;
        setIsContextMenuOpen(true);
        setCoordinates({ x, y });
      }
    },
  });

  // Init: set bounds and center marker
  useEffect(() => {
    const southWest = map.unproject([0, mapHeight], zoomLevels);
    const northEast = map.unproject([mapWidth, 0], zoomLevels);
    const bounds: LatLngBounds = new LatLngBounds(southWest, northEast);

    setFloorplanBounds(bounds);

    if (map) {
      map.fitBounds(bounds);
    }
  }, [map, mapWidth, mapHeight, zoomLevels, setFloorplanBounds]);

  useEffect(() => {
    if (map) {
      const zoomLevel = map.getZoom();
      if (!isContextMenuOpen || centering) {
        if (lat !== 0 || lng !== 0) {
          if (zoomLevel !== localZoom) {
            map.setView([lat, lng]);
            setLocalZoom(zoomLevel);
          } else {
            map.setView([lat, lng]);
          }
          setCentering(false);
        }
      }
    }
  }, [map, lat, lng, localZoom, isContextMenuOpen, centering, setCentering]);

  return (
    <>
      <AuthTileLayer
        maxZoom={zoomLevels}
        minZoom={1}
        noWrap
        page={page}
        tileSize={256}
        visualContextId={visualContextId}
      />

      {cluster ? <ClusterGroup>{children}</ClusterGroup> : children}
      {(placeSnag === 'false' || !placeSnag) && isContextMenuOpen && (
        <MapContextMenu />
      )}
      {isSelectTemplateDialogOpen && (
        <SelectSnagModal visualContextId={visualContextId} />
      )}
    </>
  );
};
