import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { Collapse, ListItemText } from '@pro4all/shared/mui-wrappers';
import { ReportConfigTypes } from '@pro4all/shared/types';
import { Text } from '@pro4all/shared/ui/typography';

import { useReportOptionsContext } from '../../ReportOptionsContext';
import { StyledListItemButton } from '../FormStyles';
import { flattenItems } from '../helpers/flattenItems';

import { SectionFieldInclude } from './SectionFieldInclude';

export const ConfigureSectionsAndFields = ({
  previewInstances,
  template,
}: Pick<ReportConfigTypes, 'previewInstances' | 'template'>) => {
  const { t } = useTranslation();

  const { reportOptions, setHiddenSectionsAndFields } =
    useReportOptionsContext();
  const [open, setOpen] = useState(true);

  if (!template) return null;

  const items = previewInstances.find(
    (instance) => instance.templateId === template.id
  )?.items;

  if (!items) return null;

  const flattenedItems = flattenItems({ items, level: 0 });

  const hiddenSectionsAndFields = template
    ? reportOptions?.templates?.[template.id]
      ? reportOptions?.templates?.[template.id]?.hiddenSectionsAndFields
      : []
    : [];

  const sectionsAndFields = flattenedItems.map((item) => {
    const checked = hiddenSectionsAndFields
      ? !hiddenSectionsAndFields?.includes(item.id)
      : true;

    return (
      <SectionFieldInclude
        checked={checked}
        flattenedItem={item}
        items={items}
        setHiddenSectionsAndFields={setHiddenSectionsAndFields}
        template={template}
      />
    );
  });

  return (
    <>
      <StyledListItemButton onClick={() => setOpen(!open)}>
        <ListItemText primary={<Text variant="h6">{t('Answers')}</Text>} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </StyledListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {sectionsAndFields}
      </Collapse>
    </>
  );
};
