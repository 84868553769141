import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppPermissions } from '@pro4all/shared/identity';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';

import { useMetaDataContext } from '../../views/MetaDataContext';
import { featureConfig } from '../configs/featureConfig';
import { useDeleteFieldDefinitions } from '../hooks/useDeleteFieldDefinitions';
import { usePublishTemplatesModal } from '../modals/usePublishTemplatesModal';

export const ItemsActionBar = () => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const { hasAppPermission } = useAppPermissions();
  const userCannotUpdateOrg = !hasAppPermission('OrganizationUpdate');
  const { templateType, templateService } = useMetaDataContext();
  const deleteFieldDefinitions = useDeleteFieldDefinitions();
  const { entity, entityId, entityIcon } =
    featureConfig[templateService].fieldTypes[templateType];

  const { publishTemplatesModal, setShowModal, setUpdateCallback } =
    usePublishTemplatesModal({
      updateAndPublishLabel: 'Delete and publish', // i18n
      updateLabel: 'Delete', // i18n
    });

  const mainActions: ActionProps[] = [
    {
      ariaLabel: t(`New ${entity}`),
      dataTestId: `new-${entityId}`,
      disabled: userCannotUpdateOrg,
      key: `new-${entityId}`,
      label: t(`New ${entity}`),
      onClick: () => {
        searchParams.delete('id');
        searchParams.set('action', 'createField');
      },
      startIcon: entityIcon,
    },
  ];

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Delete'),
      dataTestId: `delete-${entityId}`,
      disabled: userCannotUpdateOrg,
      key: `delete-${entityId}`,
      label: t('Delete'),
      onClick: () => {
        setShowModal(true);
        setUpdateCallback(() => () => {
          deleteFieldDefinitions();
        });
      },
      startIcon: 'delete',
    },
  ];

  return (
    <>
      <TableActionBar
        altActions={altActions}
        dataTestid={`${entityId}s-action-bar`}
        mainActions={mainActions}
        search
      />
      {publishTemplatesModal}
    </>
  );
};
