import {
  Option,
  PayloadHiddenSectionsAndFields,
  ReportOptions,
} from '@pro4all/shared/types';

export enum ActionType {
  DISABLE_CUSTOM_MODE,
  ENABLE_CUSTOM_MODE,
  SELECT_TEMPLATE,
  SET_BACKGROUND_FRONT_PAGE,
  SET_BACKGROUND,
  SET_HIDDEN_SECTIONS_AND_FIELDS,
  SET_LOGO,
  SET_REPORT_OPTIONS,
}

type State = {
  bgId: string;
  bgIdFrontPage: string;
  customMode: boolean;
  logoId: string;
  reportOptions: ReportOptions;
  styleTemplateId: string;
  styleTemplateOptions: Option[];
};

type ReducerAction = {
  payloadHiddenSectionsAndFields?: PayloadHiddenSectionsAndFields;
  type: ActionType;
  value: State;
};

export const reportOptionsReducer = (state: State, action: ReducerAction) => {
  const {
    bgId,
    bgIdFrontPage,
    customMode,
    logoId,
    reportOptions,
    styleTemplateId,
    styleTemplateOptions,
  } = action.value;

  switch (action.type) {
    case ActionType.DISABLE_CUSTOM_MODE: {
      return {
        ...state,
        customMode,
        reportOptions,
        styleTemplateId,
        styleTemplateOptions,
      };
    }
    case ActionType.ENABLE_CUSTOM_MODE: {
      return {
        ...state,
        customMode,
        styleTemplateId,
        styleTemplateOptions,
      };
    }
    case ActionType.SET_BACKGROUND: {
      return {
        ...state,
        bgId,
      };
    }
    case ActionType.SET_BACKGROUND_FRONT_PAGE: {
      return {
        ...state,
        bgIdFrontPage,
      };
    }
    case ActionType.SET_LOGO: {
      return {
        ...state,
        logoId,
      };
    }
    case ActionType.SET_REPORT_OPTIONS: {
      return {
        ...state,
        reportOptions,
      };
    }
    case ActionType.SET_HIDDEN_SECTIONS_AND_FIELDS: {
      // The reducer should be responsible for managing the state logic and determining how the state changes in response to actions.
      // Initially I used the same pattern as used for the other actions, but I noticed issues with that in the memoization of `SectionFieldInclude`.
      // So user disables checkbox for section 1, then disables checkbox for section 2 then automatically section one is enabled again.
      // In other words: unreliable state management if you take the state in `ReportOptionsContext` as the source of truth.
      // The only source of truth for the state lies in this reducer.
      // TODO: I will refactor this for all other actions in a follow up PR.
      const { add, ids, templateId } =
        action.payloadHiddenSectionsAndFields || {};

      if (!ids || !templateId) return state;

      const updatedTemplate = state.reportOptions.templates
        ? state.reportOptions.templates[templateId]
        : null;

      if (!updatedTemplate) return state;

      const updatedHiddenSectionsAndFields = add
        ? [...updatedTemplate.hiddenSectionsAndFields, ...ids]
        : updatedTemplate.hiddenSectionsAndFields.filter(
            (id) => !ids.includes(id)
          );

      return {
        ...state,
        reportOptions: {
          ...state.reportOptions,
          templates: {
            ...state.reportOptions.templates,
            ...{
              [templateId]: {
                ...updatedTemplate,
                hiddenSectionsAndFields: updatedHiddenSectionsAndFields,
              },
            },
          },
        },
      };
    }
    case ActionType.SELECT_TEMPLATE: {
      return {
        ...state,
        styleTemplateId,
      };
    }
    default:
      return state;
  }
};
