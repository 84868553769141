import React from 'react';
import { useTranslation } from 'react-i18next';

import { ImageColumns, Option, ReportConfigTypes } from '@pro4all/shared/types';
import { Select } from '@pro4all/shared/ui/general';

import { useReportOptionsContext } from '../../ReportOptionsContext';

export const ConfigureImageColumns = ({
  template,
}: Pick<ReportConfigTypes, 'template'>) => {
  const { t } = useTranslation();
  const { reportOptions, setTemplateOptions } = useReportOptionsContext();

  const handleSelect = (value: Option) => {
    template?.id &&
      setTemplateOptions(template.id, {
        ...reportOptions?.templates?.[template.id],
        imageColumn: value.id as keyof typeof ImageColumns,
      });
  };

  const order = ['xs', 'sm', 'md', 'lg', 'xl'];

  const columnOptions: Option[] = Object.keys(ImageColumns)
    .map((key) => ({
      id: key,
      label: t(ImageColumns[key as keyof typeof ImageColumns]),
    }))
    .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));

  const selectedSizeOption =
    reportOptions.templates && template?.id
      ? reportOptions.templates[template.id]?.imageColumn ||
        reportOptions.templates['default'].imageColumn
      : undefined;

  return (
    <Select
      label={t('Image columns')}
      name="imageSizeSelect"
      onChange={handleSelect}
      options={columnOptions}
      placeholder={t('Select a column number')}
      value={selectedSizeOption}
    />
  );
};
