import { useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';

import {
  NotificationScope,
  Task,
  TaskType,
  VisualContext,
} from '@pro4all/graphql';
import { NotificationsPage } from '@pro4all/notifications';
import { useFetchTasks } from '@pro4all/quality-control/data-access';
import {
  FloorPlan,
  FloorPlansMain,
} from '@pro4all/quality-control/ui/floor-plan';
import { MapLinkingContextProvider } from '@pro4all/quality-control/ui/maps';
import { ReportingMain } from '@pro4all/quality-control/ui/report-options';
import { ResultsMain } from '@pro4all/quality-control/ui/results';
import { SavedAnswersTable } from '@pro4all/quality-control/ui/saved-answers';
import { TasksActionBar } from '@pro4all/quality-control/ui/tasks';
import { Routes } from '@pro4all/shared/config';
import { generateRoute, useRouting } from '@pro4all/shared/routing-utils';
import { SnagFromSearchContextProvider } from '@pro4all/shared/snags-and-forms-search-context';
import {
  DataViewContextProvider,
  DataViewType,
} from '@pro4all/shared/ui/data-view';
import {
  Column,
  Main,
  OptimisticResponseProvider,
} from '@pro4all/shared/ui/general';
import { TasksMain, TaskTablePostFix } from '@pro4all/shared/ui/tasks';

import { Tabs } from './Tabs';

export const QualityControlResults = () => {
  const { params, searchParams } = useRouting();
  const projectId = params.projectId;

  const typeFilter = [TaskType.Resolve, TaskType.QualityControl];
  const { loading, data } = useFetchTasks(typeFilter);

  const tasks = useMemo(
    () => data?.project?.mainProcedure?.tasks || [],
    [data?.project?.mainProcedure?.tasks]
  ) as Task[];

  if (searchParams.get('action') === 'createReport')
    return (
      <MapLinkingContextProvider>
        <ReportingMain />
      </MapLinkingContextProvider>
    );

  return (
    <MapLinkingContextProvider>
      <Route exact path={Routes.projectQualityControlMain}>
        <Redirect
          to={generateRoute('projectQualityControlResults', {
            params: { projectId },
          })}
        />
      </Route>
      <Route
        exact
        path={[
          Routes.projectQualityControlFloorPlan,
          Routes.objectFloorPlan,
          Routes.projectObjectFloorPlan,
        ]}
      >
        <FloorPlan />
      </Route>
      <Route
        exact
        path={[
          Routes.objectFloorPlan,
          Routes.projectObjectFloorPlan,
          Routes.projectQualityControlFloorPlans,
          Routes.projectQualityControlMySavedAnswers,
          Routes.projectQualityControlResults,
          Routes.projectQualityControlTasks,
          Routes.projectQualityControlNotifications,
        ]}
      >
        <Column>
          <Tabs />
          <Route path={Routes.projectQualityControlResults}>
            <DataViewContextProvider
              initialView={DataViewType.Table}
              viewKey="quality-control-results"
              views={[DataViewType.Table, DataViewType.Charts]}
            >
              <SnagFromSearchContextProvider>
                <ResultsMain projectId={projectId} />
              </SnagFromSearchContextProvider>
            </DataViewContextProvider>
          </Route>
          <Route exact path={Routes.projectQualityControlFloorPlans}>
            <OptimisticResponseProvider<VisualContext>>
              <Main sx={{ overflow: 'hidden' }}>
                <Column>
                  <FloorPlansMain />
                </Column>
              </Main>
            </OptimisticResponseProvider>
          </Route>
          <Route path={Routes.projectQualityControlTasks}>
            <OptimisticResponseProvider<Task>>
              <DataViewContextProvider
                initialView={DataViewType.Table}
                viewKey="quality-control-tasks"
                views={[DataViewType.Table, DataViewType.Charts]}
              >
                <TasksMain
                  TasksActionBar={<TasksActionBar />}
                  hideProject
                  hideUser={false}
                  loading={loading}
                  mainProcedureId={data?.project?.mainProcedure?.id}
                  noTasksText="Have they been completed? On a roll! Need new ones? Create them so you can guarantee overall quality."
                  noTasksTitle="You don't have any quality control tasks yet"
                  postfix={TaskTablePostFix.QC}
                  tasks={tasks}
                  typeFilter={typeFilter}
                />
              </DataViewContextProvider>
            </OptimisticResponseProvider>
          </Route>
          <Route path={Routes.projectQualityControlMySavedAnswers}>
            <SavedAnswersTable />
          </Route>
          <Route path={Routes.projectQualityControlNotifications}>
            <OptimisticResponseProvider<VisualContext>>
              <Main>
                <Column>
                  <NotificationsPage scope={NotificationScope.Qc} />
                </Column>
              </Main>
            </OptimisticResponseProvider>
          </Route>
        </Column>
      </Route>
    </MapLinkingContextProvider>
  );
};
