import React, { useState } from 'react';

import { Option, ReportConfigTypes } from '@pro4all/shared/types';

import { ConfigureImageColumns } from './ConfigureImageColumns';
import { ConfigureSectionsAndFields } from './ConfigureSectionsAndFields';
import { ConfigureSelectTemplate } from './ConfigureSelectTemplate';
import { ConfigureShowDescription } from './ConfigureShowDescription';
import { ConfigureShowLinkedSnags } from './ConfigureShowLinkedSnags';

export const InstanceCustomOptionsForm = ({
  previewInstances,
  templateOptions,
}: Pick<ReportConfigTypes, 'previewInstances' | 'templateOptions'>) => {
  const [template, setTemplate] = useState<Option | null>(null);

  return (
    <>
      <ConfigureSelectTemplate
        elementIdentifier="form-template-select"
        setTemplate={setTemplate}
        template={template}
        templateOptions={templateOptions}
      />

      {template && templateOptions.length > 0 && (
        <>
          <ConfigureImageColumns template={template} />
          <ConfigureShowDescription
            elementIdentifier="form-show-description"
            template={template}
          />
          <ConfigureShowLinkedSnags
            elementIdentifier="form-show-linked-snags"
            template={template}
          />
          <ConfigureSectionsAndFields
            previewInstances={previewInstances}
            template={template}
          />
        </>
      )}
    </>
  );
};
