import { useTranslation } from 'react-i18next';

import { ResolveTaskResourcesProps } from '@pro4all/shared/types';

import { Instance } from '../elements';

const ResolveTaskResources = ({ instance }: ResolveTaskResourcesProps) => {
  const { t } = useTranslation();
  return (
    <Instance.Item>
      <Instance.Label>{t('Snags')}</Instance.Label>
      <Instance.Value>
        {instance.linkedSnagInstances
          ?.map((template) => template.name)
          .join(', ')}
      </Instance.Value>
    </Instance.Item>
  );
};

export default ResolveTaskResources;
