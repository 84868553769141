import {
  FacetGroup,
  FilterType,
  SearchDocument,
  SearchQcInstance,
  ValueTypeName,
} from '@pro4all/graphql';
import { filterKeys } from '@pro4all/shared/config';
import { toFilterType } from '@pro4all/shared/utils';

import { FilterBaseProps } from './shared-search-types';

export const delimiters = {
  // ","
  filters: ',',
  // ":"
  keyValue: ':',
  // "_"
  metaDataSubKey: '_',
  // "+"
  multiSelectOptions: '+',
  // "."
  subTypeKey: '.',
};

export const RELOAD_FLAG = '.reload';
export const HIDDEN_FLAG = '.hidden';
export const AUTO_OPEN_FLAG = '.open';
export const FILE_TYPE_PREFIX = '.';

export const mdFilterQCTypes = [
  FilterType.Bool,
  FilterType.DateTime,
  FilterType.Number,
  FilterType.Status,
  FilterType.Selection,
  FilterType.Text,
  FilterType.UserSelection,
];

export const stateFilterTypes = [
  FilterType.State,
  FilterType.Versions,
  FilterType.VersionState,
];

export const staticFilterTypes: FilterType[] = [
  FilterType.UpdatedBy,
  FilterType.UpdatedAt,
];

export type OnSearchArgs = {
  contextQueryParam?: string[];
  disableTracking?: boolean;
  query: string;
  savedFilters?: string;
};

export const staticParamFilters = staticFilterTypes.map(
  (type) => `${filterKeys[type]}:`
);

export const removeDotFromExtension = (
  extension: string | undefined | null
) => {
  if (!extension) return '';
  return extension.charAt(0) === FILE_TYPE_PREFIX
    ? extension.substring(1)
    : extension;
};
export const parseFilterParam = (filterParam: string): [string, string] => {
  const [key, ...valueParts] = filterParam.split(delimiters.keyValue);
  const value = valueParts.join(delimiters.keyValue);
  return [key, value];
};

export const parseFilterString = (queryString: string): FilterBaseProps[] => {
  const result: FilterBaseProps[] = [];
  const paramFilterArray = queryString.split(delimiters.filters);

  paramFilterArray.forEach((paramFilter) => {
    const [key, value] = parseFilterParam(paramFilter);
    const hasSubType = key.includes(delimiters.subTypeKey);
    const keyParts = hasSubType ? key.split(delimiters.subTypeKey) : [key];
    const isMetaData = isMetaDataType(keyParts[0]);
    const metaDataKey =
      isMetaData && keyParts.length > 1 ? keyParts[1] : undefined;
    const typeKey = keyParts[0];

    // for metadata the format is UUID_fieldName
    const name =
      metaDataKey &&
      metaDataKey
        .split(delimiters.metaDataSubKey)
        .filter((_, key) => key !== 0) // removes the first part of key (the UUID)
        .join(delimiters.metaDataSubKey);
    const hidden = paramFilter.includes(HIDDEN_FLAG);
    const open = paramFilter.includes(AUTO_OPEN_FLAG);
    if (typeKey) {
      const type = toFilterType(typeKey);
      result.push({
        hidden,
        metaDataKey,
        name,
        open,
        type,
        value,
      });
    }
  });

  return result.filter((filter) => Boolean(filter.type));
};

export const toFilterArray = (filterString: string | null) => {
  if (!filterString) return [];
  let filterArray: string[] = [];
  filterString?.split(delimiters.filters)?.forEach((paramFilter) => {
    if (!paramFilter) return;
    const [key, value] = parseFilterParam(paramFilter);
    const entries = value?.split(delimiters.multiSelectOptions);
    const formattedEntries = entries?.map((entry) =>
      value ? `${key}${delimiters.keyValue}${entry}` : ''
    );
    if (!formattedEntries) return;
    filterArray = filterArray.concat(formattedEntries.filter(Boolean));
  });
  return filterArray;
};

export const computeFacetGroups = (
  documents: (SearchDocument | SearchQcInstance)[],
  facetGroups: FacetGroup[]
): FacetGroup[] =>
  facetGroups.map(({ items, type }) => {
    switch (type) {
      case FilterType.Extension: {
        const matchingFacets = items?.filter((facetItem) =>
          documents.find(
            (document) =>
              removeDotFromExtension(document.extension) ===
              removeDotFromExtension(facetItem?.value)
          )
        );
        return { items: matchingFacets, type };
      }
      default: {
        return { items, type };
      }
    }
  });

export const isMetaDataType = (type: string) =>
  type &&
  Object.keys(ValueTypeName)
    .map((valueTypeName) => valueTypeName.toLowerCase())
    .includes(type.toLowerCase());
