import React from 'react';

import { Task } from '@pro4all/graphql';
import { ComposedPin, TMuiIcon } from '@pro4all/shared/composed-snag-form-pin';
import { LinkedInstance } from '@pro4all/workflow/ui/utils';

// TODO: Eventually add logic for custom images
export const FormIconComponent: React.FC<{
  currentTask: Task;
  getInstanceFormColor: (
    currentTask: Task,
    instance: LinkedInstance
  ) => string | undefined;
  instance: LinkedInstance;
}> = ({ currentTask, instance, getInstanceFormColor }) => (
  <ComposedPin
    customColor={getInstanceFormColor(currentTask, instance)}
    selectedIconName={(instance?.icon?.name as TMuiIcon) ?? undefined}
    simpleIcon
    type="form"
  />
);

export const SnagIconComponent: React.FC<{
  currentTask: Task;
  getInstanceSnagColor: (
    currentTask: Task,
    instance: LinkedInstance
  ) => string | undefined;
  instance: LinkedInstance;
}> = ({ currentTask, instance, getInstanceSnagColor }) => (
  <ComposedPin
    customColor={getInstanceSnagColor(currentTask, instance)}
    selectedIconName={(instance?.icon?.name as TMuiIcon) ?? undefined}
    simpleIcon
    type="snag"
  />
);
