import { useTranslation } from 'react-i18next';
import AddBoxIcon from '@mui/icons-material/AddBox';
import MinBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

import { useCollapseButtons } from '@pro4all/shared/contexts';
import { Button } from '@pro4all/shared/mui-wrappers';

export const CollapseExpandButtons = ({ maxLevel }: { maxLevel: number }) => {
  const { collapseLevel, setCollapseLevel } = useCollapseButtons();
  const { t } = useTranslation();

  return (
    <>
      <Button
        onClick={() => {
          setCollapseLevel?.({
            initial: false,
            level: Math.min(collapseLevel.level + 1, maxLevel),
          });
        }}
      >
        <AddBoxIcon />
        {t('Expand one level')}
      </Button>
      <Button
        onClick={() => {
          setCollapseLevel?.({
            initial: false,
            level: Math.max(collapseLevel.level - 1, 0),
          });
        }}
      >
        <MinBoxIcon />
        {t('Collapse one level')}
      </Button>
    </>
  );
};
