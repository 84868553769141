import React, {
  createContext,
  useContext,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import {
  DEFAULT_BACKGROUND_URL,
  DEFAULT_LOGO_URL,
} from '@pro4all/quality-control/ui/report';
import {
  MapElements,
  Option,
  PayloadHiddenSectionsAndFields,
  ReportOptions,
  ReportOptionsContextValue,
  TemplateOptions,
} from '@pro4all/shared/types';
import { resolveNameDuplicates } from '@pro4all/shared/utils';

import {
  ActionType,
  reportOptionsReducer,
} from './utils/useReportOptionsReducer';

const ReportOptionsContext = createContext<
  ReportOptionsContextValue | undefined
>(undefined);

const defaultStyleTemplateOption = {
  id: 'default',
  label: 'Prostream default',
};

export const useReportOptionsContext = (): ReportOptionsContextValue => {
  const context = useContext(ReportOptionsContext);
  if (!context) throw new Error('ReportOptionsContext not available');
  return context;
};

export const defaultTemplate: TemplateOptions = {
  hiddenSectionsAndFields: [],
  imageColumn: 'bTwo',
  showDescription: true,
  showLinkedSnags: true,
};

export const ReportOptionsContextProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [miniMaps, setMiniMaps] = useState<MapElements | undefined>(undefined);
  const [reportFloorplans, setReportFloorplans] = useState<
    MapElements | undefined
  >(undefined);

  // @Todo: fetch options
  const fetchedStyleTemplateOptions: Option[] = [];

  const initialReportOptions: ReportOptions = {
    background: DEFAULT_BACKGROUND_URL,
    backgroundFrontPage: DEFAULT_BACKGROUND_URL,
    compactLists: true,
    companyLogo: DEFAULT_LOGO_URL,
    enableCreatedBy: true,
    enableCreatedOn: true,
    enableEndDate: true,
    enableFrontPage: true,
    enableGeneratedWith: true,
    enableProjectDescription: true,
    enableProjectName: true,
    enableProjectNumber: true,
    enableStartDate: true,
    enableTitle: true,
    footerText: t('Generated with Prostream'),
    inlineLists: true,
    showEmptyAnswers: true,
    showPageNumbers: true,
    templates: { default: defaultTemplate },
  };

  const initialStyleTemplateOptions: Option[] = [
    defaultStyleTemplateOption,
    ...fetchedStyleTemplateOptions,
  ];

  const originalStyleTemplateId = useRef<string | null>(null);

  const [state, dispatch] = useReducer(reportOptionsReducer, {
    bgId: 'default',
    bgIdFrontPage: 'default',
    customMode: false,
    logoId: 'default',
    reportOptions: initialReportOptions,
    styleTemplateId: 'default',
    styleTemplateOptions: initialStyleTemplateOptions,
  });

  if (!state) return null; // State not initialized

  const {
    customMode,
    bgId,
    bgIdFrontPage,
    logoId,
    reportOptions,
    styleTemplateId,
    styleTemplateOptions,
  } = state;

  const enableCustomMode = () => {
    originalStyleTemplateId.current = styleTemplateId;
    const selectedOption = styleTemplateOptions.find(
      (option) => option.id === styleTemplateId
    );

    if (!selectedOption) {
      enqueueSnackbar(t('Something went wrong'));
      return;
    }

    const customLabel = resolveNameDuplicates(
      `${selectedOption.label} (custom)`,
      styleTemplateOptions.map((option) => option.label)
    );
    const customOption: Option = { id: 'custom', label: customLabel };

    dispatch({
      type: ActionType.ENABLE_CUSTOM_MODE,
      value: {
        ...state,
        customMode: true,
        styleTemplateOptions: [...styleTemplateOptions, customOption],
      },
    });

    selectStyleTemplate(customOption);
  };

  const disableCustomMode = () => {
    const filteredOptions = styleTemplateOptions.filter(
      (option) => option.id !== 'custom'
    );
    dispatch({
      type: ActionType.DISABLE_CUSTOM_MODE,
      value: {
        ...state,
        bgId: 'default',
        bgIdFrontPage: 'default',
        customMode: false,
        logoId: 'default',
        reportOptions: initialReportOptions,
        styleTemplateId: originalStyleTemplateId.current || 'default',
        styleTemplateOptions: filteredOptions,
      },
    });
  };

  const toggleCustomMode = (force?: boolean) => {
    // return early because forcing true is redundant
    if (force === customMode) return;
    // force true
    if (force) enableCustomMode();
    // force arg not provided, so toggle
    else customMode ? disableCustomMode() : enableCustomMode();
  };

  const selectStyleTemplate = (option: Option) => {
    if (!option) return;
    dispatch({
      type: ActionType.SELECT_TEMPLATE,
      value: {
        ...state,
        styleTemplateId: option.id,
      },
    });
  };

  const setBackground = (id: string) => {
    dispatch({
      type: ActionType.SET_BACKGROUND,
      value: {
        ...state,
        bgId: id,
      },
    });
  };

  const setBackgroundFrontPage = (id: string) => {
    dispatch({
      type: ActionType.SET_BACKGROUND_FRONT_PAGE,
      value: {
        ...state,
        bgIdFrontPage: id,
      },
    });
  };

  const setLogo = (id: string) => {
    dispatch({
      type: ActionType.SET_LOGO,
      value: {
        ...state,
        logoId: id,
      },
    });
  };

  const setHiddenSectionsAndFields = (
    payload: PayloadHiddenSectionsAndFields
  ) => {
    dispatch({
      payloadHiddenSectionsAndFields: payload,
      type: ActionType.SET_HIDDEN_SECTIONS_AND_FIELDS,
      value: state,
    });
  };

  const setReportOptions = (reportOptions: ReportOptions) => {
    dispatch({
      type: ActionType.SET_REPORT_OPTIONS,
      value: {
        ...state,
        reportOptions,
      },
    });
  };

  const setTemplateOptions = (templateId: string, options: TemplateOptions) => {
    dispatch({
      type: ActionType.SET_REPORT_OPTIONS,
      value: {
        ...state,
        reportOptions: {
          ...reportOptions,
          templates: {
            ...state.reportOptions.templates,
            ...{ [templateId]: options },
          },
        },
      },
    });
  };

  const selectedStyleTemplate = styleTemplateOptions.find(
    (option) => option.id === styleTemplateId
  );

  const value = {
    bgId,
    bgIdFrontPage,
    customMode,
    logoId,
    miniMaps,
    reportFloorplans,
    reportOptions,
    selectStyleTemplate,
    selectedStyleTemplate,
    setBackground,
    setBackgroundFrontPage,
    setHiddenSectionsAndFields,
    setLogo,
    setMiniMaps,
    setReportFloorplans,
    setReportOptions,
    setTemplateOptions,
    styleTemplateOptions,
    toggleCustomMode,
  };

  return (
    <ReportOptionsContext.Provider value={value}>
      {children}
    </ReportOptionsContext.Provider>
  );
};
