import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { DocumentAction, Status, Task } from '@pro4all/graphql';
import { useTheme } from '@pro4all/shared/mui-wrappers';

import { TaskOption } from './types';

export const useDonutOptionsTasks = (tasks: Task[]) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const hasDocumentTasks =
    tasks.find((task) => task.type === 'Document') !== undefined;

  const keyOptions: TaskOption[] = [
    {
      id: 'status',
      label: t('Status'),
      labelColorResolver: (task) => {
        switch (task.status) {
          case Status.Done:
            return theme.palette.success.main;
          case Status.InProgress:
            return theme.palette.info.main;
          case Status.ToDo:
            return theme.palette.grey[500];
          case Status.Paused:
            return theme.palette.error.main;
          default:
            return theme.palette.grey[300];
        }
      },
      valueResolver: (task) => task.status || t('(empty)'),
    },
    {
      id: 'taskCategoryName',
      label: t('Category'),
      valueResolver: (task) => task.taskCategoryName || t('(empty)'),
    },
    {
      id: 'assignment',
      label: t('Responsible'),
      valueResolver: (task) =>
        task.assignment && task.assignment.length > 0
          ? task.assignment[0].displayName
          : t('(empty)'),
    },
    {
      id: 'createdBy',
      label: t('Created by'),
      valueResolver: (task) => task.createdByUser?.displayName || t('(empty)'),
    },
    {
      id: 'endTime',
      label: t('Due state'),
      labelColorResolver: (task) => {
        if (!task.endTime) return theme.palette.grey[300];

        const endTime = dayjs(task.endTime);
        const now = dayjs();

        if (task.status === 'Done') {
          return theme.palette.success.main;
        } else if (endTime.isBefore(now)) {
          return theme.palette.error.main;
        } else if (endTime.diff(now, 'day') <= 7) {
          return theme.palette.warning.main;
        } else {
          return theme.palette.success.light;
        }
      },
      valueResolver: (task) => {
        if (!task.endTime) return t('No deadline');
        const endTime = dayjs(task.endTime);
        const now = dayjs();

        if (task.status === 'Done') {
          return t('Done');
        } else if (endTime.isBefore(now)) {
          return t('Overdue');
        } else if (endTime.diff(now, 'day') <= 7) {
          return t('Due within a week');
        } else {
          return t('Due later');
        }
      },
    },
    {
      id: 'type',
      label: t('Type'),
      valueResolver: (task) => task.type || t('(empty)'),
    },
  ];

  if (hasDocumentTasks) {
    keyOptions.push({
      id: 'documentAction',
      label: t('Document action'),
      labelColorResolver: (task) => {
        switch (task.documentAction) {
          case DocumentAction.ForApproval:
            return theme.palette.secondary.main;
          case DocumentAction.ForReview:
            return theme.palette.success.main;
          case DocumentAction.ForInformation:
            return theme.palette.primary.main;
          case DocumentAction.InformationRequest:
            return theme.palette.primary.dark;
          case DocumentAction.ForQrStamp:
            return theme.palette.primary.light;
          case DocumentAction.ForSigning:
            return theme.palette.secondary.dark;
          case DocumentAction.OnRequest:
            return theme.palette.error.main;
          case DocumentAction.Other:
            return theme.palette.grey[500];
          case DocumentAction.ProcessComments:
            return theme.palette.warning.main;
          case DocumentAction.ToUpload:
            return theme.palette.error.main;
          default:
            return theme.palette.grey[300];
        }
      },
      valueResolver: (task) => {
        switch (task.documentAction) {
          case DocumentAction.ForApproval:
            return t('For approval');
          case DocumentAction.ForReview:
            return t('For review');
          case DocumentAction.ForInformation:
            return t('For information');
          case DocumentAction.InformationRequest:
            return t('Information request');
          case DocumentAction.OnRequest:
            return t('On request');
          case DocumentAction.ForQrStamp:
            return t('Stamp QR code');
          case DocumentAction.ForSigning:
            return t('For signature');
          case DocumentAction.Other:
            return t('Other');
          case DocumentAction.ProcessComments:
            return t('Process comments');
          case DocumentAction.ToUpload:
            return t('To upload');
          default:
            return t('Empty');
        }
      },
    });
  }

  return keyOptions;
};
