import { useTranslation } from 'react-i18next';

import {
  FilterHeaderType,
  PredefinedFilter,
} from '@pro4all/shared/ui/filtering';

export const usePredefinedFilters = () => {
  const { t } = useTranslation();

  const predefinedFiltes: PredefinedFilter[] = [
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['Active'],
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'state',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Active'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: [
            'Deactivated',
            'Invitation expired',
            'Invitation not sent',
            'Invitation sent',
          ],
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'state',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Not active'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['Administrator'],
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'roles',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Administrators'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['User'],
          isMultiSelect: false,
          itemsFiltered: [],
          propertyId: 'roles',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Users'),
    },
  ];

  return predefinedFiltes;
};
