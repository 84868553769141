import { useTranslation } from 'react-i18next';

import {
  FilterHeaderType,
  PredefinedFilter,
} from '@pro4all/shared/ui/filtering';

export const usePredefinedFilters = () => {
  const { t } = useTranslation();

  const predefinedFiltes: PredefinedFilter[] = [
    {
      filters: [
        {
          filterType: FilterHeaderType.Select,
          filterValues: ['Unread'],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'read',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Unread'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Date,
          filterValues: ['Today'],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'createdAt',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Today'),
    },
    {
      filters: [
        {
          filterType: FilterHeaderType.Date,
          filterValues: ['LastSevenDays'],
          isMultiSelect: true,
          itemsFiltered: [],
          propertyId: 'createdAt',
          subPropertyId: '',
          translateOptions: true,
        },
      ],
      title: t('Last week'),
    },
  ];

  return predefinedFiltes;
};
