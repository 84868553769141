import { Dispatch, SetStateAction } from 'react';

import {
  BatchQualityControlInstancesQuery,
  Document,
  DocumentQuery,
  QualityControlInstance,
  Task,
  TaskType,
  VisualContext,
} from '@pro4all/graphql';

export const determineLinkInstances = ({
  snagToLink,
  isEditMode,
  taskType,
  copiedTask,
  task,
  checkedRows,
  filterFormsOutOfCheckedRows,
  documentToLink,
}: {
  checkedRows: (QualityControlInstance | Document)[];
  copiedTask?: Task | null;
  documentToLink?: DocumentQuery;
  filterFormsOutOfCheckedRows: () => (QualityControlInstance | Document)[];
  isEditMode: boolean;
  snagToLink?: BatchQualityControlInstancesQuery;
  task?: Task;
  taskType: TaskType;
}) => {
  //creating resolve task by clicking single task row in table
  if (snagToLink?.qualityControlInstances?.length) {
    return snagToLink?.qualityControlInstances;
  }
  //duplicating a document task
  else if (
    !isEditMode &&
    taskType === TaskType.Document &&
    copiedTask?.deliverables?.length
  ) {
    return copiedTask.deliverables;
  }
  //duplicating a resolve task
  else if (
    !isEditMode &&
    taskType === TaskType.Resolve &&
    copiedTask?.linkedSnagInstances
  ) {
    return copiedTask?.linkedSnagInstances;
  }

  //editing document task
  if (isEditMode && task) {
    if (taskType === TaskType.Document && task.deliverables?.length) {
      return task.deliverables;
      //edit resolve task
    } else if (taskType === TaskType.Resolve && task.linkedSnagInstances) {
      return task.linkedSnagInstances;
    }
  }
  // We want to prioritize selected checked rows when manipulating document tasks
  //creating resolve task or document task and link multiple snags or documents(via table checkboxes)
  if (checkedRows.length) {
    return filterFormsOutOfCheckedRows();
  }
  //creating document task by clicking single document in table
  if (documentToLink?.document) {
    return [documentToLink?.document];
  }
  return [];
};

export const setFPS = ({
  qualityControlInstances,
  setFloorPlans,
}: {
  qualityControlInstances: QualityControlInstance[];
  setFloorPlans: Dispatch<SetStateAction<VisualContext[]>>;
}) => {
  const fps = qualityControlInstances?.map(
    (instance) => instance.visualContext
  ) as VisualContext[];

  const uniqueFloorplans = fps?.reduce(
    (acc: VisualContext[], curr: VisualContext) => {
      if (!acc.some((fp: VisualContext) => fp.id === curr.id)) acc.push(curr);
      return acc;
    },
    []
  );

  setFloorPlans(uniqueFloorplans);
};

export const removeLinkInstance = ({
  instance,
  linkInstances,
  setLinkInstances,
  setDeletedLinkedInstance,
  setFloorPlans,
  onClose,
}: {
  instance: QualityControlInstance | Document;
  linkInstances: QualityControlInstance[] | Document[];
  onClose: () => void;
  setDeletedLinkedInstance: Dispatch<SetStateAction<boolean>>;
  setFloorPlans: Dispatch<SetStateAction<VisualContext[]>>;
  setLinkInstances: Dispatch<
    SetStateAction<QualityControlInstance[] | Document[]>
  >;
}) => {
  const indexToRemove = linkInstances.findIndex(
    (inst: Document | QualityControlInstance) => instance.id === inst.id
  );
  const newInstances = [...linkInstances] as
    | QualityControlInstance[]
    | Document[];
  newInstances.splice(indexToRemove, 1);
  setLinkInstances(newInstances);
  if (instance.__typename !== 'Document')
    setFPS({
      qualityControlInstances: newInstances as QualityControlInstance[],
      setFloorPlans,
    });
  setDeletedLinkedInstance(true);
  if (newInstances.length === 0) onClose();
};
