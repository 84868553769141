import { useTranslation } from 'react-i18next';

import { TbqBrandcheckMultiResult } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Text } from '@pro4all/shared/ui/typography';

import {
  BrandcheckProgressBarType,
  calculateAveragesByObject,
} from './brandcheckMultiAverages';
import {
  BarPercentageContainer,
  Percentage,
  ProgressBar,
  ProgressBarsPerObjectWrapper,
  SubTitle,
} from './Progressbar.styles';

interface BrandcheckMultiDashboardDetailedProgressProps {
  brandcheckMultiResult: TbqBrandcheckMultiResult[];
  id: string | null;
  progressBarType: BrandcheckProgressBarType | null;
}

export const BrandcheckMultiDashboardDetailedProgress: React.FC<
  BrandcheckMultiDashboardDetailedProgressProps
> = ({ id, brandcheckMultiResult, progressBarType }) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();

  if (!id || !brandcheckMultiResult) return null;

  const goalOrCategoryName = searchParams.get('goalOrCategoryName');

  if (!progressBarType) return null;

  const filteredResults = brandcheckMultiResult.filter((scan) =>
    progressBarType === BrandcheckProgressBarType.Category
      ? scan.categories.some((category) => category.id === id)
      : scan.operationGoals.some((goal) => goal.id === id)
  );

  const averagesByObject = calculateAveragesByObject(
    filteredResults,
    id,
    progressBarType
  );

  return (
    <ProgressBarsPerObjectWrapper>
      <SubTitle>
        {progressBarType === BrandcheckProgressBarType.Category
          ? 'Categorie'
          : 'Operationele doelen'}{' '}
        <strong>{goalOrCategoryName}</strong>
      </SubTitle>
      {averagesByObject.map((result) => (
        <>
          <Text>{`${t('Object')}: ${result.objectName}`}</Text>
          <BarPercentageContainer>
            <ProgressBar
              max={100}
              value={Math.min(
                Math.max(2, Math.ceil(result.averagePercentage * 100), 1)
              )}
            />
            <Percentage>{`${Math.ceil(
              result.averagePercentage * 100
            )}%`}</Percentage>
          </BarPercentageContainer>
        </>
      ))}
    </ProgressBarsPerObjectWrapper>
  );
};
