import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import {
  TaskType,
  useTaskQuery,
  useVisualContextsQuery,
  VisualContext,
} from '@pro4all/graphql';
import { FloorPlanRouterState } from '@pro4all/quality-control/data-access';
import { getFloorPlanRoute } from '@pro4all/quality-control/utils';
import { Routes } from '@pro4all/shared/config';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Button } from '@pro4all/shared/ui/buttons';
import { FloatingModal } from '@pro4all/shared/ui/floating-modal';
import { Card, Timestamp } from '@pro4all/shared/ui/general';
import { useSearchInput } from '@pro4all/shared/ui/search-input';

import { useAnswerEditContext } from './AnswerEditContext';

interface Props {
  fieldId: string;
  onClose: () => void;
  projectId: string;
}

export const PlaceSelectSnagDialog = ({
  onClose,
  fieldId,
  projectId,
}: Props) => {
  const { t } = useTranslation();

  const { goTo, searchParams, url, params } = useRouting();
  const { enqueueSnackbar } = useSnackbar();
  const { query, searchInput } = useSearchInput();
  const formInstanceId = searchParams.get('id');

  const taskId = searchParams.get('taskId');
  const isMyTasks = useRouteMatch([Routes.projectWorkflows]);
  const isResults = useRouteMatch([Routes.projectQualityControlResults]);
  const editResult = searchParams.is('action', 'editResult');
  const projId = projectId ?? params.projectId;

  const { linkedTaskId } = useAnswerEditContext();

  const { data: taskData, error: taskError } = useTaskQuery({
    fetchPolicy: 'cache-and-network',
    skip: !taskId && !linkedTaskId,
    variables: { id: taskId ?? linkedTaskId },
  });

  const isTbqType =
    taskData?.task?.type === TaskType.Tbq ||
    taskData?.task?.type === TaskType.TbqScan;

  const { data: vcData, error: vcError } = useVisualContextsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isTbqType && taskData?.task?.visualContexts?.length > 0,
    variables: { projectId: projId },
  });

  if (taskError || vcError) {
    enqueueSnackbar(t('Could not fetch floor plans.'));
    return null;
  }

  if (!taskData && !vcData) {
    return null;
  }

  let visualContexts: VisualContext[] = [];

  if (isTbqType || !linkedTaskId) {
    visualContexts = vcData?.visualContexts ?? [];
  } else if (linkedTaskId) {
    visualContexts = taskData?.task?.visualContexts?.length
      ? taskData.task.visualContexts
      : [];
  }

  const filteredVisualContexts = visualContexts.filter(
    ({ name, deletedAt }) =>
      name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) && !deletedAt
  );
  const navigateToFloorplan = (id: string) => {
    const previousPageName =
      (editResult && 'Editing result') ||
      (isMyTasks && 'My tasks') ||
      (isResults && 'Results') ||
      'Tasks';

    goTo(getFloorPlanRoute(params), {
      params: { objectId: params.objectId, projectId, visualContextId: id },
      searchParams: {
        fieldId,
        formInstanceId,
        id: id,
        placeSnag: true,
        taskId,
      },
      state: {
        previousPageName,
        previousPageUrl: url,
      } as FloorPlanRouterState,
    });
  };

  return (
    <FloatingModal
      maxWidth="md"
      onClose={() => onClose()}
      open
      title={t('Select a floorplan')}
    >
      <FloatingModal.Header iconName="map">
        {t('Select a floorplan')}
      </FloatingModal.Header>
      <ContentWrapper>
        {searchInput}
        {!filteredVisualContexts.length && <p>No floor plans found</p>}

        {filteredVisualContexts.map(({ id, name, createdAt, createdBy }) => (
          <Card
            disabledButtonBorder
            iconName="map"
            key={id}
            menuItems={[
              {
                label: `Floor Plan`,
                onClick: () => navigateToFloorplan(id),
                startIcon: 'arrowForward',
              },
            ]}
            meta={[
              `${createdBy?.displayName}`,
              <Timestamp date={createdAt} format="lll" />,
            ]}
            onClick={() => navigateToFloorplan(id)}
            title={name}
          />
        ))}
      </ContentWrapper>
      <FloatingModal.Footer>
        <Button color="inherit" onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
      </FloatingModal.Footer>
    </FloatingModal>
  );
};

const ContentWrapper = styled(Box)`
  && {
    height: 500px;
  }
`;
