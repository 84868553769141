import React from 'react';

import { FilterType } from '@pro4all/graphql';
import { FilterAndButtonProps } from '@pro4all/shared/search-utils';

import { BooleanFilter } from './meta-data/BooleanFilter';
import { MDDateFilter } from './meta-data/MDDateFilter';
import { NumberFilter } from './meta-data/NumberFilter';
import { QCUserSelection } from './meta-data/QCUserSelection';
import { SelectionFilter } from './meta-data/SelectionFilter';
import { TextFilter } from './meta-data/TextFilter';
import { DateFilter } from './DateFilter';
import { FileTypeFilter } from './FileTypeFilter';
import { FolderFilter } from './FolderFilter';
import { MemberFilter } from './MemberFilter';
import { QCFloorplanFilter } from './QCFloorplanFilter';
import { QCSelectFilter } from './QCSelectFilter';
import { QCTemplateFilter } from './QCTemplateFilter';
import { QCTypeFilter } from './QCTypeFilter';
import { QrStampFilter } from './QrStampFilter';
import { StateFilter } from './StateFilter';
import { VersionStateFilter } from './VersionStateFilter';

type FilterComponentType = {
  [key in FilterType]?: React.ElementType<FilterAndButtonProps>;
};

export const ComponentByType: FilterComponentType = {
  [FilterType.CreatedBy]: MemberFilter,
  [FilterType.ParentFolderIds]: FolderFilter,
  [FilterType.FolderId]: FolderFilter,
  [FilterType.UpdatedBy]: MemberFilter,
  [FilterType.CreatedAt]: DateFilter,
  [FilterType.UpdatedAt]: DateFilter,
  [FilterType.Extension]: FileTypeFilter,
  [FilterType.State]: StateFilter,
  [FilterType.VersionState]: VersionStateFilter,
  [FilterType.Bool]: BooleanFilter,
  [FilterType.Number]: NumberFilter,
  [FilterType.Versions]: QrStampFilter,
  [FilterType.Selection]: SelectionFilter,
  [FilterType.DateTime]: MDDateFilter,
  [FilterType.Text]: TextFilter,
  [FilterType.Status]: SelectionFilter,
  [FilterType.Floorplan]: QCFloorplanFilter,
  [FilterType.Template]: QCTemplateFilter,
  [FilterType.ProjectNumber]: QCSelectFilter,
  [FilterType.ProjectName]: QCSelectFilter,
  [FilterType.Tasks]: QCSelectFilter,
  [FilterType.Snags]: SelectionFilter,
  [FilterType.Forms]: SelectionFilter,
  [FilterType.UserSelection]: QCUserSelection,
  [FilterType.Highlight]: QCSelectFilter,
  [FilterType.TemplateType]: QCTypeFilter,
  [FilterType.TemplateId]: QCTypeFilter,
};
