import { createContext, useContext } from 'react';

import type { ObjectNode } from '@pro4all/graphql';

import type { UseObjectDetailResult } from './useObjectDetail';

// Context can only be initialized when we have an object so we can drop
// the | undefined from the `UseObjectDetailResult`.
interface Context extends UseObjectDetailResult {
  object: ObjectNode;
  projectId?: string;
}

const ObjectDetailContext = createContext<Context | undefined>(undefined);

export const useObjectDetailContext = () => {
  const context = useContext(ObjectDetailContext);
  if (!context) throw Error('Object detail context not initialized.');
  return context;
};

export const ObjectDetailProvider: React.FC<Context> = ({
  children,
  ...value
}) => {
  if (!value) throw Error('Object context not initialized.');
  return (
    <ObjectDetailContext.Provider value={value}>
      {children}
    </ObjectDetailContext.Provider>
  );
};
