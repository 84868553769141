import React from 'react';
import * as MuiIcons from '@mui/icons-material';
import styled from 'styled-components';

import { QualityControlInstanceType } from '@pro4all/graphql';
import { Box } from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

import { TMuiIcon } from './composed-snag-form-pin-types';

type ComposedPinType = 'snag' | 'form' | 'tbq';

export type TComposedPinProps = {
  currentFile?: File | string | null;
  customColor?: string;
  left?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  report?: boolean;
  scale?: number;
  selectedIconName?: TMuiIcon;
  simpleIcon?: boolean;
  top?: number;
  type: ComposedPinType;
};

export const ComposedPin = ({
  top = 0,
  left = 0,
  report = false,
  ...props
}: TComposedPinProps) => {
  const defaultSelectedIcon =
    props.type === QualityControlInstanceType.Tbq
      ? ''
      : props.type === QualityControlInstanceType.Snag
      ? 'ShareLocation'
      : 'Rule';

  const Component = defaultSelectedIcon
    ? MuiIcons[props.selectedIconName || (defaultSelectedIcon as TMuiIcon)]
    : null;

  const currentPin = !props.simpleIcon
    ? props.type === QualityControlInstanceType.Snag
      ? 'changeableSnagIcon'
      : 'changeableFormIcon'
    : props.type === QualityControlInstanceType.Snag
    ? 'changeableSimpleSnagIcon'
    : props.type === QualityControlInstanceType.Tbq
    ? 'tbqMainGreen'
    : 'changeableSimpleFormIcon';

  const scale = props.scale || 1;

  const iconSize = (props.simpleIcon ? 18 : 22) * scale;

  const fileUrl =
    props.currentFile instanceof File
      ? URL.createObjectURL(props.currentFile)
      : props.currentFile;

  const containedWidth = (props.simpleIcon ? 20 : 27) * scale;
  const containedHeight = (props.simpleIcon ? 20 : 27) * scale;

  const tailHeight = 18 * scale;

  return (
    <OuterIconContainer
      className={report ? 'outer-icon-container-reports' : ''}
      height={!props.simpleIcon ? `${containedHeight + tailHeight}px` : 'auto'}
      onClick={(event) => {
        props?.onClick?.(event);
      }}
    >
      <InnerIconContainer
        className={report ? 'inner-icon-container-reports' : ''}
        height={`${containedHeight}px`}
        width={`${containedWidth}px`}
      >
        <Icon
          customColor={props.customColor}
          iconName={currentPin}
          scale={scale}
        />

        <IconContainer
          className={report ? 'icon-container-reports' : ''}
          left={`${left}px`}
          top={`${top}px`}
        >
          {props.currentFile ? (
            <ImageIcon
              scale={props.scale || 1}
              simpleIcon={props.simpleIcon}
              src={fileUrl || ''}
              type={props.type}
              width={`${iconSize}px`}
            />
          ) : (
            Component && (
              <Component
                sx={{
                  fontSize: `${iconSize}px`,
                  padding: '2px',
                }}
              />
            )
          )}
        </IconContainer>
      </InnerIconContainer>
    </OuterIconContainer>
  );
};

const ImageIcon = styled.img<{
  scale: number;
  simpleIcon?: boolean;
  type: ComposedPinType;
}>`
  clip-path: circle(60%);
  max-height: 100%;
  max-width: 100%;
  padding: 0
    ${(props) => {
      const imagePadding = props.simpleIcon ? 1.5 : 3;
      return props.type === 'snag' ? `${imagePadding * props.scale}px` : '0';
    }};
  overflow: hidden;
`;

const OuterIconContainer = styled(Box)<{ height: string }>`
  position: relative;
  height: ${({ height }) => height};
`;

const InnerIconContainer = styled(Box)<{ height: string; width: string }>`
  cursor: pointer;
  position: relative;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

const IconContainer = styled(Box)<{ left: string; top: string }>`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 4px;
  position: absolute;
  width: 100%;
  left: ${({ left }) => left};
  top: ${({ top }) => top};
  z-index: 200;
`;
