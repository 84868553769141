import styled from 'styled-components';

import { Box } from '@pro4all/shared/mui-wrappers';

export const Scans = styled(Box)`
  && {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    width: 100%;
  }
`;
