import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@pro4all/shared/mui-wrappers';
import { Tab, Tabs } from '@pro4all/shared/ui/general';

import { useReportOptionsContext } from '../ReportOptionsContext';

interface CustomOptionsTabsProps {
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}

export const CustomOptionsTabs: React.FC<CustomOptionsTabsProps> = ({
  currentTab,
  setCurrentTab,
}) => {
  const { t } = useTranslation();

  const context = useReportOptionsContext();
  if (!context) throw new Error('ReportOptionsContext not available');

  return (
    <Box mb={2}>
      <Tabs onChange={(e, value) => setCurrentTab(value)} value={currentTab}>
        <Tab
          data-testid="report-options-general-tab"
          label={t('General')}
          value="general"
        />
        <Tab
          data-testid="report-options-frontpage-tab"
          label={t('Front page')}
          value="frontpage"
        />
        <Tab
          data-testid="report-options-snag-tab"
          label={t('Snag')}
          value="snag"
        />
        <Tab
          data-testid="report-options-snag-tab"
          label={t('Form')}
          value="form"
        />
      </Tabs>
    </Box>
  );
};
