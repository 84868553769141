import React from 'react';

import { groupInstancesByFloorplan } from '@pro4all/quality-control/utils';
import { GroupedInstances, ReportProps } from '@pro4all/shared/types';

import FloorplanPage from './pages/FloorplanPage';
import { FrontPage } from './pages/FrontPage';
import { InstancePage } from './pages/InstancePage';
import { TaskInstancePage } from './pages/TaskInstancePage';

export const Report = ({
  currentUser,
  date,
  miniMaps,
  reportFloorplans,
  photoBlobs,
  project,
  reportOptions,
  signatures,
  tasks,
  instancesGroupedByFloorplan,
  ...rest
}: ReportProps) => {
  const filterSignatures = (instanceId: string) =>
    signatures?.filter((signature) => signature.instanceId === instanceId);
  const InstancesGroupedByFP = ({
    groupedInstances,
  }: {
    groupedInstances: GroupedInstances[];
  }) => (
    <>
      {groupedInstances?.map((group) => {
        const reportFloorplanHtml = reportFloorplans
          ? reportFloorplans[group.visualContextId + group.page]
          : undefined;

        return (
          <>
            {reportFloorplanHtml && reportFloorplanHtml.length > 0 && (
              <FloorplanPage
                group={group}
                reportFloorplanHtml={reportFloorplanHtml}
                reportOptions={reportOptions}
              />
            )}

            {group.instances.map((instance) => {
              const miniMapHtml = miniMaps ? miniMaps[instance.id] : undefined;
              return (
                <InstancePage
                  instance={instance}
                  key={instance.id}
                  miniMapHtml={miniMapHtml}
                  photoBlobs={photoBlobs}
                  reportFloorplanHtml={reportFloorplanHtml}
                  reportOptions={reportOptions}
                  signatures={filterSignatures(instance.id)}
                />
              );
            })}
          </>
        );
      })}
    </>
  );

  return (
    <>
      <FrontPage
        date={date}
        project={project}
        reportOptions={reportOptions}
        user={currentUser}
      />
      {tasks && tasks?.length > 0
        ? tasks.map((task) => (
            <>
              <TaskInstancePage instance={task} reportOptions={reportOptions} />
              {task.linkedSnagInstances && (
                <InstancesGroupedByFP
                  groupedInstances={groupInstancesByFloorplan(
                    task.linkedSnagInstances
                  )}
                />
              )}
            </>
          ))
        : instancesGroupedByFloorplan && (
            <InstancesGroupedByFP
              groupedInstances={instancesGroupedByFloorplan}
            />
          )}
    </>
  );
};
