import React, { useContext, useState } from 'react';
import { LatLngBounds } from 'leaflet';

import { DroppedFloorplan } from '@pro4all/quality-control/data-access';

type QualityControlContextValue = {
  QCProjectId: string;

  beCheck: DroppedFloorplan[];

  canUpdate: DroppedFloorplan[];
  canUpload: DroppedFloorplan[];
  cantUpdate: DroppedFloorplan[];
  cantUpload: DroppedFloorplan[];
  floorplanBounds: LatLngBounds;

  setBeCheck: React.Dispatch<React.SetStateAction<DroppedFloorplan[]>>;

  setCanUpdate: React.Dispatch<React.SetStateAction<DroppedFloorplan[]>>;
  setCanUpload: React.Dispatch<React.SetStateAction<DroppedFloorplan[]>>;
  setCantUpdate: React.Dispatch<React.SetStateAction<DroppedFloorplan[]>>;
  setCantUpload: React.Dispatch<React.SetStateAction<DroppedFloorplan[]>>;
  setFloorplanBounds: React.Dispatch<React.SetStateAction<LatLngBounds>>;

  setQCProjectId: React.Dispatch<React.SetStateAction<string>>;
  setUpdateMarkerObj: React.Dispatch<
    React.SetStateAction<{
      instanceId: string;
      page: number;
      visualContextId: string;
      x: number;
      y: number;
    }>
  >;

  updateMarkerObj: {
    instanceId: string;
    page: number;
    visualContextId: string;
    x: number;
    y: number;
  };
};

const QualityControlContext = React.createContext<QualityControlContextValue>({
  QCProjectId: '',
  beCheck: [],
  canUpdate: [],
  canUpload: [],
  cantUpdate: [],
  cantUpload: [],
  floorplanBounds: new LatLngBounds({ lat: 0, lng: 0 }, { lat: 0, lng: 0 }),
  setBeCheck: () => [],
  setCanUpdate: () => [],
  setCanUpload: () => [],
  setCantUpdate: () => [],
  setCantUpload: () => [],
  setFloorplanBounds: () =>
    new LatLngBounds({ lat: 0, lng: 0 }, { lat: 0, lng: 0 }),
  setQCProjectId: () => '',
  setUpdateMarkerObj: () => '',
  updateMarkerObj: { instanceId: '', page: 1, visualContextId: '', x: 0, y: 0 },
});

export function useQualityControlContext() {
  return useContext(QualityControlContext);
}

export const QualityControlContextProvider: React.FC = ({ children }) => {
  const [floorplanBounds, setFloorplanBounds] = useState<LatLngBounds>(
    new LatLngBounds({ lat: 0, lng: 0 }, { lat: 0, lng: 0 })
  );

  const [canUpdate, setCanUpdate] = useState<DroppedFloorplan[]>([]);
  const [cantUpdate, setCantUpdate] = useState<DroppedFloorplan[]>([]);
  const [beCheck, setBeCheck] = useState<DroppedFloorplan[]>([]);
  const [cantUpload, setCantUpload] = useState<DroppedFloorplan[]>([]);
  const [canUpload, setCanUpload] = useState<DroppedFloorplan[]>([]);

  const [updateMarkerObj, setUpdateMarkerObj] = useState({
    instanceId: '',
    page: 1,
    visualContextId: '',
    x: 0,
    y: 0,
  });

  const [QCProjectId, setQCProjectId] = useState<string>('');

  const qualityControlContextValue = {
    QCProjectId,
    beCheck,
    canUpdate,
    canUpload,
    cantUpdate,
    cantUpload,
    floorplanBounds,
    setBeCheck,
    setCanUpdate,
    setCanUpload,
    setCantUpdate,
    setCantUpload,
    setFloorplanBounds,
    setQCProjectId,
    setUpdateMarkerObj,
    updateMarkerObj,
  };

  return (
    <QualityControlContext.Provider value={qualityControlContextValue}>
      {children}
    </QualityControlContext.Provider>
  );
};
