import {
  FieldDefinition,
  MeUser,
  Project,
  QualityControlInstance,
  QualityControlInstanceType,
  Task,
  ValueTypeName,
} from '@pro4all/graphql';

import { Option } from './types';

// Collection with props used for reporting. Specific types below pick their props from this collection.
// This removes the enormous amount of duplicate prop typing we had before.
export type ReportTypes = {
  add: boolean;
  background: string;
  backgroundFrontPage?: string;
  bgId: string;
  bgIdFrontPage: string;
  borderRight?: boolean;
  checked: boolean;
  children: React.ReactNode;
  color: string;
  columns?: number;
  compactLists?: boolean;
  companyLogo?: string;
  currentUser: MeUser;
  customMode?: boolean;
  date: Date;
  elementIdentifier: string;
  enableCreatedBy: boolean;
  enableCreatedOn: boolean;
  enableEndDate: boolean;
  enableFrontPage: boolean;
  enableGeneratedWith: boolean;
  enableProjectDescription: boolean;
  enableProjectName: boolean;
  enableProjectNumber: boolean;
  enableStartDate: boolean;
  enableTitle: boolean;
  finalInstances: QualityControlInstance[];
  flattenedItem: FlattenedItem;
  footerText?: string;
  formTemplateOptions: Option[];
  group: GroupedInstances;
  hiddenSectionsAndFields: string[];
  html: string;
  id: string;
  ids: string[];
  imageColumn: keyof typeof ImageColumns;
  inlineLists?: boolean;
  instance: Task;
  instanceId: string;
  instances: QualityControlInstance[];
  instancesGroupedByFloorplan: GroupedInstances[];
  instancesWithSignatures: InstanceWithSignature[];
  item: FieldDefinition;
  itemId: string;
  items: FieldDefinition[];
  key: string;
  labelWidth?: number;
  lat: number;
  level: number;
  linkedSnagInstances: QualityControlInstance[];
  linkedSnags: QualityControlInstance[];
  lng: number;
  logoId: string;
  mapHeight: number;
  mapInstances: QualityControlInstance[];
  mapWidth: number;
  mediaLoaded: boolean;
  miniMapHtml?: string;
  miniMaps?: MapElements;
  name: string;
  onClose?: () => void;
  onLoad: () => void;
  options?: ReportOptions;
  page: number;
  photoBlobs?: PhotoBlobs;
  preventPageBreak?: boolean;
  previewInstances: QualityControlInstance[];
  project: Project;
  reportFloorplanHtml?: string;
  reportFloorplans?: MapElements;
  reportOptions: ReportOptions;
  selectStyleTemplate: (option: Option) => void;
  selectedStyleTemplate?: Option;
  setBackground: (id: string) => void;
  setBackgroundFrontPage: (id: string) => void;
  setHiddenSectionsAndFields: (payload: PayloadHiddenSectionsAndFields) => void;
  setLogo: (id: string) => void;
  setMiniMaps: React.Dispatch<React.SetStateAction<MapElements | undefined>>;
  setReportFloorplans: React.Dispatch<
    React.SetStateAction<MapElements | undefined>
  >;
  setReportOptions: (reportOptions: ReportOptions) => void;
  setTemplate: (value: Option) => void;
  setTemplateOptions: (templateId: string, options: TemplateOptions) => void;
  showDescription: boolean;
  showEmptyAnswers: boolean;
  showLinkedSnags: boolean;
  showPageNumbers: boolean;
  signatureId: string;
  signatureUrl: string;
  signatures?: SignatureObject[];
  snagTemplateOptions: Option[];
  styleTemplateOptions: Option[];
  tasks?: Task[];
  template: Option;
  templateId: string;
  templateOptions: TemplateOptions;
  templates?: Record<string, TemplateOptions>;
  // index by templateId
  toggleCustomMode: (force?: boolean) => void;
  type: ValueTypeName;
  unnestedItems: FieldDefinition[];
  user: MeUser;
  values: string[];
  visualContextId: string;
  visualContextName: string;
  zoomLevels?: number;
};

export enum ImageColumns {
  aOne = 'One',
  bTwo = 'Two',
  cThree = 'Three',
  dFour = 'Four',
}

export type PhotoBlobs = Record<string, string> | null;
export type MapElements = Record<string, string>;
export type ColumnsMap = {
  [key in keyof typeof ImageColumns]: number;
};

export type InstanceWithSignature = Pick<
  ReportTypes,
  'instanceId' | 'itemId' | 'signatureId'
>;

export type SignatureObject = Pick<
  ReportTypes,
  'instanceId' | 'itemId' | 'signatureId' | 'signatureUrl'
>;

export type ReactReportProps = Pick<
  ReportTypes,
  | 'currentUser'
  | 'customMode'
  | 'finalInstances'
  | 'miniMaps'
  | 'photoBlobs'
  | 'project'
  | 'reportFloorplans'
  | 'reportOptions'
> & { signatures: SignatureObject[] };

export type GroupedInstances = Pick<
  ReportTypes,
  'instances' | 'page' | 'visualContextId' | 'visualContextName'
>;

export type ReportProps = Pick<
  ReportTypes,
  | 'currentUser'
  | 'date'
  | 'instancesGroupedByFloorplan'
  | 'miniMaps'
  | 'photoBlobs'
  | 'project'
  | 'reportFloorplans'
  | 'reportOptions'
  | 'signatures'
  | 'tasks'
>;

export type LayoutProps = Pick<
  ReportTypes,
  | 'borderRight'
  | 'columns'
  | 'compactLists'
  | 'inlineLists'
  | 'labelWidth'
  | 'preventPageBreak'
>;

export interface ReportOptions
  extends LayoutProps,
    Pick<
      ReportTypes,
      | 'background'
      | 'backgroundFrontPage'
      | 'companyLogo'
      | 'enableCreatedBy'
      | 'enableCreatedOn'
      | 'enableEndDate'
      | 'enableFrontPage'
      | 'enableGeneratedWith'
      | 'enableProjectDescription'
      | 'enableProjectName'
      | 'enableProjectNumber'
      | 'enableStartDate'
      | 'enableTitle'
      | 'footerText'
      | 'showEmptyAnswers'
      | 'showPageNumbers'
      | 'templates'
    > {}

export type TemplateOptions = Pick<
  ReportTypes,
  | 'hiddenSectionsAndFields'
  | 'imageColumn'
  | 'showDescription'
  | 'showLinkedSnags'
>;

export type ReportConfigTypes = Pick<
  ReportTypes,
  | 'checked'
  | 'elementIdentifier'
  | 'flattenedItem'
  | 'formTemplateOptions'
  | 'hiddenSectionsAndFields'
  | 'id'
  | 'items'
  | 'level'
  | 'onClose'
  | 'previewInstances'
  | 'reportOptions'
  | 'setHiddenSectionsAndFields'
  | 'setTemplate'
  | 'setTemplateOptions'
  | 'snagTemplateOptions'
  | 'template'
> & { templateOptions: Option[] };

export type FlattenedItem = Pick<ReportTypes, 'id' | 'level' | 'name' | 'type'>;

export type PayloadHiddenSectionsAndFields = Pick<
  ReportTypes,
  'add' | 'ids' | 'templateId'
>;

export type ReportFloorplanProps = Pick<
  ReportTypes,
  'instances' | 'page' | 'visualContextId'
>;

export type ReportFloorplanContentsProps = Pick<
  ReportTypes,
  | 'children'
  | 'mapHeight'
  | 'mapWidth'
  | 'page'
  | 'visualContextId'
  | 'zoomLevels'
>;

export interface StrictInstanceType
  extends Omit<QualityControlInstance, 'templateId'>,
    Pick<ReportTypes, 'templateId'> {}

export type ReportMapContentsProps = Pick<
  ReportTypes,
  | 'children'
  | 'lat'
  | 'lng'
  | 'mapHeight'
  | 'mapWidth'
  | 'page'
  | 'visualContextId'
  | 'zoomLevels'
> & { type: QualityControlInstanceType };

export type ReportMiniMapProps = QualityControlInstance;

export type ReportOptionsContextValue = Pick<
  ReportTypes,
  | 'bgId'
  | 'bgIdFrontPage'
  | 'logoId'
  | 'miniMaps'
  | 'reportFloorplans'
  | 'reportOptions'
  | 'selectStyleTemplate'
  | 'selectedStyleTemplate'
  | 'setBackground'
  | 'setBackgroundFrontPage'
  | 'setHiddenSectionsAndFields'
  | 'setLogo'
  | 'setMiniMaps'
  | 'setReportFloorplans'
  | 'setReportOptions'
  | 'setTemplateOptions'
  | 'styleTemplateOptions'
  | 'toggleCustomMode'
> & { customMode: boolean };

export interface ReportPreviewProps
  extends Omit<ReportProps, 'reportOptions'>,
    Pick<
      ReportTypes,
      'instances' | 'instancesGroupedByFloorplan' | 'mapInstances' | 'tasks'
    > {}

export type FloorplanReportMapsProps = {
  instancesGroupedByFloorplan: GroupedInstances[];
  onLoad?: () => void;
};

export type FetchArgs = { id: string; instanceId: string };

export type ReportMiniMapsProps = Pick<ReportTypes, 'instances'>;

export type FetchSignatureReportsProps = Pick<
  ReportTypes,
  'instancesWithSignatures'
>;

export type ReportRendererProps = Pick<
  ReportTypes,
  'html' | 'id' | 'mediaLoaded'
>;

export type ReportImagesProps = Pick<
  ReportTypes,
  'photoBlobs' | 'reportOptions' | 'templateId' | 'values'
>;

export type SignatureReportsProps = Pick<ReportTypes, 'signatureUrl'>;

export type QCTaskResourcesProps = Pick<ReportTypes, 'instance'>;

export type ResolveTaskResourcesProps = Pick<ReportTypes, 'instance'>;

export type LinkedSnagsListProps = Pick<ReportTypes, 'linkedSnags'>;

export type ReportDescriptionFieldProps = Pick<ReportTypes, 'item' | 'key'>;

export type ReportHierarchyFieldProps = Pick<
  ReportTypes,
  'item' | 'key' | 'linkedSnags' | 'templateOptions'
>;

export type ReportMediaFieldProps = Pick<
  ReportTypes,
  | 'item'
  | 'key'
  | 'linkedSnags'
  | 'photoBlobs'
  | 'reportOptions'
  | 'templateId'
  | 'templateOptions'
>;

export type ReportSelectionFieldProps = Pick<
  ReportTypes,
  'item' | 'key' | 'linkedSnags' | 'reportOptions' | 'templateOptions'
>;

export type ReportSignatureFieldProps = Pick<
  ReportTypes,
  | 'item'
  | 'key'
  | 'linkedSnags'
  | 'signatureUrl'
  | 'signatures'
  | 'templateOptions'
>;

export type ReportStatusFieldProps = Pick<
  ReportTypes,
  | 'background'
  | 'color'
  | 'item'
  | 'key'
  | 'linkedSnags'
  | 'options'
  | 'templateOptions'
>;

export type ReportStopwatchFieldProps = Pick<
  ReportTypes,
  'item' | 'key' | 'linkedSnags' | 'options' | 'templateOptions'
>;

export type InstancePageSectiontProps = Pick<
  ReportTypes,
  | 'instanceId'
  | 'items'
  | 'linkedSnagInstances'
  | 'photoBlobs'
  | 'reportOptions'
  | 'signatures'
  | 'templateId'
  | 'unnestedItems'
> & { level?: number };

export type FloorplanPageProps = Pick<
  ReportTypes,
  'group' | 'reportFloorplanHtml' | 'reportOptions'
>;

export type FrontPageProps = Pick<
  ReportTypes,
  'date' | 'project' | 'reportOptions' | 'user'
>;

export type InstancePageProps = Pick<
  ReportTypes,
  | 'miniMapHtml'
  | 'photoBlobs'
  | 'reportFloorplanHtml'
  | 'reportOptions'
  | 'signatures'
> & { instance: QualityControlInstance };

export type InstancePageHeaderProps = Pick<
  ReportTypes,
  'instanceId' | 'items' | 'reportOptions'
>;

export type TaskInstancePageProps = Pick<
  ReportTypes,
  'instance' | 'reportOptions'
>;
