import { ValueTypeName } from '@pro4all/graphql';
import { FlattenedItem, ReportConfigTypes } from '@pro4all/shared/types';

export const flattenItems = ({
  items,
  level = 0,
}: Pick<ReportConfigTypes, 'items' | 'level'>) => {
  let flattenedItems: FlattenedItem[] = [];

  items.forEach((item) => {
    // Add the current item to the flat array with the current nested level.
    flattenedItems.push({
      id: item.id,
      level: level,
      name: item.name || '',
      type: item.type || ValueTypeName.Text,
    });

    // If the item is of type 'section' and has subFields, call the function recusrsively with that array of items and a one more deeper level.
    if (
      item.type === ValueTypeName.Section &&
      item?.valueType?.subFields &&
      item.valueType?.subFields?.length > 0
    ) {
      flattenedItems = flattenedItems.concat(
        flattenItems({ items: item.valueType.subFields, level: level + 1 })
      );
    }
  });

  return flattenedItems;
};
