import { useTranslation } from 'react-i18next';

import { QualityControlInstanceType } from '@pro4all/graphql';
import { FloorplanPageProps } from '@pro4all/shared/types';
import { FormPin, SnagPin } from '@pro4all/shared/ui/icons';

import {
  FloorplanContainer,
  FloorplanHeader,
  FloorplanLabel,
  FloorplanList,
  FloorplanTitle,
  Instance,
  Page,
} from './elements';

const FloorplanPage = ({
  group,
  reportFloorplanHtml,
  reportOptions,
}: FloorplanPageProps) => {
  const { t } = useTranslation();

  return (
    <Page>
      <FloorplanHeader>
        <FloorplanList
          compactLists={reportOptions.compactLists}
          inlineLists={reportOptions.inlineLists}
          labelWidth={reportOptions.inlineLists ? 30 : 40}
        >
          <FloorplanTitle>
            <Instance.Icon iconName="map" />
            {group.visualContextName}
          </FloorplanTitle>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
            }}
          >
            <FloorplanLabel>
              <SnagPin color="#E25314" iconScale={0.51} statusColor="" />
              {t('Placed snags')}:
              {
                group.instances.filter(
                  (instance) =>
                    instance.type === QualityControlInstanceType.Snag
                ).length
              }
            </FloorplanLabel>
            <FloorplanLabel>
              <FormPin color="#28237A" iconScale={0.51} statusColor="" />
              {t('Placed forms')}:
              {
                group.instances.filter(
                  (instance) =>
                    instance.type === QualityControlInstanceType.Form
                ).length
              }
            </FloorplanLabel>
          </div>
        </FloorplanList>
      </FloorplanHeader>

      <FloorplanContainer>
        <div
          className="floorplan-container"
          dangerouslySetInnerHTML={{
            __html: reportFloorplanHtml,
          }}
          id={`floorplan-container-${group.visualContextId}${group.page}`}
        />
      </FloorplanContainer>
    </Page>
  );
};

export default FloorplanPage;
