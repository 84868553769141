import React from 'react';

import { QualityControlInstance } from '@pro4all/graphql';
import {
  AnswersPercentageContextProvider,
  ResultSidebar,
} from '@pro4all/quality-control/ui/results';
import { PhotoProvider } from '@pro4all/shared/contexts';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SnagFromSearchContextProvider } from '@pro4all/shared/snags-and-forms-search-context';
import { Loader } from '@pro4all/shared/ui/general';
import { OptimisticResponseProvider } from '@pro4all/shared/ui/general';

import { FloorPlanResults } from './FloorPlanResults';
import { FloorPlanSearchResults } from './FloorPlanSearchResults';
import { useFetchFloorplan } from './useFetchFloorplan';

export const FloorPlan = () => {
  const {
    params: { visualContextId },
  } = useRouting();

  const { data } = useFetchFloorplan(visualContextId);

  const isFloorPlanSearchEnabled = useFeatureFlag('qc-search-drawing');

  if (!data?.visualContext) return <Loader />;

  return (
    <OptimisticResponseProvider<QualityControlInstance>>
      {isFloorPlanSearchEnabled ? (
        <SnagFromSearchContextProvider
          initializeSearchWithPredefinedHiddenFilters
        >
          <FloorPlanSearchResults visualContext={data.visualContext} />
          <PhotoProvider>
            <AnswersPercentageContextProvider>
              <ResultSidebar />
            </AnswersPercentageContextProvider>
          </PhotoProvider>
        </SnagFromSearchContextProvider>
      ) : (
        <>
          <FloorPlanResults visualContext={data.visualContext} />
          <PhotoProvider>
            <AnswersPercentageContextProvider>
              <ResultSidebar />
            </AnswersPercentageContextProvider>
          </PhotoProvider>
        </>
      )}
    </OptimisticResponseProvider>
  );
};
