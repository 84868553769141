import { ReactNode } from 'react';
import {
  createLayerComponent,
  LayerProps,
  LeafletContextInterface,
} from '@react-leaflet/core';
import L, { DoneCallback } from 'leaflet';

import { TileService } from '@pro4all/quality-control/data-access';
import { WhiteBackground } from '@pro4all/quality-control/ui/report';

interface AuthTileProps extends LayerProps {
  children?: ReactNode;
  maxZoom?: number;
  minZoom?: number;
  noWrap?: boolean;
  page: number;
  tileSize?: number;
  visualContextId: string;
  zoomOffset?: number;
}

class AuthTile64 extends L.TileLayer {
  visualContextId: string;
  className: string;
  page: number;

  createTile(coords: L.Coords, done: DoneCallback) {
    const img = document.createElement('img');
    img.setAttribute('role', 'presentation');
    img.setAttribute('alt', 'minimap-tile');

    const onError = () => {
      img.src = WhiteBackground;
      img.setAttribute('alt', '');
      done(new Error('Could not resolve media'), img);
    };

    TileService.getTile64(
      this.visualContextId,
      coords.z,
      coords.x,
      coords.y,
      this.page
    )
      .then((src) => {
        if (src) {
          img.src = src;
          done(undefined, img);
          return img;
        } else onError();
      }, null)
      .catch(() => {
        onError();
      });

    return img;
  }

  setVisualContextId(id: string) {
    this.visualContextId = id;
  }
  setPage(page: number) {
    this.page = page;
  }
}

const createAuthTileLayer = (
  props: AuthTileProps,
  context: LeafletContextInterface
) => {
  const instance = new AuthTile64('placeholder', {
    ...props,
  });
  instance.setVisualContextId(props.visualContextId);
  instance.setPage(props.page);
  return { context, instance };
};

const updateAuthTileLayer = (
  instance: AuthTile64,
  props: AuthTileProps,
  prevProps: AuthTileProps
) => {
  if (prevProps.visualContextId !== props.visualContextId) {
    instance.visualContextId = props.visualContextId;
  }
  if (prevProps.page !== props.page) {
    instance.page = props.page;
  }
};

export const AuthTileLayer64 = createLayerComponent(
  createAuthTileLayer,
  updateAuthTileLayer
);
