import { MapContainer } from 'react-leaflet';
import styled from 'styled-components';

export const ReportMapContainer = styled(MapContainer)`
  width: 256px;
  height: 256px;

  &.leaflet-grab,
  .leaflet-interactive {
    cursor: default;
  }

  .leaflet-map-pane .leaflet-marker-pane {
    .leaflet-div-icon {
      background: none;
      border: 0;
      border-radius: 50%;
    }
  }
`;

export const ReportFloorplanContainer = styled(MapContainer)<{
  height?: number;
  width?: number;
}>`
  width: 675px;
  height: 800px;

  box-sizing: border-box;
  border: 1px solid #afafaf;
  border-radius: 4px;

  &.leaflet-grab,
  .leaflet-interactive {
    cursor: default;
  }

  .leaflet-map-pane {
    transform: ${({ width, height }) =>
      height / width > 1
        ? ' translateX(0px) translateY(0px) rotate(0deg) !important'
        : ' translateX(735px) translateY(60px) rotate(90deg) !important'};
  }

  .leaflet-map-pane .leaflet-marker-pane {
    .leaflet-div-icon {
      background: none;
      border: 0;
      border-radius: 50%;
    }
  }
`;
