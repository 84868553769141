import { useTranslation } from 'react-i18next';

import {
  QualityControlInstance,
  QualityControlInstanceType,
  useTasksBatchIncludeQuery,
} from '@pro4all/graphql';
import {
  groupInstancesByFloorplan,
  sortGroupedInstances,
} from '@pro4all/quality-control/utils';
import { Box, Typography } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { Option, StrictInstanceType } from '@pro4all/shared/types';
import { Loader } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';

import { ReportOptionsForm } from './form/ReportOptionsForm';
import { useReportData } from './utils/useReportData';
import { BackButton, DownloadReportButton, PrintButton } from './components';
import {
  ActionBar,
  EditReportLayout,
  Main,
  ReportMainContainer,
} from './ReportingMainStyles';
import { ReportOptionsContextProvider } from './ReportOptionsContext';
import { ReportPreview } from './ReportPreview';

const INSTANCE_STEP_SIZE = 10;

const getLinkedSnagsIds = (instances: QualityControlInstance[]) => {
  const linkedSnagsIds: string[] = [];

  instances.forEach((instance) => {
    if (instance.type === 'form') {
      instance.linkedSnagInstances?.forEach((linkedSnag) => {
        linkedSnagsIds.push(linkedSnag.id);
      });
    }
  });

  return linkedSnagsIds;
};

export const ReportingMain = () => {
  const { t } = useTranslation();

  const maxInstances = INSTANCE_STEP_SIZE;

  const {
    searchParams,
    state: { instanceIds, taskInstanceIds },
  } = useRouting<{
    instanceIds: string[];
    taskInstanceIds?: string[];
  }>();

  const goBack = () => {
    searchParams.clear();
  };

  if (!instanceIds?.length && !taskInstanceIds?.length) {
    goBack();
  }

  const { instances, currentUser, project } = useReportData(instanceIds ?? []);

  const {
    data: { tasksBatchInclude: taskInstances } = { tasksBatchInclude: [] },
  } = useTasksBatchIncludeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !taskInstanceIds,
    variables: {
      ids: taskInstanceIds ?? [],
      includeAssignment: true,
      includeCategory: true,
      includeCompletedAt: true,
      includeCreatedAt: true,
      includeCreatedBy: true,
      includeDescription: true,
      includeEndtime: true,
      includeFormTemplates: true,
      includeLinkedFormInstances: true,
      includeLinkedSnagInstances: true,
      includeName: true,
      includeSnagTemplates: true,
      includeStatus: true,
      includeVisualContexts: true,
    },
  });

  const linkedSnagsIds = getLinkedSnagsIds(instances);
  const { instances: linkedInstances } = useReportData(linkedSnagsIds ?? []);

  const allInstances = instances.concat(linkedInstances);

  const finalInstances = allInstances.reduce((accumulator, current) => {
    if (!accumulator.find((item) => item.id === current.id)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);

  const mapInstances =
    taskInstances && taskInstances.length > 0
      ? [
          ...(taskInstances?.map((task) => task.linkedSnagInstances) || []),
          ...(taskInstances?.map((task) => task.linkedFormInstances) || []),
        ].flat()
      : finalInstances.filter(
          (instance) => instance.visualContextId && instance.x && instance.y
        );

  const previewInstances = (
    taskInstanceIds
      ? taskInstances?.flatMap((inst) => inst.linkedSnagInstances)
      : finalInstances
  )?.slice(0, maxInstances) as QualityControlInstance[];

  const snagInstances = (previewInstances || []).filter(
    (instance) =>
      (Boolean(instance?.templateId) &&
        instance?.type === QualityControlInstanceType.Snag) ||
      instance?.type === QualityControlInstanceType.FreeSnag
  ) as StrictInstanceType[];

  const formInstances = (previewInstances || []).filter(
    (instance) =>
      Boolean(instance?.templateId) &&
      instance?.type === QualityControlInstanceType.Form
  ) as StrictInstanceType[];

  const formTemplateIds = [
    ...new Set(formInstances.map<string>((instance) => instance.templateId)),
  ];

  const snagTemplateIds = [
    ...new Set(snagInstances.map<string>((instance) => instance.templateId)),
  ];

  const snagTemplateOptions = snagTemplateIds
    .map((templateId) => ({
      id: templateId,
      label: previewInstances?.find(
        (instance) => instance?.templateId === templateId
      )?.name,
    }))
    .filter((option) => Boolean(option.label)) as Option[];

  const formTemplateOptions = formTemplateIds
    .map((templateId) => ({
      id: templateId,
      label: previewInstances?.find(
        (instance) => instance?.templateId === templateId
      )?.name,
    }))
    .filter((option) => Boolean(option.label)) as Option[];

  const date = new Date();

  if (
    (!previewInstances?.length && !taskInstances?.length) ||
    !project ||
    !currentUser ||
    !date
  )
    return <Loader />;

  const reportPreview = () => (
    <ReportPreview
      currentUser={currentUser}
      date={date}
      instances={finalInstances}
      instancesGroupedByFloorplan={sortGroupedInstances(
        groupInstancesByFloorplan(finalInstances)
      )}
      mapInstances={mapInstances}
      project={project}
      tasks={taskInstances}
    />
  );

  return (
    <ReportOptionsContextProvider>
      <Main>
        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <Box
            sx={{
              alignSelf: 'stretch',
              display: 'flex',
              flex: 'auto',
              flexDirection: 'column',
            }}
          >
            <ActionBar>
              <BackButton goBack={goBack} />
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                <PrintButton />
                <DownloadReportButton />
              </Box>
            </ActionBar>
            <ReportMainContainer>{reportPreview()}</ReportMainContainer>
          </Box>
          <EditReportLayout>
            <Box
              sx={{ display: 'flex', flexDirection: 'row', gap: 1, padding: 3 }}
            >
              <Icon iconName="edit"></Icon>
              <Typography variant="h6">{t('Edit report layout')}</Typography>
            </Box>
            <ReportOptionsForm
              formTemplateOptions={formTemplateOptions}
              previewInstances={previewInstances}
              snagTemplateOptions={snagTemplateOptions}
            />
          </EditReportLayout>
        </Box>
      </Main>
    </ReportOptionsContextProvider>
  );
};
