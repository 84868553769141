import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  Connection,
  Project,
  ProjectSettings,
  useProjectQuery,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { StorageKeys } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { AppPermission } from '@pro4all/shared/types';

export enum ProjectAccessType {
  Loading = 'Loading',
  NoAccess = 'NoAccess',
  ProjectRead = 'ProjectRead',
  ProjectUpdate = 'ProjectUpdate',
}

type ProjectAccessLoadStatus = {
  projectId: string;
  status: ProjectAccessType;
};

export type DocumentToCopy = {
  extension?: string;
  id: string;
  name: string;
};

type ProjectContextValue = {
  connections: Connection[];
  isExplicitProjectAdmin: boolean;
  isExplicitProjectUser: boolean;
  isExternalUserInProject: boolean;
  mainProcedureId: string;
  projectAccessLoadStatus: ProjectAccessLoadStatus;
  projectData: Project;
  projectName: string;
  projectOrganizationId: string;
  projectOrganizationName: string;
  setConnections: React.Dispatch<React.SetStateAction<Connection[]>>;
  setMainProcedureId: React.Dispatch<React.SetStateAction<string>>;
  setProjectAccessLoadStatus: React.Dispatch<
    React.SetStateAction<ProjectAccessLoadStatus>
  >;
  setProjectData: React.Dispatch<React.SetStateAction<Project>>;
  setSettings: React.Dispatch<React.SetStateAction<ProjectSettings>>;
  settings: ProjectSettings;
};

const ProjectContext = React.createContext<ProjectContextValue>({
  connections: [],
  isExplicitProjectAdmin: false,
  isExplicitProjectUser: false,
  isExternalUserInProject: false,
  mainProcedureId: '',
  projectAccessLoadStatus: { projectId: '', status: ProjectAccessType.Loading },
  projectData: { id: '', name: '', organizationId: '', projectNumber: '' },
  projectName: '',
  projectOrganizationId: '',
  projectOrganizationName: '',
  setConnections: () => ({}),
  setMainProcedureId: () => '',
  setProjectAccessLoadStatus: () => ({}),
  setProjectData: () => ({}),
  setSettings: () => ({}),
  settings: {},
});

export function useProjectContext() {
  return useContext(ProjectContext);
}

export const ProjectContextProvider: React.FC = ({ children }) => {
  const [projectData, setProjectData] = useState<Project>();
  const [settings, setSettings] = useState<ProjectSettings>({});
  const [connections, setConnections] = useState<Connection[]>([]);
  const [mainProcedureId, setMainProcedureId] = useState<string>('');
  const [projectAccessLoadStatus, setProjectAccessLoadStatus] =
    useState<ProjectAccessLoadStatus>();
  const {
    params: { projectId },
  } = useRouting();
  const { projectsMe, userOrganizationId } = useOrganizationContext();
  const projectMeData = projectsMe?.find(
    (project) => project?.projectId === projectId
  );
  const projectOrganizationId = projectMeData?.organizationId;
  const projectOrganizationName = projectMeData?.organizationName;

  const isExternalUserInProject = !projectOrganizationId
    ? false
    : projectOrganizationId !== userOrganizationId;

  const { roles } = projectMeData || {};
  const scopedPermissionsProject = new Set(
    roles?.flatMap((role) => role?.permissions)
  );
  const isExplicitProjectAdmin =
    roles &&
    roles.length &&
    scopedPermissionsProject?.has(AppPermission.ProjectUpdate)
      ? true
      : false;
  const isExplicitProjectUser =
    roles &&
    roles.length &&
    scopedPermissionsProject?.has(AppPermission.ProjectRead)
      ? true
      : false;

  useEffect(() => {
    sessionStorage.setItem(StorageKeys.PROJECT_ID, projectId || '');
    sessionStorage.setItem(
      StorageKeys.PROJECT_ORGANIZATION_ID,
      projectOrganizationId || ''
    );
    return () => {
      // This code will be executed on unmounting.
      sessionStorage.removeItem(StorageKeys.PROJECT_ID);
      sessionStorage.removeItem(StorageKeys.PROJECT_ORGANIZATION_ID);
    };
  }, [projectId, projectOrganizationId]);

  const { data } = useProjectQuery({
    skip: !projectId,
    variables: { projectId: projectId || '' },
  });
  const projectName = data?.project?.name || '';

  const projectContextValue = useMemo(
    () => ({
      connections,
      isExplicitProjectAdmin,
      isExplicitProjectUser,
      isExternalUserInProject,
      mainProcedureId,
      projectAccessLoadStatus,
      projectData,
      projectName,
      projectOrganizationId,
      projectOrganizationName,
      setConnections,
      setMainProcedureId,
      setProjectAccessLoadStatus,
      setProjectData,
      setSettings,
      settings,
    }),
    [
      connections,
      isExternalUserInProject,
      isExplicitProjectAdmin,
      isExplicitProjectUser,
      mainProcedureId,
      projectAccessLoadStatus,
      projectData,
      projectName,
      projectOrganizationId,
      projectOrganizationName,
      setConnections,
      setMainProcedureId,
      setProjectData,
      setProjectAccessLoadStatus,
      setSettings,
      settings,
    ]
  );

  return (
    <ProjectContext.Provider value={projectContextValue}>
      {children}
    </ProjectContext.Provider>
  );
};
