import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import { ReportConfigTypes, ReportOptions } from '@pro4all/shared/types';
import { FormWrapper } from '@pro4all/shared/ui/wrappers';

import { CustomOptionsTabs } from '../components/CustomOptionsTabs';
import { useReportOptionsContext } from '../ReportOptionsContext';

import { FrontpageCustomOptions } from './frontpage-custom-options/FrontpageCustomOptions';
import { GeneralCustomOptions } from './general-custom-options/GeneralCustomOptions';
import { InstanceCustomOptionsForm } from './instance-custom-options/InstanceCustomOptionsForm';
import { InstanceCustomOptionsSnag } from './instance-custom-options/InstanceCustomOptionsSnag';

export const CustomOptions = ({
  snagTemplateOptions,
  previewInstances,
  formTemplateOptions,
}: Pick<
  ReportConfigTypes,
  'formTemplateOptions' | 'previewInstances' | 'snagTemplateOptions'
>) => {
  const { values } = useFormikContext<ReportOptions>();
  const [currentTab, setCurrentTab] = useState('general');

  const context = useReportOptionsContext();
  if (!context) throw new Error('ReportOptionsContext not available');
  const { setReportOptions } = context;

  const debouncedUpdate = useDebouncedCallback(
    (values: ReportOptions) => setReportOptions(values),
    350
  );

  // Hacking in onChange because formik doesn't support it
  useEffect(() => {
    debouncedUpdate(values);
  }, [values, debouncedUpdate]);

  return (
    <FormWrapper noPadding>
      <CustomOptionsTabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {currentTab === 'general' && <GeneralCustomOptions />}
      {currentTab === 'frontpage' && <FrontpageCustomOptions />}
      {currentTab === 'snag' && (
        <InstanceCustomOptionsSnag
          previewInstances={previewInstances}
          templateOptions={snagTemplateOptions}
        />
      )}
      {currentTab === 'form' && (
        <InstanceCustomOptionsForm
          previewInstances={previewInstances}
          templateOptions={formTemplateOptions}
        />
      )}
    </FormWrapper>
  );
};
