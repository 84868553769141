import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import { TemplateService, TemplateType } from '@pro4all/graphql';
import { useTemplatesInclude } from '@pro4all/metadata/data-access';
import { Routes } from '@pro4all/shared/config';

import { TemplateFrame } from '../components/form-template/TemplateFrame';

import { MetaDataContextProvider } from './MetaDataContext';
import { MetaDataMain } from './MetaDataMain';

export const OrganizationQualityControl = () => {
  const routeForms = useRouteMatch(Routes.metaDataQualityControlForms);
  const routeSnags = useRouteMatch(Routes.metaDataQualityControlSnags);
  const routeTemplates = useRouteMatch(Routes.metaDataQualityControlTemplates);
  const routeItems = useRouteMatch(Routes.metaDataQualityControlFields);

  const templateService = TemplateService.QualityControl;
  const templateType = routeForms
    ? TemplateType.Form
    : routeSnags
    ? TemplateType.Snag
    : routeTemplates
    ? TemplateType.Section
    : routeItems
    ? TemplateType.Field
    : undefined;

  const qualityControlRoutesTemplates = [
    Routes.metaDataQualityControlForms,
    Routes.metaDataQualityControlTemplates,
    Routes.metaDataQualityControlSnags,
  ];

  const qualityControlRoutesItems = [Routes.metaDataQualityControlFields];

  const { selectedTemplate } = useTemplatesInclude({
    includeScope: true,
    templateService,
    templateType,
  });

  return (
    <MetaDataContextProvider
      templateService={templateService}
      templateType={templateType}
    >
      <Route exact path={Routes.metaDataQualityControl}>
        <Redirect to={Routes.metaDataQualityControlForms} />
      </Route>
      <Route
        exact
        path={[...qualityControlRoutesTemplates, ...qualityControlRoutesItems]}
        render={({ location }) => {
          const searchParams = new URLSearchParams(location.search);
          return searchParams.get('action') !== 'createTemplate' &&
            searchParams.get('action') !== 'editSet' ? (
            <MetaDataMain
              base={`${Routes.metaDataQualityControl}/`}
              pathFields={qualityControlRoutesItems}
              pathTemplates={qualityControlRoutesTemplates}
            />
          ) : (
            <TemplateFrame selectedTemplate={selectedTemplate} />
          );
        }}
      />
    </MetaDataContextProvider>
  );
};
