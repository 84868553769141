import styled, { css } from 'styled-components';

import { customColors } from '@pro4all/shared/themes';
import { LayoutProps } from '@pro4all/shared/types';
import { Icon as DefaultIcon } from '@pro4all/shared/ui/icons';

import { Section } from './GeneralElements';

export const Icon = styled(DefaultIcon)`
  padding: 1pt;
  color: #212121;
`;

export const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  border: 1px solid ${customColors.grey300};
  border-radius: 4px 0 0 4px;
  margin-bottom: 8pt;
  overflow: hidden;
`;

export const Title = styled.div`
  font-size: 12pt;
  margin: 0;
  margin-bottom: 4pt;
  min-height: 29pt;
  display: flex;
  order: 1;
  flex-grow: 1;
  font-weight: 500;
  font-style: normal;
  align-items: center;

  > ${Icon} {
    width: 29pt;

    > svg {
      display: inline-flex;
      margin: auto;
      font-size: 24pt;
      width: 24pt;
      padding-left: 4pt;
    }
  }
`;

export const Label = styled.dt<LayoutProps>`
  ${({ inlineLists = true }) =>
    !inlineLists &&
    css`
      --label-width: 100%;
    `}
  flex: 0 0 var(--label-width);
`;

export const Value = styled.dd<LayoutProps>`
  ${({ inlineLists = true }) =>
    !inlineLists &&
    css`
      --value-width: 100%;
    `}
  flex: 2;
  color: #585858;
`;

export const MediaLabel = styled.p`
  font-weight: 500;
`;

export const Item = styled.div<LayoutProps>`
  page-break-inside: ${({ preventPageBreak }) =>
    preventPageBreak ? 'avoid' : 'auto'};

  ${Label} {
    font-weight: 500;
    font-size: var(--font-size);
    line-height: var(--line-height);
    padding: var(--padding-top) 0 var(--padding-bottom) var(--padding-left);
  }

  ${Value}, p {
    font-weight: normal;
    font-size: var(--font-size);
    line-height: var(--line-height);
    padding: var(--padding-top) 0 var(--padding-bottom) var(--padding-left);
    margin: 0;
  }
`;

export const LinkedItemsTitle = styled.div`
  padding: var(--padding-top) var(--padding-right) var(--padding-bottom)
    var(--padding-left);
  font-weight: normal;
  font-size: var(--font-size-sm);
`;

export const LinkedItemsList = styled.tr<LayoutProps>`
  font-weight: normal;
  font-size: var(--font-size-sm);
  line-height: var(--line-height);
  padding-bottom: var(--padding-bottom);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  padding-top: var(--padding-top);
`;

// Theme does not work here
export const LinkedItemsListProperty = styled.td`
  padding-left: var(--padding-left);
  font-weight: normal;
  font-size: var(--font-size-sm);
`;

export const List = styled.dl<LayoutProps>`
  --line-height: ${({ compactLists }) => (compactLists ? '10pt' : '12pt')};
  --font-size-sm: 8pt;
  --padding-top: ${({ compactLists, inlineLists }) =>
    inlineLists ? (compactLists ? '2mm' : '3mm') : '2pt'};
  --padding-right: ${({ compactLists }) => (compactLists ? '2mm' : '3mm')};
  --padding-bottom: ${({ compactLists, inlineLists }) =>
    inlineLists ? (compactLists ? '2mm' : '3mm') : '2pt'};
  --padding-left: ${({ compactLists }) => (compactLists ? '2mm' : '3mm')};

  --label-width: ${({ labelWidth = 30 }) => `${labelWidth}%`};
  --value-width: ${({ labelWidth = 30 }) => `${100 - labelWidth}%`};

  width: 100%;
  margin: 0;
  columns: ${({ columns = 1 }) => columns};

  ${Section} {
    font-weight: 500;
    line-height: var(--line-height);
    margin: var(--padding-top) 0 var(--padding-bottom) 0;
  }

  ${Item} {
    ${({ inlineLists }) =>
      inlineLists &&
      css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      `}
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const SelectionOuter = styled.ul<{ inline?: boolean }>`
  ${({ inline }) =>
    inline &&
    css`
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline;
        list-style: none;

        :not(:last-child)::after {
          content: ', ';
        }
      }
    `}
`;

export const SelectionInner = styled.div`
  border: solid 1px;
  border-color: #cdf3ed;
  background-color: #cdf3ed;
  color: #006b5e;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 4px;
  gap: 4px;
  border-radius: 2px;
`;

export const Status = styled.div<{ background: string; color: string }>`
  --color: ${({ color }) => color};
  --background: ${({ background }) => background};
  display: inline-block;
  text-align: center;
`;
