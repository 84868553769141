import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { QualityControlInstance } from '@pro4all/graphql';
import { usePersistentState } from '@pro4all/shared/hooks';
import { useRouting } from '@pro4all/shared/routing-utils';
import { customColors } from '@pro4all/shared/themes';
import { IconButton } from '@pro4all/shared/ui/buttons';
import { useOptimisticResponseContext } from '@pro4all/shared/ui/general';
import { Tooltip } from '@pro4all/shared/ui/tooltip';

import { ErrorDialog } from '../FloorPlanResults';
import { DirectionSearchNavigation } from '../FloorPlanSearchResults';

interface Props {
  pageCount: number;
  searchNavigation?: (dir: DirectionSearchNavigation) => void;
  setErrorDialog: Dispatch<SetStateAction<ErrorDialog>>;
  visualContextId: string;
}

const Paginator = ({
  pageCount,
  setErrorDialog,
  visualContextId,
  searchNavigation,
}: Props) => {
  const { t } = useTranslation();
  const { searchParams } = useRouting();
  const pageParam = Number(searchParams.get('page'));
  const [persistentPage, setPersistenPage] = usePersistentState(
    visualContextId,
    pageParam === 0 ? 1 : pageParam
  );
  const setDefaultInputState = () => {
    if (pageParam > 0) return pageParam;
    else if (persistentPage > 0) return persistentPage;
    else return 1;
  };

  const [inputElementState, setInputElementState] = useState<number>(
    setDefaultInputState()
  );

  useEffect(() => {
    if (pageParam === 0 && persistentPage > 0) {
      searchParams.set({ page: persistentPage });
    } else {
      setPersistenPage(pageParam);
    }
    setInputElementState(pageParam);
  }, [pageParam, persistentPage]);

  const { replaceAllItems } =
    useOptimisticResponseContext<QualityControlInstance>();

  const navigateWithArrow = (pageChange: 1 | -1) => {
    const direction =
      pageChange === 1
        ? DirectionSearchNavigation.Next
        : DirectionSearchNavigation.Prev;
    searchNavigation?.(direction);
    replaceAllItems([]);
    setInputElementState((prev) => prev + pageChange);
    if (pageChange === 1 && pageParam === 0) {
      searchParams.set({ action: '', id: '', page: pageParam + 2 });
    } else
      searchParams.set({ action: '', id: '', page: pageParam + pageChange });
  };

  const navigateWithInput = useCallback(() => {
    const emptyInput = !inputElementState;
    const pageTooHigh = inputElementState > pageCount;
    if (pageTooHigh || emptyInput) {
      setErrorDialog({ enteredPage: inputElementState });
      setInputElementState(pageParam);
    } else {
      replaceAllItems([]);
      searchParams.set({ page: inputElementState });
    }
  }, [inputElementState, pageParam, pageCount, searchParams, setErrorDialog]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        navigateWithInput();
      }
    };
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [navigateWithInput]);

  return (
    <Container>
      <Tooltip placement="top" title={t('Show previous page')}>
        <IconButton
          color="default"
          disabled={pageParam < 2}
          iconName="arrowBack"
          onClick={() => navigateWithArrow(-1)}
        />
      </Tooltip>
      <Inner>
        Page
        <InputContainer>
          <PageInput
            onBlur={() => navigateWithInput()}
            onChange={(e) =>
              setInputElementState(() => Number(e.target.value) || null)
            }
            value={inputElementState}
          />
        </InputContainer>
        {`of ${pageCount}`}
      </Inner>
      <Tooltip placement="top" title={t('Show next page')}>
        <IconButton
          color="default"
          disabled={pageParam >= pageCount}
          iconName="arrowForward"
          onClick={() => navigateWithArrow(1)}
        />
      </Tooltip>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  align-items: center;
  margin: auto;
  padding: ${({ theme }) => theme.spacing(2)} 0;
`;

const Inner = styled('div')`
  display: flex;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing(2.66)};
  color: ${({ theme }) => theme.palette.text.primary};
`;

const InputContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: solid 1px ${customColors.grey500};
  width: 30px;
  height: 32px;
  margin: 0 ${({ theme }) => theme.spacing(1.33)};
`;

const PageInput = styled('input')`
  border: none;
  text-align: center;
  width: 90%;
  height: 90%;
  outline: none;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export default Paginator;
