import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormControlLabel } from '@pro4all/shared/mui-wrappers';
import { Checkbox, SearchableSelect } from '@pro4all/shared/ui/general';

import { useReportOptionsContext } from '../ReportOptionsContext';

export const StylePresetSelect: React.FC = () => {
  const { t } = useTranslation();

  const context = useReportOptionsContext();
  if (!context) throw new Error('ReportOptionsContext not available');
  const {
    customMode,
    selectStyleTemplate,
    selectedStyleTemplate,
    styleTemplateOptions,
    toggleCustomMode,
  } = context;

  return (
    <>
      <SearchableSelect
        label={t('Template')}
        name="snag-template-select"
        onChange={(values) => selectStyleTemplate(values)}
        options={styleTemplateOptions}
        value={selectedStyleTemplate}
      />
      <FormControlLabel
        control={
          <Checkbox checked={customMode} onChange={() => toggleCustomMode()} />
        }
        label={t('Customize')}
      />
    </>
  );
};
