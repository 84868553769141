import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { VisualContext } from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@pro4all/shared/mui-wrappers';
import { StylingDefaults } from '@pro4all/shared/themes';
import { Button } from '@pro4all/shared/ui/buttons';
import { useTableCheck } from '@pro4all/shared/ui/general';
import { Footer } from '@pro4all/shared/ui/general';

import { DeleteVariant } from './types';

interface Props {
  deleteFloorplans: (arg0: VisualContext[]) => void;
  deleteVariant: DeleteVariant;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
  showDialog: boolean;
}

export const FloorPlansDeleteDialog: React.FC<Props> = ({
  deleteFloorplans,
  deleteVariant,
  setShowDialog,
  showDialog,
}) => {
  const { t } = useTranslation();
  const { checkedRows } = useTableCheck<VisualContext>();
  const { userId } = useOrganizationContext();

  return (
    <DialogContainer
      onClose={() => setShowDialog(false)}
      open={showDialog}
      scroll="paper"
    >
      <DialogTitle>{t('Floorplans deletion confirmation')}</DialogTitle>
      <DialogContents style={{ overflowX: 'hidden' }}>
        <Box paddingTop={1}>
          <>
            {deleteVariant === DeleteVariant.DELETE_NONE && (
              <>
                <DialogSubHeader>{t('No permissions')}</DialogSubHeader>
                <p>
                  {`${t(
                    "You don't have the permission to delete floorplans you don't own. The following floorplans won't be deleted"
                  )}:`}{' '}
                </p>
                <ul>
                  {checkedRows.map((fp, index) => (
                    <li key={index}>{fp.name}</li>
                  ))}
                </ul>
              </>
            )}

            {deleteVariant === DeleteVariant.DELETE_ONLY_OWN && (
              <>
                <DialogSubHeader>
                  {t('Only your own floorplan(s) will be deleted')}
                </DialogSubHeader>
                <p>
                  {t(
                    "You are trying to delete floorplans you don't own. You don't have the right permissions to do so."
                  )}
                </p>
                <p>
                  <b>{`${t("The following floorplans won't be deleted")}:`}</b>
                </p>
                <ul>
                  {checkedRows
                    .filter((row) =>
                      row.createdBy ? row.createdBy.id !== userId : false
                    )
                    .map((fp, index) => (
                      <li key={index}>{fp.name}</li>
                    ))}
                </ul>
                <p>
                  <b>{`${t(
                    "If you continue, the following floorplans will be deleted (this action can't be undone)"
                  )}:`}</b>
                </p>
                <ul>
                  {checkedRows
                    .filter((row) =>
                      row.createdBy ? row.createdBy.id === userId : false
                    )
                    .map((fp, index) => (
                      <li key={index}>{fp.name}</li>
                    ))}
                </ul>
              </>
            )}

            {deleteVariant === DeleteVariant.DELETE_SELECTED && (
              <>
                <DialogSubHeader>{t('Confirm deletion')}</DialogSubHeader>
                <p>
                  {`${t(
                    "If you continue, the following floorplans will be deleted (this action can't be undone)"
                  )}:`}
                </p>
                {checkedRows.map((fp, index) => (
                  <li key={index}>{fp.name}</li>
                ))}
              </>
            )}

            {deleteVariant === DeleteVariant.DELETE_ALL && (
              <>
                <DialogSubHeader>{t('Confirm deletion')}</DialogSubHeader>
                <p>
                  {t(
                    "If you proceed, ALL floorplans will be deleted (this action can't be undone)."
                  )}
                </p>
              </>
            )}
          </>
        </Box>
      </DialogContents>
      <DialogActions>
        <Footer pb={3} pt={2} px={3}>
          <Button color="inherit" onClick={() => setShowDialog(false)}>
            {t(
              deleteVariant === DeleteVariant.DELETE_NONE ? 'Close' : 'Cancel'
            )}
          </Button>

          {deleteVariant !== DeleteVariant.DELETE_NONE && (
            <Button
              onClick={() => {
                deleteFloorplans(
                  deleteVariant === DeleteVariant.DELETE_ONLY_OWN
                    ? checkedRows.filter((row) =>
                        row.createdBy ? row.createdBy.id === userId : false
                      )
                    : checkedRows
                );
              }}
              startIcon="delete"
              type="submit"
              variant="contained"
            >
              {t('Confirm')}
            </Button>
          )}
        </Footer>
      </DialogActions>
    </DialogContainer>
  );
};

const DialogContents = styled(DialogContent)`
  && {
    overflow-x: hidden;
  }
`;

const DialogContainer = styled(Dialog)`
  && {
    .MuiPaper-root {
      padding: ${({ theme }) => theme.spacing(4)};
      border-radius: ${StylingDefaults.borderRadius};
      box-shadow: ${StylingDefaults.boxShadow};
      min-width: 10vw;
      max-width: 40vw;
      min-height: 10vh;
      max-height: 90vh;
    }

    .MuiBackdrop-root {
      background-color: ${StylingDefaults.backdropColor};
    }
  }
`;

const DialogSubHeader = styled.h3`
  margin-top: ${({ theme }) => theme.spacing(6)};
`;
