import React, {
  createContext,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

interface QCTBQContextValue {
  objectProjectId: string;
  setObjectProjectId: React.Dispatch<SetStateAction<string>>;
}

export const QCTBQContext = createContext({} as QCTBQContextValue);

export const useQCTBQContext = () =>
  useContext<QCTBQContextValue>(QCTBQContext) || {
    objectProjectId: null,
    setObjectProjectId: () => '',
  };

export const QCTBQContextProvider: React.FC = ({ children }) => {
  const [objectProjectId, setObjectProjectId] = useState('');

  const value = useMemo(
    () => ({
      objectProjectId,
      setObjectProjectId,
    }),
    [objectProjectId, setObjectProjectId]
  );

  return (
    <QCTBQContext.Provider value={value}>{children}</QCTBQContext.Provider>
  );
};
