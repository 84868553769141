import { FilterType } from '@pro4all/graphql';

export const excludedFilterTypes = [
  FilterType.ParentFolderIds,
  FilterType.FolderId,
];

export const folderFilterTypes = [
  FilterType.ParentFolderIds,
  FilterType.FolderId,
];

export const standardFilterTypes = [
  FilterType.CreatedBy,
  FilterType.CreatedAt,
  FilterType.Extension,
];

export const standardQCFilterTypes = [
  FilterType.CreatedBy,
  FilterType.CreatedAt,
  FilterType.Floorplan,
  FilterType.Template,
  FilterType.UpdatedBy,
  FilterType.UpdatedAt,
  FilterType.Highlight,
  FilterType.TemplateType,
];

export const drawingQCFilterTypes = [
  FilterType.CreatedBy,
  FilterType.CreatedAt,
  FilterType.Template,
  FilterType.UpdatedBy,
  FilterType.UpdatedAt,
  FilterType.Highlight,
  FilterType.TemplateType,
];

export const mdFilterTypes = [
  FilterType.Bool,
  FilterType.DateTime,
  FilterType.Number,
  FilterType.Status,
  FilterType.Selection,
  FilterType.Text,
];

export const mdFilterColumnTypes = [
  FilterType.Bool,
  FilterType.DateTime,
  FilterType.Number,
  FilterType.Status,
  FilterType.Selection,
  FilterType.Text,
  FilterType.UserSelection,
];

export const snagsAndFormsFilterTypes = [FilterType.Snags, FilterType.Forms];
