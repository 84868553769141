import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { getAnswer } from '@pro4all/documents/data-access';
import { useFolderTreeContextInner } from '@pro4all/documents/ui/folders';
import {
  Document,
  FieldDefinition,
  Folder,
  StandardItemKey,
  ValueTypeName,
} from '@pro4all/graphql';
import { AnswerView } from '@pro4all/metadata/ui';
import { Routes } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { DocTablePropertyKeys } from '@pro4all/shared/types';
import { FilterHeaderType } from '@pro4all/shared/ui/filtering';
import {
  ColumnProps,
  ColumnSizes,
  FilePath,
  getFormattedDate,
} from '@pro4all/shared/ui/general';
import {
  FilterHeader,
  useMetaDataHeader,
} from '@pro4all/shared/ui/table-column-filtering';

import { DocumentType, useStandardColumns } from './useStandardColumns';

export const useColumns = ({
  folder,
  onColumnResizeCallback,
  isForVersionTable,
}: {
  folder?: Folder;
  isForVersionTable?: boolean;
  onColumnResizeCallback?: (value: ColumnSizes) => void;
}): ColumnProps<DocumentType>[] => {
  const { t } = useTranslation();

  const { setExpandedRowKeys } = useFolderTreeContextInner();

  const hasSnagstreamFeatureFlag = useFeatureFlag('snagstream-integration');

  const getColumn = useStandardColumns({
    folder,
    isForVersionTable,
    onColumnResizeCallback,
  });
  const { params } = useRouting();
  const { projectId } = params;
  const isCollection = useRouteMatch([
    Routes.collectionFolder,
    Routes.projectCollectionFolder,
  ]);
  const isSmartFolder = useRouteMatch([
    Routes.smartFolder,
    Routes.projectSmartFolder,
  ]);

  const getMetaDataHeader = useMetaDataHeader<FieldDefinition>();

  const mainColumns: ColumnProps<DocumentType>[] = [
    getColumn({ name: StandardItemKey.Name, pullFromLocalStorage: true }),
    getColumn({ name: StandardItemKey.Extension }),
    getColumn({ name: StandardItemKey.Version }),
    getColumn({ name: StandardItemKey.UpdatedOn }),
    getColumn({ name: StandardItemKey.UpdatedBy }),
    getColumn({ name: StandardItemKey.Qr }),
    getColumn({ name: StandardItemKey.Approved }),
    getColumn({ name: StandardItemKey.ThreeD }),
    getColumn({ name: StandardItemKey.Finalized }),
    getColumn({ name: StandardItemKey.Comments }),
    getColumn({ name: StandardItemKey.Tags }),
  ];

  const PathColumn: ColumnProps<DocumentType> = {
    filterable: true,
    getValue: ({ path }: Document) => path,
    headerComponent: (
      <FilterHeader<Document, {}>
        defaultWidth={200}
        filterType={FilterHeaderType.Text}
        label="Location" // i18n
        minWidth={100}
        propertyId={DocTablePropertyKeys.Path}
      />
    ),
    key: DocTablePropertyKeys.Path,
    minWidth: 80,
    render: (doc: Document) => {
      const { path, pathIds, id } = doc;
      return (
        <FilePath
          id={id}
          path={path || ''}
          pathIds={pathIds}
          setExpandedRowKeys={setExpandedRowKeys}
        />
      );
    },
    width: 200,
  };

  if (isCollection || isSmartFolder) {
    mainColumns.splice(
      Math.max(
        mainColumns.findIndex(
          (column) => column.key === DocTablePropertyKeys.UpdatedBy
        ),
        0
      ) + 2,
      0,
      PathColumn
    );
  }

  if (projectId) {
    mainColumns.splice(
      mainColumns.length - 1,
      0,
      getColumn({ name: StandardItemKey.Build12 })
    );

    if (hasSnagstreamFeatureFlag) {
      mainColumns.splice(
        mainColumns.length - 1,
        0,
        getColumn({ name: StandardItemKey.Snagstream })
      );
    }
  }

  const fields = folder?.template?.fields || [];

  // Previously the `Name` column contained the important prop 'pullFromLocalStorage' and was always included in the documents table.
  // However, this is not the case anymore, so now it can be that none of the columns have this prop.
  // This causes the table not be able to render on filter data from local storage.
  // So now we add the `pullFromLocalStorage` prop to the config of the first column dynamically.
  let pullFromLocalStoragePropAdded = false;

  const metaDataColumns: ColumnProps<DocumentType>[] =
    fields.map((item) => {
      if (item.type === ValueTypeName.StandardItem) {
        // Standard item.
        const { name } = item;
        const nameStandardFieldDefinition = name as StandardItemKey;
        const column = getColumn({
          name: nameStandardFieldDefinition,
          pullFromLocalStorage: !pullFromLocalStoragePropAdded,
        });
        pullFromLocalStoragePropAdded = true;
        return column;
      } else {
        // Meta data item.
        const column = {
          filterable: true,
          getValue: (document: Document) => {
            const answer = getAnswer(document, item);
            if (item.type === ValueTypeName.Bool)
              return answer ? t(answer) : '';
            if (item.type === ValueTypeName.DateTime)
              return answer ? getFormattedDate(answer).label : '';
            return answer;
          },
          headerComponent: getMetaDataHeader({
            fieldDefinition: item,
            idProp: 'fieldDefinitionId',
            idPropFallback: 'id',
            multiSelectProp: 'multiSelect',
            nameProp: 'name',
            onColumnResizeCallback,
            pullFromLocalStorage: !pullFromLocalStoragePropAdded,
            typeProp: 'type',
            valueTypeProp: 'valueType',
          }),
          key: `metaData.${item.fieldDefinitionId || item.id}.answers`,
          render: (document: Document) => (
            <AnswerView
              item={item}
              tagHasMargin={false}
              value={getAnswer(document, item)}
            />
          ),
          width: 200,
        };
        pullFromLocalStoragePropAdded = true;
        return column;
      }
    }) || [];

  return !folder?.template ? [...mainColumns] : [...metaDataColumns];
};
