import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';

import {
  ImageType,
  useReportBackgroundsQuery,
  useReportImageUrlLazyQuery,
} from '@pro4all/graphql';
import {
  DEFAULT_BACKGROUND_URL,
  IMAGE_POLLING_TIMEOUT,
} from '@pro4all/quality-control/ui/report';
import { ApiConfig } from '@pro4all/shared/config';
import { Option, ReportOptions } from '@pro4all/shared/types';
import { SearchableSelectProps } from '@pro4all/shared/ui/general';
import { sortBy } from '@pro4all/shared/utils';

import { ImageUpload } from '../../components';
import { useReportOptionsContext } from '../../ReportOptionsContext';

export const BackgroundField = ({
  isFrontPageProp = false,
}: {
  isFrontPageProp?: boolean;
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const fieldName = isFrontPageProp ? 'backgroundFrontPage' : 'background';

  const {
    data: bgData,
    startPolling,
    stopPolling,
  } = useReportBackgroundsQuery();
  const [getImageUrl] = useReportImageUrlLazyQuery();

  const queriedBgOptions = bgData?.reportBackgrounds?.map((background) => ({
    id: background.id,
    label: decodeURI(background.name),
  }));

  const { setFieldValue } = useFormikContext<ReportOptions>();

  const [queuedBgId, queueBgId] = useState<string | null>();

  const defaultBgOption = useMemo(
    () => ({
      id: 'default',
      label: 'Prostream background (default)',
    }),
    []
  );

  const bgOptions: Option[] = useMemo(
    () =>
      queriedBgOptions
        ? [defaultBgOption, ...queriedBgOptions.sort(sortBy({ key: 'label' }))]
        : [defaultBgOption],
    [defaultBgOption, queriedBgOptions]
  );

  const context = useReportOptionsContext();
  if (!context) throw new Error('ReportOptionsContext not available');
  const { bgId, bgIdFrontPage, setBackground, setBackgroundFrontPage } =
    context;

  const selectedId = isFrontPageProp ? bgIdFrontPage : bgId;

  const selectBgOption = useCallback(
    async ({ id }: Option) => {
      if (isFrontPageProp) {
        setBackgroundFrontPage(id);
      } else {
        setBackground(id);
      }
      if (id === 'default') setFieldValue(fieldName, DEFAULT_BACKGROUND_URL);
      else {
        const { data } = await getImageUrl({
          variables: { id },
        });
        data?.reportImageUrl && setFieldValue(fieldName, data?.reportImageUrl);
      }
    },
    [
      fieldName,
      getImageUrl,
      isFrontPageProp,
      setBackground,
      setBackgroundFrontPage,
      setFieldValue,
    ]
  );

  const onChange = async (value: SearchableSelectProps['value']) => {
    if (!value || typeof value === 'string') return;
    await selectBgOption({ id: value.id, label: value.label });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (queuedBgId) {
        stopPolling();
        enqueueSnackbar(t('Something went wrong'));
        queueBgId(null);
      }
    }, IMAGE_POLLING_TIMEOUT);

    if (queuedBgId) {
      const foundBg = bgOptions?.find(
        (background) => background.id === queuedBgId
      );
      if (!foundBg) {
        startPolling(3000 * ApiConfig.pollEnabled);
      } else {
        selectBgOption(foundBg).then(() => {
          queueBgId(null);
          stopPolling();
        });
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    bgOptions,
    enqueueSnackbar,
    queuedBgId,
    selectBgOption,
    startPolling,
    stopPolling,
    t,
  ]);

  return (
    <ImageUpload
      imageType={ImageType.Background}
      label="Background"
      loading={Boolean(queuedBgId)}
      name={fieldName}
      onChange={onChange}
      onUpload={queueBgId}
      options={bgOptions}
      selectedId={selectedId}
    />
  );
};
