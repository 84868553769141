import { ReportStatusFieldProps } from '@pro4all/shared/types';

import { Instance } from '../elements';

import { FieldDescription } from './FieldDescription';
import LinkedSnagsList from './LinkedSnagsList';

export const ReportStatusField = ({
  item,
  key,
  linkedSnags,
  templateOptions,
  color,
  background,
}: ReportStatusFieldProps) => {
  const { displayName, displayDescription, value } = item;

  return (
    <Instance.Item key={key}>
      <Instance.Label>{displayName}</Instance.Label>
      <Instance.Value>
        <Instance.Status background={background} color={color}>
          {value}
        </Instance.Status>
        {templateOptions?.showDescription && (
          <FieldDescription displayDescription={displayDescription} />
        )}
        {templateOptions?.showLinkedSnags && (
          <LinkedSnagsList linkedSnags={linkedSnags} />
        )}
      </Instance.Value>
    </Instance.Item>
  );
};

export default ReportStatusField;
