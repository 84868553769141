import React from 'react';

import { FormControlLabel } from '@pro4all/shared/mui-wrappers';
import { ReportConfigTypes } from '@pro4all/shared/types';
import { Checkbox } from '@pro4all/shared/ui/general';

import { BoxSectionFieldInclude } from '../FormStyles';
import { getNestedItemIds } from '../helpers/getNestedItemIds';

type SectionFieldIncludeType = Pick<
  ReportConfigTypes,
  | 'checked'
  | 'flattenedItem'
  | 'items'
  | 'setHiddenSectionsAndFields'
  | 'template'
>;

export const SectionFieldIncludeComponent = ({
  checked,
  flattenedItem,
  items,
  setHiddenSectionsAndFields,
  template,
}: SectionFieldIncludeType) => {
  const { id, level, name, type } = flattenedItem;

  return (
    <BoxSectionFieldInclude level={level} type={type}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={() => {
              const nestedItemIds = getNestedItemIds({ id, items });
              const touchedIds = [id, ...nestedItemIds];
              setHiddenSectionsAndFields({
                add: checked ? true : false,
                ids: touchedIds,
                templateId: template.id,
              });
            }}
          />
        }
        id={id}
        label={name}
      />
    </BoxSectionFieldInclude>
  );
};

// Only in case the `checked` prop changes we wanna re-render.
// This saves us from having a lot of unnecessary re-renders certainly in case of many sections/question.
const skipUpdate = (
  prevProps: SectionFieldIncludeType,
  nextProps: SectionFieldIncludeType
) => prevProps.checked === nextProps.checked;

export const SectionFieldInclude = React.memo(
  SectionFieldIncludeComponent,
  skipUpdate
);
