import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { DocumentAction, Task } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';
import { StatusChip } from '@pro4all/shared/ui/chips';
import { Card, GreySmallMetaText, Timestamp } from '@pro4all/shared/ui/general';
import { Icon } from '@pro4all/shared/ui/icons';
import { Text } from '@pro4all/shared/ui/typography';
import { titleCaseToSentenceCase } from '@pro4all/shared/utils';

interface Props {
  tasks?: Task[];
}

type CollapsedActions = {
  [key in DocumentAction]: boolean;
};

function LinkedTasks({ tasks }: Props) {
  const { searchParams } = useRouting();
  const { t } = useTranslation();
  const defaultCollapsedActions = Object.fromEntries(
    Object.values(DocumentAction).map((value) => [value, false])
  ) as CollapsedActions;

  const [collapsedActions, setcollapsedActions] = useState<CollapsedActions>(
    defaultCollapsedActions
  );

  const taskWithActionExists = (action: DocumentAction) =>
    tasks?.find((task) => task.documentAction === action);

  return (
    <Container>
      {tasks?.length ? (
        Object.values(DocumentAction).map((action) => (
          <div key={uuid()}>
            {taskWithActionExists(action) && (
              <div>
                <Row
                  onClick={() =>
                    setcollapsedActions({
                      ...collapsedActions,
                      [action]: !collapsedActions?.[action],
                    })
                  }
                >
                  <Text variant="h5">
                    {t(`${titleCaseToSentenceCase(action)}`)}
                  </Text>
                  <Icon
                    iconName={`${
                      collapsedActions?.[action as DocumentAction]
                        ? 'keyboardArrowUp'
                        : 'keyboardArrowDown'
                    }`}
                  />
                </Row>
                {!collapsedActions?.[action as DocumentAction] &&
                  tasks
                    ?.filter((task) => task.documentAction === action)
                    .map((task) => (
                      <Card
                        iconName="documentTask"
                        key={uuid()}
                        meta={LinkedTaskMeta(task)}
                        onClick={() => {
                          searchParams.set({ action: 'viewTask', id: task.id });
                        }}
                        title={task.name ?? ''}
                      />
                    ))}
              </div>
            )}
          </div>
        ))
      ) : (
        <p>{t('This document has not been linked to any task yet')}</p>
      )}
    </Container>
  );
}

const LinkedTaskMeta = (task: Task) => (
  <GreySmallMetaText>
    {task.status && <StatusChip status={task.status} />}
    {task.endTime && (
      <>
        <Icon iconName="calendar" />
        <Timestamp date={task.endTime} format="YYYY-MM-DD" />
      </>
    )}

    {task.assignment?.[0]?.displayName && (
      <>
        <Icon iconName="personOutline" />
        <span>{task?.assignment?.[0]?.displayName}</span>
      </>
    )}
  </GreySmallMetaText>
);

const Row = styled('div')`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: fit-content;
  &:hover {
    cursor: pointer;
  }
`;

const Container = styled('div')`
  padding: 0 1.5rem;
`;

export default LinkedTasks;
