import { ReportSignatureFieldProps } from '@pro4all/shared/types';

import { Instance } from '../elements';
import { SignatureReports } from '../elements/SignatureReports';

import { FieldDescription } from './FieldDescription';
import LinkedSnagsList from './LinkedSnagsList';

export const ReportSignatureField = ({
  item,
  key,
  linkedSnags,
  signatures,
  signatureUrl,
  templateOptions,
}: ReportSignatureFieldProps) => {
  const { displayName, displayDescription } = item;
  return (
    <Instance.Item key={key}>
      <Instance.Label>{displayName}</Instance.Label>
      <Instance.Value>
        {signatures && <SignatureReports signatureUrl={signatureUrl} />}
        {templateOptions?.showDescription && (
          <FieldDescription displayDescription={displayDescription} />
        )}
        {templateOptions?.showLinkedSnags && (
          <LinkedSnagsList linkedSnags={linkedSnags} />
        )}
      </Instance.Value>
    </Instance.Item>
  );
};

export default ReportSignatureField;
