import { Routes } from '@pro4all/shared/config';

export const OrganizationRoutes = [
  Routes.access,
  Routes.accessDocuments,
  Routes.allMessages,
  Routes.baseAccessDocuments,
  Routes.baseDocs,
  Routes.collectionFolder,
  Routes.docs,
  Routes.docsSharedByInvite,
  Routes.docsSharedInScope,
  Routes.groups,
  Routes.members,
  Routes.messages,
  Routes.metaDataDocuments,
  Routes.metaDataDocumentsFields,
  Routes.metaDataDocumentsTemplates,
  Routes.metaDataHierarchicalLists,
  Routes.metaDataQualityControl,
  Routes.metaDataQualityControlFields,
  Routes.metaDataQualityControlForms,
  Routes.orgTemplatesTasks,
  Routes.metaDataQualityControlSnags,
  Routes.metaDataQualityControlTemplates,
  Routes.metaDataQualityControlTBQTemplates,
  Routes.metaDataQualityControlTBQTemplatesScope,
  Routes.myApiKeyManagement,
  Routes.myProfile,
  Routes.mySignature,
  Routes.mySupport,
  Routes.myOverview,
  Routes.myProjects,
  Routes.myTasks,
  Routes.objectDocuments,
  Routes.objectFloorPlans,
  Routes.objectFloorPlan,
  Routes.objectResults,
  Routes.objectTBQ,
  Routes.objectTBQOsidLog,
  Routes.objectTBQResults,
  Routes.objectTBQMultiBrandcheckResults,
  Routes.objectTBQRieResults,
  Routes.objectTBQMultiRieResults,
  Routes.objectTasks,
  Routes.objects,
  Routes.organization,
  Routes.projects,
  Routes.settingsOrganization,
  Routes.settingsOrganizationIntegrations,
  Routes.settingsOrganizationLicense,
  Routes.settingsOrganizationOrganization,
  Routes.settingsOrganizationSettings,
  Routes.settingsOrganizationDocuments,
  Routes.smartFolder,
  Routes.smartFolders,
  Routes.usersGroups,
  Routes.organizationNotifications,
];
