import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ResultSet, TbqAnswer, TbqCategory, TbqGoal } from '@pro4all/graphql';
import { useGetTbqResultQuery } from '@pro4all/graphql';
import { useRouting } from '@pro4all/shared/routing-utils';

export function useTBQResults() {
  const {
    searchParams,
    params: { objectId = '', resultSet = ResultSet.Victims },
    goTo,
  } = useRouting();
  const history = useHistory();
  const categoryId = searchParams.get('categoryId');
  const goalId = searchParams.get('goalId');
  const resultPlus = resultSet.endsWith('Plus');
  const taskId = searchParams.get('taskId') ?? '';
  const isTBQEditQuestionnaire = searchParams.is(
    'edit-tbq',
    'edit-tbq-dashboard'
  );

  const {
    data,
    refetch: refetchTBQBrandcheckResult,
    ...queryResult
  } = useGetTbqResultQuery({
    fetchPolicy: 'no-cache',
    skip: !taskId,
    variables: { resultSet: resultSet as ResultSet, taskId },
  });

  useEffect(() => {
    refetchTBQBrandcheckResult();
  }, [isTBQEditQuestionnaire]);

  const {
    answers = [],
    categories = [],
    operationGoals = [],
    shortcomings = [],
  } = useMemo(() => {
    const emptyResult = {
      answers: [] as TbqAnswer[],
      categories: [] as TbqCategory[],
      operationGoals: [] as TbqGoal[],
      shortcomings: [] as TbqAnswer[],
    };
    return data?.getTBQResult ?? emptyResult;
  }, [data?.getTBQResult]);

  const qcInstance = data?.getTBQResult?.instance;
  const tbqBrandcheckLinkedSnags =
    data?.getTBQResult?.tbqInstanceLinkedSnags ?? [];

  const back = () => history.goBack();

  const filterShortcomings = (param: 'categoryId' | 'goalId', id: string) => {
    goTo({
      searchParams: {
        categoryId: undefined,
        goalId: undefined,
        [param]: id,
      },
    });
  };

  const toggleResultPlus = () => {
    goTo({
      params: {
        objectId,
        resultSet: resultPlus
          ? resultSet.replace('Plus', '')
          : `${resultSet}Plus`,
      },
    });
  };

  return {
    done: Boolean(data?.getTBQResult),
    ...queryResult,
    answers,
    back,
    categories,
    filterShortcomings,
    hasFilter: Boolean(categoryId || goalId),
    operationGoals,
    qcInstance,
    resultPlus,
    resultSet,
    shortcomings: shortcomings.filter(
      ({ categoryId: cId, operationGoalIds: gIds }) =>
        cId === categoryId || gIds.includes(goalId ?? '')
    ),
    tbqBrandcheckLinkedSnags,
    toggleResultPlus,
  };
}
