import React from 'react';
import { useTranslation } from 'react-i18next';

import { Option, ReportConfigTypes } from '@pro4all/shared/types';
import { SearchableSelect } from '@pro4all/shared/ui/general';

export const ConfigureSelectTemplate = ({
  elementIdentifier,
  setTemplate,
  template,
  templateOptions,
}: Pick<
  ReportConfigTypes,
  'elementIdentifier' | 'setTemplate' | 'templateOptions'
> & {
  template: Option | null;
}) => {
  const { t } = useTranslation();

  return (
    <SearchableSelect
      label={t('Template')}
      name={elementIdentifier}
      onChange={setTemplate}
      options={templateOptions}
      placeholder={t('Select a template')}
      value={template}
    />
  );
};
