import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { unnestInstance } from '@pro4all/metadata/ui/utils';
import { InstancePageProps } from '@pro4all/shared/types';

import { Instance, MetaLeft, MetaRight, Page } from './elements';
import { InstancePageHeader } from './InstancePageHeader';
import { InstancePageSection } from './InstancesPageSection';

export const InstancePage = ({
  photoBlobs,
  instance,
  miniMapHtml,
  reportOptions,
  signatures,
}: InstancePageProps) => {
  const { t } = useTranslation();
  return (
    <Page>
      <Instance.Header>
        <MetaLeft>
          <Instance.List
            compactLists={reportOptions.compactLists}
            inlineLists={reportOptions.inlineLists}
            labelWidth={reportOptions.inlineLists ? 30 : 40}
          >
            <Instance.Title id={instance.id}>
              <Instance.Icon
                iconName={instance.type === 'snag' ? 'snag' : 'form'}
              />
              {instance.type === 'snag' ? t('Snag') : t('Form')} -{' '}
              {instance.name}
            </Instance.Title>
            <Instance.Item>
              <Instance.Label>{t('Created by')}</Instance.Label>
              <Instance.Value>{instance.createdBy?.displayName}</Instance.Value>
            </Instance.Item>
            <Instance.Item>
              <Instance.Label>{t('Created on')}</Instance.Label>
              <Instance.Value>
                {dayjs(instance.createdAt).format('lll')}
              </Instance.Value>
            </Instance.Item>
            <Instance.Item>
              <Instance.Label>{t('Reference')}</Instance.Label>
              <Instance.Value>{instance.reference}</Instance.Value>
            </Instance.Item>
            <Instance.Item>
              <Instance.Label>{t('Floor plan')}</Instance.Label>
              <Instance.Value>{instance.visualContext?.name}</Instance.Value>
            </Instance.Item>
            {instance.items && (
              <InstancePageHeader
                instanceId={instance.id}
                items={instance.items}
                reportOptions={reportOptions}
              />
            )}
          </Instance.List>
        </MetaLeft>
        {miniMapHtml && miniMapHtml.length > 0 && (
          <MetaRight>
            <div
              className="map-container"
              dangerouslySetInnerHTML={{
                __html: miniMapHtml,
              }}
              id={`map-container-${instance.id}`}
            />
          </MetaRight>
        )}
      </Instance.Header>
      {instance.items && (
        <InstancePageSection
          instanceId={instance.id}
          items={instance.items}
          linkedSnagInstances={instance.linkedSnagInstances ?? []}
          photoBlobs={photoBlobs}
          reportOptions={reportOptions}
          signatures={signatures}
          templateId={instance.templateId}
          unnestedItems={unnestInstance(instance.items)}
        />
      )}
    </Page>
  );
};
