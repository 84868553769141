import { ReportMediaFieldProps } from '@pro4all/shared/types';

import { ReportImages } from '../../ReportImages';
import { Instance } from '../elements';

import { FieldDescription } from './FieldDescription';
import LinkedSnagsList from './LinkedSnagsList';

export const ReportMediaField = ({
  item,
  key,
  linkedSnags,
  photoBlobs,
  reportOptions,
  templateId,
  templateOptions,
}: ReportMediaFieldProps) => {
  const { displayName, displayDescription, value } = item;
  const values = value?.split(',') || [];
  const valueLength = value?.length ?? 0;
  const overrideInline = valueLength < 300;

  return (
    <Instance.Item key={key} preventPageBreak>
      <Instance.Label>{displayName}</Instance.Label>
      <Instance.Value inlineLists={overrideInline}>
        <ReportImages
          photoBlobs={photoBlobs}
          reportOptions={reportOptions}
          templateId={templateId}
          values={values}
        />
        {templateOptions?.showDescription && (
          <FieldDescription displayDescription={displayDescription} />
        )}
        {templateOptions?.showLinkedSnags && (
          <LinkedSnagsList linkedSnags={linkedSnags} />
        )}
      </Instance.Value>
    </Instance.Item>
  );
};

export default ReportMediaField;
