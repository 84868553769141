import { QualityControlInstance } from '@pro4all/graphql';
import { FloorPlanRouterState } from '@pro4all/quality-control/data-access';
import { useMapLinkingContext } from '@pro4all/quality-control/ui/maps';
import {
  getFloorPlanRoute,
  getQCSResultRoute,
} from '@pro4all/quality-control/utils';
import { Action } from '@pro4all/shared/config';
import { useRouting } from '@pro4all/shared/routing-utils';
import { useAnalytics } from '@pro4all/shared/vendor';

export const useResultActions = (result: QualityControlInstance) => {
  const { goTo, params, searchParams, url } = useRouting();
  const { projectId } = params;
  const { setCentering } = useMapLinkingContext();
  const { id, visualContext } = result ?? {};

  const { track } = useAnalytics();

  const centerOnMap = () => {
    setCentering(true);
  };

  const onClose = () => {
    searchParams.clear();
  };

  const showInTable = () => {
    track(Action.ViewInTableButtonClicked, {
      id: result.id,
      name: result.name,
      projectId,
      type: result.type,
    });

    goTo(getQCSResultRoute(params), {
      params: { objectId: params.objectId, projectId },
      searchParams: { action: 'viewResult', id },
    });
  };

  const showOnMap = (page: number) => {
    track(Action.ViewOnFloorplanButtonClicked, {
      id: result.id,
      name: result.name,
      projectId,
      type: result.type,
    });

    goTo(getFloorPlanRoute(params), {
      params: {
        objectId: params.objectId,
        projectId,
        visualContextId: visualContext?.id,
      },
      searchParams: { action: 'viewResult', id, page },
      state: {
        previousPageName: 'results',
        previousPageUrl: url,
      } as FloorPlanRouterState,
    });
  };

  return { centerOnMap, onClose, showInTable, showOnMap };
};
