import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Task, TaskType } from '@pro4all/graphql';
import { FloorPlansMain } from '@pro4all/quality-control/ui/floor-plan';
import {
  AnswersPercentageContextProvider,
  ResultSidebar,
  ResultsMain,
} from '@pro4all/quality-control/ui/results';
import { Routes } from '@pro4all/shared/config';
import { PhotoProvider } from '@pro4all/shared/contexts';
import { useQCTBQContext } from '@pro4all/shared/contexts';
import { Box } from '@pro4all/shared/mui-wrappers';
import { useRouting } from '@pro4all/shared/routing-utils';
import { SnagFromSearchContextProvider } from '@pro4all/shared/snags-and-forms-search-context';
import { Loader, OptimisticResponseProvider } from '@pro4all/shared/ui/general';

import * as Messages from '../../Messages';
import { ProjectStatus } from '../../projects';
import { TBQTasks, TBQType } from '../../tbq';
import { OsidLogs } from '../../tbq/osid/OsidLogs';
import { TBQResolveTasks } from '../../tbq/tasks/list/TBQResolveTasks';

import { ObjectDetailProvider } from './ObjectDetailContext';
import { Tabs } from './Tabs';
import { useObjectDetail } from './useObjectDetail';

export const ObjectDetail: React.FC = () => {
  const { error, id, loading, object, refetch, ...queryResult } =
    useObjectDetail();
  const routeProjectId = useRouting().params.projectId;
  const projectContext = useQCTBQContext();
  if (!id) return <Messages.NoObject />;
  if (!object && loading) return <Loader />;
  if (!object) return <Messages.NotFound />;

  const { tasks = [] } = object;
  const routeOrObjectProjectId = routeProjectId ?? object.projectId;
  const procedureId = object?.project?.mainProcedure?.id;

  if (object?.projectId) {
    projectContext.setObjectProjectId(object.projectId);
  }

  return (
    <ObjectDetailProvider
      error={error}
      id={id}
      loading={loading}
      object={object}
      projectId={object?.projectId ?? ''}
      refetch={refetch}
      {...queryResult}
    >
      {routeOrObjectProjectId && <Tabs />}
      <Route
        path={[
          Routes.objectTBQ,
          Routes.objectFloorPlans,
          Routes.objectResults,
          Routes.objectTasks,
          Routes.projectObjectTbq,
          Routes.projectObjectFloorPlans,
          Routes.projectObjectResults,
          Routes.projectObjectTasks,
        ]}
      >
        <ProjectStatus />
      </Route>
      <Switch>
        <Route path={[Routes.objectTBQ, Routes.projectObjectTbq]}>
          <TBQType />
          <Box flex="auto" mt={3}>
            <OptimisticResponseProvider<Task>>
              <TBQTasks
                procedureId={procedureId}
                projectId={routeOrObjectProjectId ?? ''}
                tasks={
                  tasks?.filter(
                    (t) =>
                      t.type === TaskType.Tbq || t.type === TaskType.TbqScan
                  ) ?? []
                }
              />
            </OptimisticResponseProvider>
          </Box>
        </Route>
        <Route path={[Routes.objectFloorPlans, Routes.projectObjectFloorPlans]}>
          <FloorPlansMain projectId={routeOrObjectProjectId ?? ''} />
        </Route>
        <Route path={[Routes.objectResults, Routes.projectObjectResults]}>
          <SnagFromSearchContextProvider>
            <ResultsMain projectId={routeOrObjectProjectId ?? ''} />
          </SnagFromSearchContextProvider>
        </Route>
        <Route path={[Routes.objectTasks, Routes.projectObjectTasks]}>
          <Box flex="auto" mt={3}>
            <OptimisticResponseProvider<Task>>
              <TBQResolveTasks
                procedureId={procedureId ?? ''}
                projectId={routeOrObjectProjectId ?? ''}
              />
            </OptimisticResponseProvider>
          </Box>
        </Route>
        <Route path={[Routes.objectTBQOsidLog, Routes.projectObjectTBQOsidLog]}>
          <Box flex="auto" mt={3}>
            <OsidLogs />
          </Box>
        </Route>
      </Switch>

      <PhotoProvider>
        <AnswersPercentageContextProvider>
          <ResultSidebar />
        </AnswersPercentageContextProvider>
      </PhotoProvider>
    </ObjectDetailProvider>
  );
};
