import { ReportDescriptionFieldProps } from '@pro4all/shared/types';

import { Instance } from '../elements';

export const ReportDescriptionField = ({
  item,
  key,
}: ReportDescriptionFieldProps) => {
  const { displayName, description } = item;
  return (
    <div>
      <Instance.Item key={key}>
        <Instance.Label>{displayName}</Instance.Label>
        <Instance.Value>{description}</Instance.Value>
      </Instance.Item>
    </div>
  );
};

export default ReportDescriptionField;
