import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';

import {
  ImageType,
  useReportImageUrlLazyQuery,
  useReportLogosQuery,
} from '@pro4all/graphql';
import {
  DEFAULT_LOGO_URL,
  IMAGE_POLLING_TIMEOUT,
} from '@pro4all/quality-control/ui/report';
import { ApiConfig } from '@pro4all/shared/config';
import { Option, ReportOptions } from '@pro4all/shared/types';
import { SearchableSelectProps } from '@pro4all/shared/ui/general';
import { sortBy } from '@pro4all/shared/utils';

import { ImageUpload } from '../../components';
import { useReportOptionsContext } from '../../ReportOptionsContext';

export const LogoField: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { data: logoData, startPolling, stopPolling } = useReportLogosQuery();
  const [getImageUrl] = useReportImageUrlLazyQuery();

  const queriedLogoOptions = logoData?.reportLogos?.map((logo) => ({
    id: logo.id,
    label: decodeURI(logo.name),
  }));

  const { setFieldValue } = useFormikContext<ReportOptions>();

  const context = useReportOptionsContext();
  if (!context) throw new Error('ReportOptionsContext not available');
  const { logoId, setLogo } = context;

  const [queuedLogoId, queueLogoId] = useState<string | null>();

  const defaultLogoOption = useMemo(
    () => ({
      id: 'default',
      label: 'Prostream logo (default)',
    }),
    []
  );
  const logoOptions: Option[] = useMemo(
    () =>
      queriedLogoOptions
        ? [
            defaultLogoOption,
            ...queriedLogoOptions.sort(sortBy({ key: 'label' })),
          ]
        : [defaultLogoOption],
    [defaultLogoOption, queriedLogoOptions]
  );

  const selectLogoOption = useCallback(
    async ({ id }: Option) => {
      setLogo(id);
      if (id === 'default') setFieldValue('companyLogo', DEFAULT_LOGO_URL);
      else {
        const { data } = await getImageUrl({
          variables: { id },
        });
        data?.reportImageUrl &&
          setFieldValue('companyLogo', data?.reportImageUrl);
      }
    },
    [getImageUrl, setFieldValue, setLogo]
  );

  const onChange = async (value: SearchableSelectProps['value']) => {
    if (!value || typeof value === 'string') return;
    await selectLogoOption({ id: value.id, label: value.label });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (queuedLogoId) {
        stopPolling();
        enqueueSnackbar(t('Something went wrong'));
        queueLogoId(null);
      }
    }, IMAGE_POLLING_TIMEOUT);

    if (queuedLogoId) {
      const foundLogo = logoOptions?.find((logo) => logo.id === queuedLogoId);
      if (!foundLogo) {
        startPolling(3000 * ApiConfig.pollEnabled);
      } else {
        selectLogoOption(foundLogo).then(() => {
          stopPolling();
          queueLogoId(null);
        });
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    enqueueSnackbar,
    logoOptions,
    queuedLogoId,
    selectLogoOption,
    startPolling,
    stopPolling,
    t,
  ]);

  return (
    <ImageUpload
      imageType={ImageType.CompanyLogo}
      label="Company logo"
      loading={Boolean(queuedLogoId)}
      name="companyLogo"
      onChange={onChange}
      onUpload={queueLogoId}
      options={logoOptions}
      selectedId={logoId}
    />
  );
};
