import {
  ResourceType,
  useResourcePermissionsQuery,
  useTaskQuery,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import {
  ProjectAccessType,
  useProjectContext,
} from '@pro4all/projects/ui/context';
import {
  getHierarchyIndex,
  getPermissionName,
  getPermissionValue,
} from '@pro4all/shared/permissions';
import { useRouting } from '@pro4all/shared/routing-utils';

import {
  convertPermissions,
  setPermissionsToAll,
} from '../utils/convertPermissions';

/* 1)You use this hook when you want to check the 'assigned' and 'ownAssigned' permissions. In other scenario's you use the useQCPermissions.ts hook
 these permissions allow you to do something if some resource is assigned to you. For example if you have a personal permission of snag.update.ownAssigned, you can only edit a snag if it is linked to a task that is assigned to you. 

 2) !important: This hook checks the permissions you have on a specific resource, not your permissions in general. 
  That means that if in general you have the snag.update.ownAssigned permission and you are passing the instance id of a snag into this hook it will check if there is a task that has that instance linked to it and is assigned to you.
  If there is no such task the hook wil return {snag.update.ownAssigned: false}, if here is such a task the hook wil return {snag.update.ownAssigned: true}. 
  that means that if you want to know if a user can edit a snag you can simply destruct const {snagUpdateOwnAssigned} = useFetchResourcePermissions({}) from the hook and use it as a boolean for your edit button condition: snagUpdateOwnAssigned && <button />.
 */
export const useFetchResourcePermissions = ({
  resourceType,
  resourceId,
  tbqProjectId,
}: {
  resourceId: string;
  resourceType: ResourceType;
  tbqProjectId?: string;
}) => {
  const { params } = useRouting();
  const { userId } = useOrganizationContext();
  const projectId = params.projectId ?? tbqProjectId;
  const projectContext = useProjectContext();
  const permissionStatus = projectContext?.projectAccessLoadStatus?.status;
  const isOrgOrProjAdmin = permissionStatus === ProjectAccessType.ProjectUpdate;

  const { data } = useResourcePermissionsQuery({
    fetchPolicy: 'cache-and-network',
    skip:
      !projectId ||
      !resourceId ||
      ![ResourceType.Instance, ResourceType.VisualContext].includes(
        resourceType
      ),
    variables: {
      projectId: projectId ?? '',
      resourceId: resourceId,
      resourceType: resourceType,
    },
  });

  const resourcePermissions = data?.resourcePermissions.permissions;
  //the BE returns us resource permissions from both the user's groups and the users individual permissions in 1 array of strings.
  //so you could have both 'snag-update-none' and 'snag-update-all' in this array for example.
  //We need to need to make sure we get the highest variant of each permission and put it in a new array.
  const highestResourcePermissions: string[] = resourcePermissions?.reduce(
    (acc: string[], perm: string) => {
      const permissionName = getPermissionName(perm); // 'snag-create' or 'form-update' etc.
      const permissionValue = getPermissionValue(perm); //'all' or 'none' etc.
      const permissionAlreadyInAcc = acc.find((p) =>
        p.includes(permissionName)
      );
      const permissionAlreadyInAccHierarchyIndex = getHierarchyIndex(
        getPermissionValue(permissionAlreadyInAcc)
      );
      const permHierarchyIndex = getHierarchyIndex(permissionValue);
      if (!permissionAlreadyInAcc) acc.push(perm);
      else if (
        permissionAlreadyInAcc &&
        permHierarchyIndex > permissionAlreadyInAccHierarchyIndex
      )
        acc.map((p) =>
          getPermissionValue(perm) === getPermissionValue(p) ? perm : p
        );
      return acc;
    },
    []
  );

  const { data: taskData } = useTaskQuery({
    fetchPolicy: 'cache-and-network',
    skip: resourceType !== ResourceType.Task,
    variables: { id: resourceId },
  });

  let permissionsObj = convertPermissions(highestResourcePermissions);

  //check if a task is assigned to the user and update the permissions accordingly
  //again remember that this hook returns permissions on a specific resource, not your general permissions (see explanation 2 at top of file )
  if (resourceType === ResourceType.Task) {
    const assignedTo = taskData?.task?.assignment?.[0];
    const assignedToMe = assignedTo?.id === userId;
    if (assignedToMe) {
      permissionsObj.snagCreateAssigned = true;
      permissionsObj.formCreateAssigned = true;
    }
  }
  //Admin overwrite
  if (isOrgOrProjAdmin) {
    permissionsObj = setPermissionsToAll(permissionsObj);
  }
  return permissionsObj;
};
