import React from 'react';

import { CircularProgress } from '@pro4all/shared/mui-wrappers';
import { ReportImagesProps } from '@pro4all/shared/types';

import { PhotoGrid } from './pages/elements';

export const ReportImages: React.FC<ReportImagesProps> = ({
  photoBlobs,
  reportOptions,
  templateId,
  values,
}) => {
  const column =
    templateId &&
    reportOptions?.templates &&
    reportOptions?.templates[templateId]
      ? reportOptions?.templates[templateId]?.imageColumn
      : 'bTwo';

  const loading = photoBlobs === undefined;

  return (
    <PhotoGrid column={column} inline={reportOptions?.inlineLists}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {values.map((id) => {
            if (!photoBlobs) return null;
            const url = photoBlobs[id];
            return (
              <div className="reports-img-container">
                <img alt="qcimage" key={id} src={url || ''} />
              </div>
            );
          })}
        </>
      )}
    </PhotoGrid>
  );
};
