import { FilterType } from '@pro4all/graphql';
import { IconName } from '@pro4all/shared/ui/icons';

export const filterNames: Record<FilterType, string> = {
  [FilterType.CreatedBy]: `Created by`,
  [FilterType.UpdatedBy]: `Updated by`,
  [FilterType.UpdatedAt]: `Updated on`,
  [FilterType.CreatedAt]: `Created on`,
  [FilterType.Extension]: `Document type`,
  [FilterType.ParentFolderIds]: `Folder`,
  [FilterType.FolderId]: `Folder`,
  [FilterType.State]: `{{name}}`,
  [FilterType.VersionState]: '{{Approved}}/{{Rejected}}',
  [FilterType.Versions]: `Qr Code`,
  [FilterType.Bool]: `Yes/No`,
  [FilterType.Number]: `Number`,
  [FilterType.Selection]: `Selection`,
  [FilterType.Text]: `Text`,
  [FilterType.Status]: `Status`,
  [FilterType.DateTime]: `Date`,
  [FilterType.Floorplan]: `Floorplan`,
  [FilterType.Template]: `Template`,
  [FilterType.TemplateType]: `Type`,
  [FilterType.ProjectNumber]: `Project Number`,
  [FilterType.ProjectName]: `Project Name`,
  [FilterType.Tasks]: `Tasks`,
  [FilterType.CreatedOn]: 'Created On',
  [FilterType.IndivativeStatus]: 'Indivative Status',
  [FilterType.ReferenceNumber]: 'Reference Number',
  [FilterType.UpdatedOn]: 'Updated On',
  [FilterType.Snags]: 'Fields in snags',
  [FilterType.Forms]: 'Fields in forms',
  [FilterType.UserSelection]: 'User Selection',
  [FilterType.Highlight]: 'Status',
  [FilterType.TemplateId]: 'Template ID',
};

export const filterIconNames: Record<FilterType, IconName | undefined> = {
  [FilterType.Extension]: 'file',
  [FilterType.CreatedBy]: 'personOutline',
  [FilterType.UpdatedBy]: 'personOutline',
  [FilterType.UpdatedAt]: 'dateRange',
  [FilterType.CreatedAt]: 'dateRange',
  [FilterType.ParentFolderIds]: 'folder',
  [FilterType.FolderId]: 'folder',
  [FilterType.State]: 'finalize',
  [FilterType.VersionState]: 'approveFile',
  [FilterType.Versions]: 'stamp',
  [FilterType.Bool]: 'boolean',
  [FilterType.Number]: 'number',
  [FilterType.Selection]: 'selection',
  [FilterType.Text]: 'text',
  [FilterType.Status]: `status`,
  [FilterType.DateTime]: `dateRange`,
  [FilterType.Floorplan]: 'floorplan',
  [FilterType.Template]: 'template',
  [FilterType.TemplateType]: 'template',
  [FilterType.ProjectNumber]: 'snag',
  [FilterType.ProjectName]: 'add',
  [FilterType.Tasks]: 'qcTask',
  [FilterType.CreatedOn]: 'add',
  [FilterType.IndivativeStatus]: 'insight',
  [FilterType.ReferenceNumber]: 'number',
  [FilterType.UpdatedOn]: 'add',
  [FilterType.Snags]: 'snag',
  [FilterType.Forms]: 'form',
  [FilterType.UserSelection]: 'selection',
  [FilterType.Highlight]: 'indicative',
  [FilterType.TemplateId]: 'add',
};

export const filterOrder: Record<FilterType, number> = {
  [FilterType.ParentFolderIds]: 0,
  [FilterType.FolderId]: 0,
  [FilterType.UpdatedBy]: 1,
  [FilterType.UpdatedAt]: 1,
  [FilterType.State]: 2,
  [FilterType.VersionState]: 2,
  [FilterType.Versions]: 2,
  [FilterType.CreatedBy]: 2,
  [FilterType.CreatedAt]: 2,
  [FilterType.Extension]: 2,
  [FilterType.Bool]: 3,
  [FilterType.Selection]: 3,
  [FilterType.Number]: 3,
  [FilterType.Text]: 3,
  [FilterType.Status]: 3,
  [FilterType.DateTime]: 3,
  [FilterType.Floorplan]: 2,
  [FilterType.Template]: 2,
  [FilterType.ProjectNumber]: 2,
  [FilterType.ProjectName]: 2,
  [FilterType.Tasks]: 2,
  [FilterType.CreatedOn]: 2,
  [FilterType.IndivativeStatus]: 2,
  [FilterType.ReferenceNumber]: 2,
  [FilterType.UpdatedOn]: 2,
  [FilterType.Snags]: 2,
  [FilterType.Forms]: 2,
  [FilterType.UserSelection]: 2,
  [FilterType.Highlight]: 2,
  [FilterType.TemplateType]: 2,
  [FilterType.TemplateId]: 2,
};
