import { useVisualContextsQuery } from '@pro4all/graphql';
import { ApiConfig } from '@pro4all/shared/config';

export const useFetchFloorplans = (projectId: string) => {
  const { data } = useVisualContextsQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000 * ApiConfig.pollEnabled,
    variables: { projectId },
  });

  return { data };
};
