import React from 'react';
import { useTranslation } from 'react-i18next';

import { VisualContext, VisualContextStatus } from '@pro4all/graphql';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@pro4all/shared/mui-wrappers';
import { Icon } from '@pro4all/shared/ui/icons';

const VisualContextListMobile: React.FC<{
  onRowClick: (visualContext: VisualContext) => void;
  visualContexts: VisualContext[];
}> = ({ onRowClick, visualContexts }) => {
  const { t } = useTranslation();

  return (
    <List
      sx={{
        borderTop: 1,
        borderTopColor: 'divider',
        height: '100%',
        overflowY: 'auto',
        width: '100%',
      }}
    >
      {visualContexts &&
        visualContexts.map((visualContext) => (
          <ListItemButton
            dense
            disabled={visualContext.status !== VisualContextStatus.Processed}
            divider
            key={visualContext.id}
            onClick={() => {
              onRowClick(visualContext);
            }}
          >
            <ListItemIcon>
              <Icon
                iconName={
                  visualContext.status !== VisualContextStatus.Processed
                    ? 'refresh'
                    : 'floorplan'
                }
              />
            </ListItemIcon>

            <ListItemText
              primary={
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    color="text.primary"
                    sx={{
                      maxWidth: '200px', // Fixed width otherwise conflicts with page count
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {visualContext.name}
                  </Typography>
                  {visualContext.status === VisualContextStatus.Processed && (
                    <Typography color="text.secondary">{`${
                      visualContext.pageCount
                    } ${t('Pages')}`}</Typography>
                  )}
                </Box>
              }
              secondary={
                <Typography color="text.secondary">
                  {visualContext.status !== VisualContextStatus.Processed
                    ? t('Processing')
                    : `${visualContext.freeSnagCount || 0} ${t('Snags')}, ${
                        visualContext.formCount || 0
                      } ${t('Forms')}`}
                </Typography>
              }
            />
          </ListItemButton>
        ))}
    </List>
  );
};

export default VisualContextListMobile;
