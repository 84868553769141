import styled, { css } from 'styled-components';

import { Card, CardHeader, CardProps } from '@pro4all/shared/mui-wrappers';
import { customColors } from '@pro4all/shared/themes';

export type CardStyle = 'default' | 'disabled' | 'error' | 'no-permission';

export const StyledCard = styled(Card)<CardProps & { $cardStyle: CardStyle }>`
  && {
    width: 100%;
    border-color: ${customColors.grey500};
    border-radius: 8px;
    padding: '8px 8px 8px 16px';

    &:hover {
      background-color: rgba(132, 132, 132, 0.1);
    }

    & + & {
      margin-top: ${({ theme }) => theme.spacing(1)};
    }
    & .MuiCardHeader-content {
      overflow: hidden;
    }

    &.error {
      border-color: ${({ theme }) => `${theme.palette.error.dark}`};
      color: ${({ theme }) => `${theme.palette.error.dark}`};
      &:hover {
        background: none;
      }
    }

    &.disabled {
      border-color: ${customColors.grey200};
      &:hover {
        background: none;
      }
    }
    &.no-permission {
      border-color: ${customColors.grey400};
      background-color: ${customColors.grey200};
    }
    article.MuiCardActionArea-root {
      margin-top: 0;
    }
  }
`;

export const StyledCardHeader = styled(CardHeader)<{
  $cardStyle: CardStyle;
}>`
  && {
    .MuiCardHeader-title {
      display: flex;
      align-items: center;
      word-break: break-all;
      ${({ theme, $cardStyle }) => css`
        color: ${$cardStyle === 'error' && theme.palette.error.dark};
      `}
      > .MuiSvgIcon-root {
        margin-right: ${({ theme }) => theme.spacing(1)};
      }
    }

    .MuiCardHeader-action {
      align-self: center;
      margin: 0;
    }

    ${({ onClick, $cardStyle }) =>
      onClick && $cardStyle === 'default' && 'cursor: pointer;'}

    padding: ${({ theme }) => theme.spacing(1)};
  }
`;

export const CardMetaList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
`;

export const CardMetaItem = styled.li`
  max-width: 100%;
  &:not(:last-of-type) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;
