import React, { useState } from 'react';
import { DropzoneState } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { client } from '@pro4all/authentication/src/graph-ql';
import {
  QcPermissionCategory,
  useDeleteVisualContextsMutation,
  VisualContext,
  VisualContextsDocument,
} from '@pro4all/graphql';
import { useOrganizationContext } from '@pro4all/organization/context';
import { useQCPermissions } from '@pro4all/quality-control/data-access';
import { Action } from '@pro4all/shared/config';
import { useFeatureFlag } from '@pro4all/shared/feature-flags';
import { useRouting } from '@pro4all/shared/routing-utils';
import { ActionProps } from '@pro4all/shared/types';
import { useTableCheck } from '@pro4all/shared/ui/general';
import { TableActionBar } from '@pro4all/shared/ui/table-action-bar';
import { useAnalytics } from '@pro4all/shared/vendor';

import { FloorPlansDeleteDialog } from './FloorPlansDeleteDialog';
import { DeleteVariant } from './types';

interface Props {
  openFileInput?: DropzoneState['open'];
  visualContexts: VisualContext[];
}

type selectedFloorplans = 'all-owned' | 'none-owned' | 'mixed';

export const FloorPlansActionBar: React.FC<Props> = ({
  openFileInput,
  visualContexts,
}) => {
  const qcGroupPermissionsFlag = useFeatureFlag('qc-group-permissions');
  const { floorDeleteNone, floorDeleteOwn, floorDeleteAll, floorCreateAll } =
    useQCPermissions({
      category: QcPermissionCategory.Qcs,
      qcGroupPermissionsFlag,
    });

  const [showDialog, setShowDialog] = useState(false);

  const [deleteVariant, setDeleteVariant] = useState<DeleteVariant>(
    DeleteVariant.EMPTY
  );

  const { track } = useAnalytics();
  const { params } = useRouting();

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const { checkedRows } = useTableCheck<VisualContext>();
  const [deleteVisualContexts] = useDeleteVisualContextsMutation();

  const { userId } = useOrganizationContext();

  const mainActions = [
    floorCreateAll && {
      ariaLabel: t('Upload floor plan'),
      dataTestId: 'upload-floor-plan',
      key: 'upload-floor-plan',
      label: t('Upload floor plan'),
      onClick: () => {
        openFileInput();

        track(Action.CreateFloorplanButtonClicked, {
          projectId: params.projectId,
        });
      },
      startIcon: 'upload',
    },
  ].filter(Boolean) as ActionProps[];

  const deleteFloorplans = async (floorplans: VisualContext[]) => {
    if (showDialog) setShowDialog(false);

    track(Action.DeleteFloorplanButtonClicked, {
      ids: floorplans.map(({ id }) => id),
      names: floorplans.map(({ name }) => name),
      projectId: params.projectId,
    });

    const { data } = await deleteVisualContexts({
      variables: { visualContextIds: floorplans.map(({ id }) => id) },
    });

    if (data.deleteVisualContexts.errors) {
      enqueueSnackbar(t('Something went wrong. Please try again.'));
    } else {
      const deleteIds = floorplans.map(({ id }) => id);
      const newVisualContexts = visualContexts.filter(
        (visualContext) => !deleteIds.includes(visualContext.id)
      );

      client.writeQuery({
        data: {
          visualContexts: newVisualContexts,
        },
        query: VisualContextsDocument,
        variables: { projectId: params.projectId },
      });

      enqueueSnackbar(
        t(deleteIds.length === 1 ? 'Floorplan deleted' : 'Floorplans deleted')
      );
    }
  };

  const verifyCheckedFloorplans = (): selectedFloorplans => {
    const flag = checkedRows.every((row) =>
      row.createdBy ? row.createdBy.id === userId : false
    )
      ? 'all-owned'
      : checkedRows.every((row) =>
          row.createdBy ? row.createdBy.id !== userId : false
        )
      ? 'none-owned'
      : 'mixed';

    return flag;
  };

  const altActions: ActionProps[] = [
    {
      ariaLabel: t('Delete floor plans'),
      dataTestId: 'delete-floor-plans',
      key: 'delete-floor-plans',
      label: t('Delete floor plans'),
      onClick: async () => {
        const selectedType = verifyCheckedFloorplans();

        if (floorDeleteAll || selectedType === 'all-owned') {
          setDeleteVariant(
            visualContexts.length === checkedRows.length
              ? DeleteVariant.DELETE_ALL
              : DeleteVariant.DELETE_SELECTED
          );
        } else if (floorDeleteOwn && selectedType === 'mixed') {
          setDeleteVariant(DeleteVariant.DELETE_ONLY_OWN);
        } else {
          setDeleteVariant(DeleteVariant.DELETE_NONE);
        }

        setShowDialog(true);
      },
      startIcon: 'delete',
    },
  ];

  return (
    <>
      <TableActionBar
        altActions={!floorDeleteNone && altActions}
        dataTestid="floor-plan-action-bar"
        mainActions={mainActions}
        search
      />

      <FloorPlansDeleteDialog
        deleteFloorplans={deleteFloorplans}
        deleteVariant={deleteVariant}
        setShowDialog={setShowDialog}
        showDialog={showDialog}
      />
    </>
  );
};
