import { useRouteMatch } from 'react-router';

import { Routes } from '@pro4all/shared/config';
import { useMediaQuery, useTheme } from '@pro4all/shared/mui-wrappers';
import { breakpoints } from '@pro4all/shared/themes';
import {
  BigMessageRotateScreen,
  BigMessageScreenTooSmall,
} from '@pro4all/shared/ui/messages';
const forbiddenRoutes = [
  //organisation scope
  Routes.metaDataDocuments,
  Routes.metaDataHierarchicalLists,
  Routes.metaDataQualityControl,
  Routes.metaDataQualityControlFields,
  Routes.metaDataQualityControlForms,
  Routes.metaDataQualityControlSnags,
  Routes.metaDataQualityControlTemplates,
  Routes.organizationNotifications,
  //project scope
  Routes.projectDocsNotifications,
  Routes.projectMetaDataDocumentsFields,
  Routes.projectMetaDataDocumentsTemplates,
  Routes.projectMetaDataHierarchicalLists,
  Routes.projectMetaDataQualityControl,
];

export function useScreenTooSmallForPage() {
  const theme = useTheme();
  const smallerThanTabletBreakpoint = useMediaQuery(
    theme.breakpoints.down('md')
  );
  const screenTooNarrowForPage = useRouteMatch(forbiddenRoutes);
  const windowWideEnoughToViewPage = window.innerHeight > breakpoints.values.md;

  if (smallerThanTabletBreakpoint && screenTooNarrowForPage)
    return windowWideEnoughToViewPage ? (
      <BigMessageRotateScreen />
    ) : (
      <BigMessageScreenTooSmall />
    );

  return null;
}
